import React from 'react';

import ImgIssuer from './img-issuer';

const RadioIssuer = props => {
    let formControl = "form-control";
    let direction = "--" + props.direction;
    let urlAPI = process.env.REACT_APP_URL_API;

    return (
        <div className={"form-group form-group--radio " + direction}>
            <ul className="payment-issuers__list">
                {props.options.map(option => (
                    <li 
                        className={"payment-issuers__listitem" + (parseInt(props.selected) === parseInt(option.value) ? " --selected" : '')} 
                        key={option.value}
                    >
                        <label>
                            <input type="radio"
                                name = {props.name}
                                value = {option.value}
                                onChange = {props.onChange}
                                className = {formControl}
                                defaultChecked = {props.defaultValue === option.value}
                            />
                            <ImgIssuer
                                option = {option}
                            />
                            <span>{option.displayValue}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RadioIssuer;