import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import * as ui from '../../../utilities/ui'; 

const FilterSubCategories = inject("stores") (
    observer (
        class FilterSubCategories extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeProducts = this.props.stores.storeProducts;

                // this.state = {
                //     foodTypes: [
                //         'chicken',
                //         'duck',
                //         'fish',
                //         'lamb',
                //         'beef',
                //         'pig',
                //         'rabbit'
                //     ]
                // }
                this.foodTypes = [];

                this.state = {
                    foodTypes: [
                        'chicken',
                        'fish',
                        'lamb'
                    ]
                }
            }

            componentDidMount = async() => {   
                const productFilters = await this.storeProducts.getProductFilters();

                productFilters.forEach((filter) => (
                    this.saveFilter(filter)
                ));
            }

            handleFilter = async(filter, value) => {
                //const px = ui.convertRemToPixels(this.storeUi.settings_list['filterScroll']);
                const px = this.storeUi.filtersTop;
                ui.smoothScrollTo(0, px, 250);

                this.storeUi.setFilterShop(filter, value);
                const products = await this.storeProducts.getProducts(this.storeUi.filtersShop.category, this.storeUi.filtersShop.type);                
            }

            saveFilter = (filter) => {
                const item = filter.name;
                
                this.foodTypes.push(item);

                this.setState({
                    foodTypes: this.foodTypes
                })
            }

            render() {
                let className = this.storeUi.filtersShop.type

                return (
                    <div className="container-filter container-filter--types">
                        <ul>
                            <li
                                className = {"--link" + (className === 'all' ? ' --active' : '')}
                                onClick = {() => this.handleFilter('type', 'all')}
                            >
                                all
                            </li>
                            {this.state.foodTypes && this.state.foodTypes.map((foodType) => (
                                <li
                                    className = {"--link" + (className === foodType ? ' --active' : '')}
                                    onClick = {() => this.handleFilter('type', foodType)}
                                >
                                    <img src={this.urlAPI + "public/images/shop/icons/ic-" + foodType + ".png"} />
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            }
        }
    )
)

export default FilterSubCategories;
