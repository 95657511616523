import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import packageJson from '../../../package.json';

const FooterVersion = inject("stores") (
    observer (
        class FooterVersion extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    year: 2022
                }
            }

            componentDidMount = () => {
                const year = new Date().getFullYear();
                this.setState({
                    year: year
                })
            }
  
            render() {

                return (
                    <div className="footer__version">
                        v{packageJson.version}
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FooterVersion);