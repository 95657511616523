import React, { createRef, Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import FilterCategories from './filter-categories';
import FilterTypes from './filter-types';
import FilterViews from './filter-views';
import {
    isBrowser,
    isMobile
} from "react-device-detect";

const Filters = inject("stores") (
    observer (
        class ListCategories extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.widthGridChange = 768;
                this.elementRef = createRef();

                this.state = {
                    isSticky: false
                }
            }

            componentDidMount = () => {
                window.scrollTo(0, 0);
                let px = 0;
      
                if (this.elementRef.getBoundingClientRect().width > this.widthGridChange) {
                    px = this.convertRemToPixels(3);
                }
         
                const posTop = this.elementRef.getBoundingClientRect().top - px;
                this.storeUi.setPosTopFilters(posTop);
                const filtersHeight = this.elementRef.getBoundingClientRect().height;
                this.storeUi.setHeightFilters(filtersHeight);

                document.addEventListener("scroll", () => {
                    this.handleScroll();
                });

                console.log('SbC posTop', posTop)

                this.setState({
                    posTop: posTop
                })
            }

            handleScroll = () => {
                const position = window.pageYOffset;

                console.log('SbC position', position)

                if (this.state.isSticky) {
                    if (position < this.state.posTop) {
                        this.storeUi.setStickyFilters(false);
                        this.setState({
                            isSticky: false
                        })
                    } else {
                        
                    }
                } else {               
                    if ((position >= this.state.posTop)) {
                        this.storeUi.setStickyFilters(true);
                        this.setState({
                            isSticky: true
                        })
                    } else {
                        
                    }
                }
            }

            convertRemToPixels(rem) {    
                return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
            }

            render() {
                return (
                    <div 
                        className={"container-filters" + (this.state.isSticky ? " --sticky" : "")}
                        ref={(c) => { this.elementRef = c }}
                    >
                        <div className="container-filters__content">
                            <FilterCategories 
                            
                            />

                            <FilterTypes

                            />
                        </div>
                    </div>
                );
            }
        }
    )
)

export default Filters;
