import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import { Player, ControlBar } from 'video-react';
import '../assets/css/video-react.css';

const Video = inject("stores") (
    observer (
        class Video extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.urlAPP = process.env.REACT_APP_URL;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.state = {
                    video: '',
                    active_video_cc: this.props.active_video_cc
                }
            }

            componentDidMount = () => {
                this.player.subscribeToStateChange(this.handleStateChange);

                if (this.props.current_state) {
                    this.player.seek(this.props.current_state.currentTime);
                }
            }

            componentDidUpdate(prevProps) {
                if (prevProps.video_playing !== this.props.video_playing && this.props.video_playing == false) {
                    this.player.pause();
                }

                if (prevProps.video_playing !== this.props.video_playing && this.props.video_playing == true) {
                    this.player.play();
                }

                if (prevProps.video !== this.props.video) {
                    this.setState({
                        video: this.props.video
                    })
                    this.player.load();
                }
            }

            handleStateChange = (state, prevState) => {               
                if (!state.paused) {
                    this.storeUi.setActiveVideo(this.props.idx);
                    this.props.handleVideoPlaying(true);
                } 

                if (state.paused) {
                    this.props.handleVideoPlaying(false);
                } 

                this.setState({
                    player: state,
                    currentTime: state.currentTime
                });

                this.props.handleVideoState(state);
               
            }

            handleCC = () => {

            }

            play = () => {
                this.player.play();
            }
            
            pause() {
                this.player.pause();
            }
            
            load() {
                this.player.load();
            }
                      
            ref = player => {
                this.player = player;
            }

            render() {
                return (
                    <Player
                        ref = {this.ref}
                        // poster = {this.props.video.poster}
                        autoPlay = {true}
                        muted
                        controls = ''
                    >
                        <source 
                            src={this.state.video}
                        />                       
                        <ControlBar 
                            autoHide = {true}
                            className="video__controls"
                        >
                        </ControlBar>
                    </Player>
                )
            }
        }
    )
)

export default withTranslation()(Video);
