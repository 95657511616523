import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';
import TemplateCart from '../../templates/template-cart';

const PaymentError = inject("stores") (
    observer (
        class PaymentError extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                        section: this.storeUi.sectionCart,
                        orderId: 0
                }
            }

            componentDidMount = async() => {
                const orderId = await (async() => this.getUrlParams(this.props.location.search) )();
                const cart = this.storeAuth.removeCartId();

                this.setState({
                    orderId: orderId
                })
            }

            componentDidUpdate = () => {

            }

            getUrlParams = async(searchString) => {
                const params = queryString.parse(searchString)
                let orderId;

                if (params.id) {
                    orderId = params.id;
                }

                return orderId;
            }

            backToStore = () => {
                const url = "/home";
                this.props.history.push(url);
            }

            render() {
                return (
                    <TemplateCart
                    
                    >
                        <div className="page-content page-content--review">
                            <div className="section--form">
                                <div 
                                    className="wrapper--section section--header"
                                >
                                    <h2>
                                        <span>Er is helaas wat fout gegaan!</span>
                                    </h2>
                                </div>
                                <div>
                                    {/* <p>Je ordernummer is: {this.state.orderId}</p> */}
                                    <p>We hebben je betaling niet ontvangen en kunnen je bestelling dan ook niet uitvoeren.</p>
                                    <p>Neem contact met ons op via ikshashashop@gmail.com zodat we je kunnen helpen.</p>
                                </div>

                                <div 
                                    className="section__columns"
                                >
                                    <div 
                                        className="section__column section__column--single"
                                    >
                                        <div className="form-row form-row--buttons --single --focus"></div>
                                            <div 
                                                className="form-row__button --active button--forward"
                                                onClick={() => this.backToStore()}
                                            >
                                                <div 
                                                    className="button button--primary"
                                                    
                                                >
                                                    <span className="button--arrow --right" />
                                                </div>
                                                <div 
                                                    className="button-text --link"
                                                >
                                                    Verder met winkelen
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </TemplateCart>
                );
            }

        }
    )
)
                    
export default PaymentError;
