import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockLeader = inject("stores") (
    observer (
        class BlockLeader extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
    
                this.storeUi = this.props.stores.storeUi;
                this.storeVisuals = this.props.stores.storeVisuals;

                this.state = {
                    quote: i18n.t("quote.shop")
                }
            }

            componentDidMount = () => {

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.quote !== this.props.quote) {
                    const data = await this.storeVisuals.getQuote(this.props.quote);
                    const quote = data.quote;

                    this.setState({
                        quote: quote
                    })
                }  
            }

            render() {
                return (
                    <div className="container-header__leader">
                        <div
                            className = "container-header__segment"
                        >
                            Shop
                        </div>
                        <div
                            className = "container-header__divider"
                        />
                        <div 
                            className="container-header__quote"
                            dangerouslySetInnerHTML={{ __html: this.state.quote}}
                        />
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockLeader);
