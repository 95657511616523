import * as apifetch from '../fetch.js';

// export async function getShopProducts(source, id) {
//     const urlEndpoint = 'products/' + section + '/' + type;

//     return apifetch.ikFetch(urlEndpoint, 'GET');
// }

export async function getShopProduct(sku) {
    const urlEndpoint = 'products/product/' + sku;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getProducts(section, type) {
    const urlEndpoint = 'products/' + section + '/' + type;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getProductData(sku) {
    const urlEndpoint = 'products/product/' + sku;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getProductPrice(sku) {
    const urlEndpoint = 'products/price/' + sku;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getBrandProducts(brand) {
    const urlEndpoint = 'products/brands/' + brand;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getProductFilters() {
    const urlEndpoint = 'products/filters';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getSoonInShop() {
    const urlEndpoint = 'products/soon';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}