import { observable, computed, action } from "mobx";

class StoreShop {
    list_products = []
    affProductUrl = '';

    constructor() {

    }

    getShopProductsCategory() {

    }

    setAffProductUrl(url) {
        this.affProductUrl = url;
    }
}

// decorate(StoreShop, {
//     affProductUrl: observable,
//     list_products: observable,
//     getShopProductsCategory: action,
//     setAffProductUrl: action
// })

export default StoreShop;