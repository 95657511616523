import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import validate from '../../utilities/validation-rules';

import RowText from '../form-rows/row-text';

import TextInput from '../../elements/form-input-text';
import Select from '../../elements/form-input-select';

const FormAddress = inject("stores") (
    observer (
        class FormAddress extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    formControls: { 
                        invoiceStreet: {
                            type: "text",
                            label: i18n.t("forms.label.street"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.delivery_street"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceHousenr: {
                            type: "text",
                            label: i18n.t("forms.label.housenr"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.housenr"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceAddition: {
                            type: "text",
                            label: i18n.t("forms.label.addition"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.addition"),
                            validationRules: {
                            },
                            valid: true
                        },
                        invoiceZipcode: {
                            type: "text",
                            label: i18n.t("forms.label.zipcode"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.delivery_zipcode"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false,
                            required: true
                        },
                        invoiceCity: {
                            type: "text",
                            label: i18n.t("forms.label.city"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.delivery_city"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false,
                            required: true
                        },
                        // country_select: {
                        //     label: i18n.t("forms.label.country"),
                        //     value: 'NL',
                        //     placeholder: i18n.t("forms.placeholder.country"),
                        //     touched: false,
                        //     validationRules: {
                        //         //isRequired: true,
                        //     },
                        //     options: this.storeUi.countriesList
                        // }
                    },
                    formIsValid: false
                }
            }

            componentDidMount = async() => {
                const cartId = this.storeAuth.getCartId();
                const cartAddresses = await this.storeCarts.getCartAddresses(cartId);

                if (cartAddresses) {
                    this.initForm(cartAddresses[0]);
                }
            }

            componentDidUpdate = async(prevProps) => {
                
            }

            initForm = async(addresses) => {
                let name = '';
                let value = '';

                name = 'invoiceStreet';
                value =  addresses[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }

                name = 'invoiceHousenr';
                value =  addresses[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }

                name = 'invoiceAddition';
                value =  addresses[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }

                name = 'invoiceZipcode';
                value =  addresses[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }

                name = 'invoiceCity';
                value =  addresses[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }
            }

            initRow = async(name, value) => {
                const valid = true;
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);
                this.setState({
                    formControls: updatedControls
                })
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);
                this.props.handleBtnAddress(formIsValid);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }, () => this.saveAddress());
            }

            saveAddress = async() => {
                const data = this.state.formControls;
                const cartId = await this.storeAuth.getCartId();
                this.storeCarts.saveCartAddresses(cartId, data);
            }
          
            render() {
                return (
                    <form className="form">
                        {Object.keys(this.state.formControls).map((key, i) => (
                            <React.Fragment>
                                {(key !== "country_select" && 
                                  key !== "has_subscription") &&                               
                                    <React.Fragment>
                                        {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                            <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                validationMsg = {this.state.formControls[key].validationMsg}
                                                onValidate = {this.validateRow}
                                                disable = {this.state.formControls[key].disable}
                                            />
                                        }
                                    </React.Fragment>
                                } 

                            </React.Fragment>
                        ))}
                    </form>
                )
            }
        }
    )
)

export default FormAddress;
