import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as auth from '../communicator/shop/auth';
//import * as login from '../communicator/login';

class StoreAuth {
    user = {
        guestId: 0,
        guestGUID: 0,
        accountId: 0,
        accountGUID: 0,
        isLoggedin: false,
        isAdmin: false,
        accessLevel: 0
    }
    userType = 'guest'
    cartId = 0

    constructor() {
        // (async() => await this.getUserId())();
        // this.getUserGUID();
        // this.getAccountId();
        // this.getAccountGUID();
    }

    // getUserId = async() => {
    //     let id = 0;
    //     if (this.user.isLoggedin) {
    //         (async() => await this.getAccountId())();
    //         (async() => await this.getAccountGUID())();
    //     } else {
    //         if (this.user.guestId === 0) {
    //             (async() => await this.getGuestId())();
    //         }
    //     }
    // }

    // getGuestId = async() => {
    //     if (localStorage.getItem('SHA_guestId')) {
    //         this.user.guestId = localStorage.getItem('SHA_guestId');
    //     } else {
    //         try {
    //             const returnData = await auth.createGuestId();
    //             runInAction(() => {
    //                 this.setGuestId(returnData.data[0])
    //                 this.setGuestGUID(returnData.data[0])
    //             })
    //         } catch (error) {
    //             runInAction(() => {
    //                 this.state = "error"
    //             })
    //         } finally {

    //         }
    //     }
    // }

    // setGuestId(guest) {
    //     this.user.guestId = guest.id;
    //     localStorage.setItem('SHA_guestId', guest.id)
    // }

    // setGuestGUID(guest) {
    //     this.user.guestGUID = guest.GUID;
    //     localStorage.setItem('SHA_guestGUID', guest.GUID)
    // }

    getAccountId = async() => {
        if (localStorage.getItem('SHA_accountId')) {
            this.user.accountId = localStorage.getItem('SHA_accountId');
            this.user.guestId = 0;
            this.user.isLoggedin = true;
        } else {
            this.user.accountId = 0;
            this.user.isLoggedin = false;
        }
    }

    getAccountGUID() {
        if (localStorage.getItem('SHA_accountGUID')) {
            this.user.accountGUID = localStorage.getItem('SHA_accountGUID');
            this.user.guestGUID = 0;
            this.user.isLoggedin = true;
        } else {
            this.user.accountGUID = 0;
            this.user.isLoggedin = false;
        }
    }

    async checkLogin(formControls) {
        let result;
        // const data = {
        //     email: formControls.email.value,
        //     pw: formControls.password.value
        // };
        
        // try {
        //     const returnData = await login.checkLogin(data);
        //     runInAction(() => {
        //         result = returnData.data[0];
        //         if (!('error' in result)) {
        //             this.user.user_id = result.id;
        //             this.user.access_level = result.access_level;
        //             this.user.is_loggedin = true;
        //             this.user.is_admin = (result.admin === "on" || result.admin === "1")
        //                 ?   true
        //                 :   false
        //             this.user.is_partner = (result.is_partner === "on" || result.is_partner === "1")
        //                 ?   true
        //                 :   false
        //             this.user.is_reseller = (result.is_reseller === "on" || result.is_reseller === "1")
        //                 ?   true
        //                 :   false
        //         } else {
        //             this.user.is_loggedin = false;
        //         }
        //         return result;
        //     })
        // } catch (error) {
        //     runInAction(() => {
        //         this.user.is_loggedin = false;
        //     })
        // }

        return result;
    }

    refreshAuth(data) {
        // if ('is_partner' in data) {
        //     this.user.is_partner = ((data.is_partner === "on" || data.is_partner === "1") && data.partner_code !== '')
        //         ?   true
        //         :   false
        // }
    }

    setAuth(data) {
        if ('id' in data) {
            this.user.userId = data.id;
        };
        if ('user_id' in data) {
            this.user.userId = data.user_id;
        };
        this.user.access_level = data.access_level;
        if ('user_id' !== '' && 'user_id' !== '0' && 'user_id' !== 0) {
            this.user.is_loggedin = true;
        }
        if ('is_loggedin' in data) {
            this.user.is_loggedin = data.is_loggedin;
        }
        if ('is_admin' in data) {
            this.user.is_admin = (data.is_admin === "on" || data.is_admin === "1" || data.is_admin === true)
                ?   true
                :   false
        }
        if ('admin' in data) {
            this.user.is_admin = (data.admin === "on" || data.admin === "1")
                ?   true
                :   false
        }
        this.user.is_partner = data.is_partner;
        this.user.is_reseller = data.is_reseller;
    }

    setLoginState(login) {
        this.user.is_loggedin = login;

        if (!login) {
            this.setLogoutState();
        }
    }

    

    getUserGUID() {
        if (localStorage.getItem('SHA_userGUID')) {
            this.user.userGUID = localStorage.getItem('SHA_userGUID');
        } else {
            this.user.userGUID = 0;
        }
    }

    setAccountId(user) {
        localStorage.setItem('SHA_accountId', user.accountId)
       }

    setAccountGUID(user) {
        localStorage.setItem('SHA_accountGUID', user.accountGUID)
    }

    setCartId(data) {
        localStorage.setItem('SHA_cartId', data.cartId);
        this.cartId = data.cartId;
    }

    getCartId() {
        let id = this.cartId;
        
        if (localStorage.getItem('SHA_cartId')) {
            id = localStorage.getItem('SHA_cartId');
            this.cartId = id;
        } 

        return id;
    }

    removeCartId(data) {
        localStorage.removeItem('SHA_cartId');
    }

    setLogoutState() {
        this.cartId = 0;
        this.user.userId = 0;
        this.user.userGUID = 0;
        this.user.accountId = 0;
        this.user.accountGUID = 0;
        this.user.access_level = 0;
        this.user.is_loggedin = false;
        this.user.is_admin = false;
        this.user.is_partner = false;
        this.user.is_reseller = false;
    }
}

export default StoreAuth;