import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import i18n from "i18next";

//import * as addresses from '../../../communicator/shop/getAddressUtils';

import FormProfile from '../forms/form-profile'
import FormAddress from '../forms/form-address'
import i18next from 'i18next';

const AccountLogin = inject("stores") (
    observer (
        class AccountLogin extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    user: {},
                    address: {}
                };
            }

            componentDidMount = async() => {
                const userId = this.storeAuth.user.userId;
                if (userId !== 0) {
                    const user = await this.storeUsers.getUser(userId);
                    const address = await this.storeUsers.getAddress(userId);
                    this.setState({
                        user: user,
                        address: address
                    }, () => console.log('SbC user:', user))
                }
            }

            gotoCartSection = (section) => {
                this.storeUi.setCartSection(section);
            }

            updateOrder = () => {
                //const data = this.state.formControls;
                const data = {};
                this.storeOrders.updateOrder(this.storeAuth.user.userId, data);
            }

            render() {
                return (
                    <React.Fragment>
                        <div className="container-cart__header">
                             <h1 className="">
                                {i18n.t("cart.header.account_login")}
                             </h1>
                             <p>Haal je gegevens op, indien je in het verleden een account hebet aangemaakt</p>
                        </div> 

                        <div className="container-cart__content container-cart__content--form">
                            <FormProfile
                                user = {this.state.user}
                            />
                        </div>
                        
                        <div className="container-cart__content">
                            <div 
                                className="form-row form-row--buttons --dialog"
                                
                            >
                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.gotoCartSection('shoppingcart')}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.goback_cart")}
                                    </div>
                                    <div className="form-row__button button--secundary">
                                        <span className="button--arrow --left"></span>
                                    </div>
                                </div>

                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.gotoCartSection('profile')}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.goto_review")}
                                    </div>
                                    <div className="form-row__button button--primary">
                                        <span className="button--arrow --right"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-cart__content">
                            Sla deze stap over en ga direct naar de volgende pagina om je gegevens in te vullen.
                        </div>

                        <div className="container-cart__content">
                            <div 
                                className="form-row form-row--buttons --dialog"
                                
                            >
                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.gotoCartSection('profile')}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.goto_profile")}
                                    </div>
                                    <div className="form-row__button button--primary">
                                        <span className="button--arrow --right"></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                );
            }
        }
    )
)
                    
export default AccountLogin;

