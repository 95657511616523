import { makeObservable, observable, computed, action} from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as visuals from '../communicator/visuals';

class StoreVisuals {
    listVideos = []

    constructor() {

    }

    async getVisuals(section) {
        let listVideos = [];
        try {
            const returnData = await visuals.getVisuals(section);
            runInAction(() => {
                listVideos = returnData.data;
                return listVideos;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return listVideos;
    }

    async getQuote(id) {
        let result = '';
        try {
            const returnData = await visuals.getQuote(id);
            runInAction(() => {
                result = returnData.data[0];
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return result;
    }
}

// decorate(StoreVisuals, {
//     listVideos: observable,
//     getVideos: action

// })

export default StoreVisuals;