import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as ui from '../utilities/ui'; 

import Template from '../templates/template.js';

import SectionPromoCompany from '../components/sections/section-promo-company';
import SectionPromoProducts from '../components/sections/section-promo-products';
import BlockUnderConstruction from '../components/blocks/general/block-under-construction.js';
import SectionPromoFriends from '../components/sections/section-promo-friends.js';

const ShopMain = inject("stores") (
    observer (
        class ShopMain extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.isProduction = process.env.REACT_APP_ENV === 'prod';

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
                ui.smoothScrollTo(0, 0, 100);
            }

            componentDidUpdate = () => {
                ui.smoothScrollTo(0, 0, 100);
            }

            onNext = (section) => {
                this.storeUi.setNavMain(section);
                let url = "/" + section;
                this.props.history.push(url);
                this.storeUi.swiper_main.slideTo(1);
            }

            render() {
                return (
                    <Template
                        leader = {true}
                        segment = "home"
                        id = "dogs"
                        story = "jack"
                    >
                        <div className="page-content">
                            {!this.isProduction &&
                                <div 
                                    className="segment-wrapper  segment-wrapper--tip"
                                >
                                    <BlockUnderConstruction

                                    />
                                </div>
                            }

                            <SectionPromoProducts

                            />        

                            {/* <SectionPromoReviews

                            />*/}

                            <div 
                                className="segment-wrapper  segment-wrapper--tip"
                            >
                                <SectionPromoCompany

                                />
                            </div>

                            {/* <div 
                                className="segment-wrapper segment-wrapper--soon"
                            >
                                <BlockProductsSoon
                                />
                            </div> */}
                            

                           {/* <div 
                                className="segment-wrapper segment-wrapper--spotlight"
                            >
                                <BlockShopSpotlight 
                                />
                            </div>  */}

                            {/* <div 
                                className="segment-wrapper"
                            >
                                <div 
                                    className="section--form"
                                >
                                    <BlockNewsletter 
                                    />
                                </div>
                            </div> */}

                            {/* <div className="segment-wrapper">
                                <div className="segment-wrapper__column">
                                    <span onClick={() => this.onNext('shop-categories')}>View all shop categories</span>
                                </div>
                            </div> */}

                            <div 
                                className="segment-wrapper segment-wrapper--tip"
                            >
                                <SectionPromoFriends

                                />
                            </div>
            
                        </div>
                    </Template>
                );
            }
        }
    )
)
        
export default withRouter(ShopMain);
