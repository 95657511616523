import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import i18n from "i18next";

import CartItems from './cart-items';
import CartReviewAddress from './cart-review-address';
import CartReviewProfile from './cart-review-address';

const CartReview = inject("stores") (
    observer (
        class CartReview extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.storeAuth = this.props.stores.storeAuth;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;

                this.state = {
                    cart: [],
                    user: {}
                }
            }

            componentDidMount = async() => {
                this.handleScroll();

                const userId = this.storeAuth.user.userId;
                if (userId !== 0) {
                    const user = await this.storeUsers.getUser(userId);
                    this.setState({
                        user: user
                    }, () => console.log('SbC user:', user))
                }
            }

            gotoCartSection = (section) => {
                this.storeUi.setCartSection(section);
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            render() {
                return (
                    <div className="container-cart-review">
                        <div className="container-cart__header">
                            <h1 className="">{i18n.t("cart.header.review")}</h1>
                            <p>Als alles ok is, kun je hierna betalen.</p>
                        </div>

                        <div className="container-cart__content">
                            <div className="container-cart__products">
                                {(this.storeCarts.cart !== null && this.storeCarts.cart.length !== 0)
                                    ?   <CartItems 
                                            editable = {false}
                                        />
                                    :   <span>Je winkelwagen is nog leeg</span>
                                }
                            </div>
                        </div>

                        <div className="container-cart__content">
                            <div 
                                className="form-row form-row--buttons --dialog"
                                
                            >
                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.gotoCartSection('overview')}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.edit_cart")}
                                    </div>
                                    <div className="form-row__button button--secundary">
                                        <span className="button--arrow --left"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-cart__header">
                            <h1 className="">{i18n.t("cart.header.review_address")}</h1>
                        </div>

                        <div className="container-cart__content">
                            <div className="container-cart__user">
                                {Object.keys(this.state.user).map((key, i) => (
                                    <dl>
                                        <dt>{key}</dt>
                                        <dd>{this.state.user[key]}</dd>
                                    </dl>
                                ))}
                            </div>
                        </div>

                        <div className="container-cart__content container-cart__content--columns">
                            <div className="container-cart__content--column">
                                <div className="container-cart___section-header">
                                    <h3>{i18n.t("cart.header.review_profile")}</h3>
                                </div>
                                <div className="container-cart__products">
                                    <CartReviewProfile 
                                        type = "billing"
                                    />
                                </div>
                            </div>

                            <div className="container-cart__content--column">
                                <div className="container-cart___section-header">
                                    <h3>{i18n.t("cart.header.review_invoice_address")}</h3>
                                </div>
                                <div className="container-cart__products">
                                    <CartReviewAddress 
                                        type = "billing"
                                    />
                                </div>
                            </div>

                            <div className="container-cart__content--column">
                                <div className="container-cart___section-header">
                                    <h3>{i18n.t("cart.header.review_delivery_address")}</h3>
                                </div>
                                <div className="container-cart__products">
                                    <CartReviewAddress 
                                        type = "shipping"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-cart__content">
                            <div 
                                className="form-row form-row--buttons --dialog"
                                
                            >
                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.gotoCartSection('profile')}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.edit_profile")}
                                    </div>
                                    <div className="form-row__button button--secundary">
                                        <span className="button--arrow --left"></span>
                                    </div>
                                </div>

                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.gotoCartSection('payment')}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.goto_payment")}
                                    </div>
                                    <div className="form-row__button button--primary">
                                        <span className="button--arrow --right"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
)
                    
export default CartReview;

