import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CartFooter from '../../components/cart/cart-footer';

import CartOverview from '../../components/cart/cart-overview';
import CartLogin from '../../components/cart/cart-login';
import CartProfile from '../../components/cart/cart-profile';
// import LoginForm from '../../components/account/account-login-form';
// import CartAddress from '../../components/cart/cart-address';
// import CartShipping from '../../components/cart/cart-shipping';
import CartPayment from '../../components/cart/xcart-payment';
import CartReview from '../../components/cart/cart-review';
// import CartConfirmation from '../../components/cart/cart-confirmation';
// import CartConfirmationError from '../../components/cart/cart-confirmation-error';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';
import TemplateCart from '../../templates/template-cart';

const ShoppingCart = inject("stores") (
    observer (
        class ShoppingCart extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    section: this.storeUi.sectionCart
                }
            }

            componentDidMount = async() => {
                window.scroll(0,0);
                
            }

            componentDidUpdate = () => {
                this.storeCarts.getCartAmount(this.storeAuth.cartId);
            }

            gotoCartSection = (section) => {
                //this.props.stores.storeUi.setCartSection(section);
            }

            followUpLoginSubmit = () => {
                console.log('SbC logging in...')
                this.gotoCartSection('address');
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <TemplateCart>
                        <div className="page-content page-content--cart">
                            <div className="section--form">
                                <div 
                                    className="wrapper--section section--header"
                                >
                                    <h2>
                                        <span>Mijn winkelwagen</span>
                                    </h2>
                                </div>
                            </div>

                            <div className="section--form">
                                <div className="section--form__columns">
                                    <div className="section--form__column">
                                        <div className="content--form">
                                            <CartOverview 
                                            />
                                        </div>
                                    </div>
                                </div>          
                            

                                <div className="form-row form-row--buttons --block">
                                    <div 
                                        className="form-row__button --active button--back"
                                        onClick={() => this.gotoPage('/shop/products')}
                                    >
                                        <div 
                                            className="button button--secundary"
                                        >
                                            <span className="button--arrow --left" />
                                        </div>
                                        <div 
                                            className="button-text --link"
                                        >
                                            Terug naar producten
                                        </div>
                                    </div>
                                    
                                    {(this.storeCarts.cart && this.storeCarts.cart.length !== 0)
                                    ?   <div 
                                            className="form-row__button --active button--forward"
                                            onClick={() => this.gotoPage('/review')}
                                        >
                                            <div 
                                                className="button button--primary"
                                            >
                                                <span className="button--arrow --right" />
                                            </div>
                                            <div 
                                                className="button-text --link"
                                            >
                                                Bestel deze artikelen
                                            </div>
                                        </div>
                                    :   <div 
                                            className="form-row--button --inactive button--forward"
                                        >
                                            <div 
                                                className="button button--primary --disabled"
                                            >
                                                <span className="button--arrow --right" />
                                            </div>
                                            <div 
                                                className="button-text"
                                            >
                                                Bestel deze artikelen
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </TemplateCart>
                );
            }

        }
    )
)
                    
export default ShoppingCart;
