export function getDefaultNav() {
    const defaultNav = {
        
    }

    return defaultNav;
}



