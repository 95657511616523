import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import validate from '../../utilities/validation-rules';

import RowText from '../form-rows/row-text';

import Checkbox from '../../elements/form-input-checkbox';
import Select from '../../elements/form-input-select';

const FormLogin = inject("stores") (
    observer (
        class FormLogin extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    error_email: false,
                    error_email_empty: false,
                    formIsValid: false,
                    formControls: {
                        email: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: false
                            },
                            valid: true
                        },
                        password: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: false
                            },
                            valid: true
                        }
                    }
                }
            }

            componentDidMount = async() => {
            } 

            componentDidUpdate = async(prevProps) => {

            }

            initRow = async(name, value) => {
                const valid = true;
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);
                this.setState({
                    formControls: updatedControls
                })
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }, () => this.saveAccount());
            }

            saveAccount = () => {
                const data = this.state.formControls;
                if (this.storeAuth.user.userId !== 0) {
                    this.storeUsers.saveUser(this.storeAuth.user.userId, data);
                } else {
                    this.storeUsers.saveUseToLS(this.storeAuth.user.userId, data);
                }
            }

            render() {
                return (
                    <form className="form">
                        {Object.keys(this.state.formControls).map((key, i) => (  
                            <React.Fragment>
                                {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                    <RowText 
                                        type = {this.state.formControls[key].type}
                                        name = {key}
                                        value = {this.state.formControls[key].value}
                                        validationRules = {this.state.formControls[key].validationRules}
                                        validationMsg = {this.state.formControls[key].validationMsg}
                                        onValidate = {this.validateRow}
                                        disable = {this.state.formControls[key].disable}
                                    />
                                }
                            </React.Fragment>
                               
                        ))}
                    </form>
                )
            }
        }
    )
)

export default withTranslation()(FormLogin);
