import React, { Component } from "react"; 

export class RedirectPayment extends Component { 
    constructor(props) { 
        super(); 
        this.props = props;
        this.state = { ...props }; 
    } 
    
    componentDidMount = () => { 
        window.location = this.props.loc; 
    } 
    
    render(){ 
        return (
            <section>Redirecting...</section>
        ) 
    } 
}

export default RedirectPayment;