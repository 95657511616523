import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

const NavTopBar = inject("stores") (
    observer (
        class NavTopBar extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeCarts = this.props.stores.storeCarts;
            }

            componentDidMount = async() => {
                const cartId = this.storeAuth.getCartId();
                const result = await this.storeCarts.getCartAmount(cartId);
            }

            onNext = (section) => {
                let url = "/" + section;
                this.props.history.push(url);
            }

            render() {
                return (
                    <div className="nav-topbar">
                        <ul className="nav-topbar__list nav-topbar__list--service">
                            <li 
                                className="nav-topbar__list-item"
                            >
                                <span 
                                    className="--link" 
                                    onClick={(e) => this.onNext('home')}
                                >
                                    {i18n.t("topbar.home")}
                                </span>
                            </li>
                            <li 
                                className="nav-topbar__list-item"
                            >
                                <span 
                                    className="--link" 
                                    onClick={(e) => this.onNext('shop/products')}
                                >
                                    {i18n.t("topbar.products")}
                                </span>
                            </li>
                            <li 
                                className="nav-topbar__list-item"
                            >
                                <img 
                                    src={this.urlAPI + "public/images/icons/ic-cart.png"} 
                                />
                                <Link to='/shoppingcart'>{i18n.t("topbar.shopping_cart")}</Link>
                                <span 
                                    className="navigation--main__cart-counter"
                                >
                                   <span>{this.storeCarts.cartAmount}</span> 
                                </span>
                            </li>
                            {/* <li 
                                className="nav-topbar__list-item"
                            >
                                <Link to='/set-language'>{i18n.t("topbar.language")}</Link>
                            </li> */}
                        </ul>

                        {/* <ul className="nav-topbar__list nav-topbar__list--account">
                            {this.storeAuth.user.isLoggedin 
                            ?   <React.Fragment>                           
                                    <li className="nav-topbar__list-item"><Link to='/overview-account'>{i18n.t("topbar.manage_account")}</Link></li>
                                    <li className="nav-topbar__list-item" onClick={(e)=>this.handleLogOut(e)}>{i18n.t("topbar.logout")}</li>
                                </React.Fragment>    
                            :   <li className="nav-topbar__list-item"><Link to='/login'>{i18n.t("topbar.login")}</Link></li>
                            
                        }
                        </ul> */}
                    </div>
                );
            }
        }
    )
)

export default withTranslation()(withRouter(NavTopBar));
