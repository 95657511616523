import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Product from '../product.js';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

export default class SubCategory extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    getMainCatLink(catName) {
        let catLink = '/shop/' + catName;
        return catLink;
    }

    render() {
        return (
            <div className="page-product">
                <span>
                    <Link to="/shop/home">SHOP HOME</Link> 
                    - 
                    <Link to={this.getMainCatLink(this.props.parent_cat)}>{this.props.parent_cat}</Link>
                </span>
                <h2>SUB CAT :: {this.props.subcat}</h2>

                <h2>Products in this category</h2>
                <ul>
                    {this.props.products.map((product, ii) => (
                        <React.Fragment>
                            <li>
                                {product.name &&
                                    <Link to={product.path}>{product.name}</Link>
                                }
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        );
    }
}
