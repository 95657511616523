import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "../../i18n";

import BlockVideo from './block-video';
import BlockVisual from './block-visual';
import BlockTypewriter from './block-typewriter';
import BlockLeader from './block-leader';

const Branding = inject("stores") (
    observer (
        class Branding extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeVisuals = this.props.stores.storeVisuals;
                this.storeUsers = this.props.stores.storeUsers;

                this.state = {
                    randVideo: "",
                    dogname: this.storeUsers.dogName
                }
            }

            componentDidMount = async() => {
                const visuals = await this.storeVisuals.getVisuals('shop');

                const max = visuals.length;
                const rand = Math.floor((Math.random() * max));

                const filename = visuals[rand].filename;
                const filetype = visuals[rand].filetype;
                const orientation = visuals[rand].orientation;
                const theme = visuals[rand].theme;
                const quote = visuals[rand].quote;

                this.setState({
                    filename: filename,
                    filetype: filetype,
                    orientation: orientation,
                    theme: theme,
                    quote: quote
                })
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.dogName !== this.props.dogName) {
                    this.setState({
                        dogName: this.props.dogName
                    })
                }
            }

            onNext = (section) => {
                let url = "/" + section;
                this.props.history.push(url);
            }
   
            render() {
                const class_template = "container-header container-header--" + this.props.segment + " --" + this.state.theme + (this.props.leader ? " --leader" : "");
               
                return (
                    <div className={class_template}>
                        <div
                            className="container-header__bg--mobile"
                        >
                            <div className="container-header__branding--mobile">
                                <div 
                                    className="container-header__logo --link"
                                    onClick={(e) => this.onNext('home')}
                                />
                                <div 
                                    className="container-header__logo-txt"
                                >
                                    <span className="container-header__logo-txt--ik"
                                        onClick={(e) => this.onNext('home')}
                                    >ik</span>
                                    <span className="container-header__logo-txt--name"
                                        onClick={(e) => this.onNext('home')}
                                    >
                                        <BlockTypewriter
                                            dogname = {this.state.dogname}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        {this.props.leader &&
                            <div className="container-header__top-visual">
                                {this.state.filetype === 'img' &&
                                    <BlockVisual 
                                        id = {"dogs"}
                                        filename = {this.state.filename}
                                        segment = {"shop"}
                                    />
                                }
                                {/* {this.state.filetype === 'video' &&
                                    <BlockVideo
                                        filename = {this.state.filename}
                                        orientation = {this.state.orientation}
                                    />
                                } */}

                                <BlockLeader 
                                    quote = {this.state.quote}
                                />
                            </div>
                        }
                        
                        <div className="container-header__branding">
                            <div 
                                className="container-header__logo --link"
                                onClick={(e) => this.onNext('home')}
                            />
                            <div 
                                className="container-header__logo-txt"
                            >
                                <span 
                                    className="container-header__logo-txt--ik"
                                    onClick={(e) => this.onNext('home')}
                                >ik</span>
                                <span
                                    className="container-header__logo-txt--name"
                                    onClick={(e) => this.onNext('home')}
                                >
                                    <BlockTypewriter
                                        dogname = {this.state.dogname}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Branding);
