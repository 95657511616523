import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

import Product from './product';

const ListProductsRecommended = inject("stores") (
    observer (
        class ListProductsRecommended extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.state = {
            products: []
        }
    }

    async componentDidMount() {
        let apiProducts;
        let category_id = this.props.category.category_id;

        if (this.props.stores.storeUi.app_auth.access_token === '') {
            await this.props.stores.storeUi.getAccessToken();
            apiProducts = await utils.getShopProductsPerCategory(category_id, this.props.stores.storeUi.app_auth.access_token);
            console.log('SbC products api::', apiProducts)
        } else {
            apiProducts = await utils.getShopProductsPerCategory(category_id, this.props.stores.storeUi.app_auth.access_token);
            console.log('SbC products api::', apiProducts)
        }

        this.setState({
            products: apiProducts.data
        });
    }

    setActiveCatSlide(idx) {

    }

    render() {
        return (
            <div className="container">
                <h3>Products in {this.props.category.name}</h3>

                <ul className="list-products-per-category">
                    {this.state.products && this.state.products.length > 0
                    ?   this.state.products.map((product, idx) => (
                            <Product
                                product = {product}
                            />              
                        ))
                    :   <li>
                            <span>No products</span>
                        </li>
                    }
                </ul>
            </div>
        );
  }
}

    )
)

export default ListProductsRecommended;
