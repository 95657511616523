import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

const DogFriendly = inject("stores") (
    observer (
        class DogFriendly extends Component {
            constructor(props) {
                super(props);
                this.props = props;
            }

            componentDidMount() {
               
            }

            goBack = () => {
                this.props.history.goBack();
            }

            handleSubmit = (e) => {

            }
        
            render() {               
                return (
                    <div className="wrapper-content">
                        DOG FRIENDLY
                    </div>
                );
            }
        }
    )
);

export default DogFriendly;