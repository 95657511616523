import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const FilterCategories = inject("stores") (
    observer (
        class FilterCategories extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeProducts = this.props.stores.storeProducts;

                this.state = {
                    sections: [
                        'snacks',
                        'dogfood'
                    ]
                }
            }

            componentDidMount = () => {

            }

            handleFilter = async(filter, value) => {
                this.storeUi.setFilterShop(filter, value);
                const products = await this.storeProducts.getProducts(this.storeUi.filtersShop.category, this.storeUi.filtersShop.type);
            }

            render() {
                let className = this.storeUi.filtersShop.category

                return (
                    <div className="container-filter container-filter--categories">
                        <ul>
                            <li
                                className = {"--link" + (className === 'snacks' ? ' --active' : '')}
                                onClick = {() => this.handleFilter('category', 'snacks')}
                            >
                                snacks
                            </li>
                            {/* <li
                                className = {"--link" + (className === 'dogfood' ? ' --active' : '')}
                                onClick = {() => this.handleFilter('category', 'dogfood')}
                            >   
                                hondenbrokken
                            </li> */}
                        </ul>
                    </div>
                );
            }
        }
    )
)

export default FilterCategories;
