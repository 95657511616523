import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import queryString from 'query-string';

import TemplateShop from '../../templates/template-shop.js';

import ListCategories from './list-categories.js';
import Category from './category';
import SubCategory from './subcategory.js';

import * as shop from '../../communicator/shop/shop';

const ShopCategories = inject("stores") (
    observer (
        class MainShop extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.APP_HOME = 15;
                this.CAT_HOME = 2;
                this.SHOP_CATS = props.SHOP_CATS;
                this.CAT_SLIDES = props.CAT_SLIDES;

                this.state = {
                    activeCat: 'HOME',
                    activeSlide: 0,
                    CAT_SLIDES: [],
                    CAT_PRODUCTS: [{}]
                }
            }

            async componentDidMount() {
                let apiCats;
                if (this.props.stores.storeUi.app_auth.access_token === '') {
                    await this.props.stores.storeUi.getAccessToken();
                    apiCats = await shop.getShopCategories(this.props.stores.storeUi.app_auth.access_token);
                    console.log('SbC cat api::', apiCats)
                } else {
                    apiCats = await shop.getShopCategories(this.props.stores.storeUi.app_auth.access_token);
                }

                this.setState(state => {
                    const CAT_SLIDES = state.CAT_SLIDES.concat(apiCats.data);
                    return { 
                        CAT_SLIDES 
                    };
                });

                const apiProducts = await shop.getShopProductsPerCategory();
                let shopProducts = this.state.CAT_PRODUCTS;
                shopProducts.push.apply(shopProducts, apiProducts);

                console.log('SbC cat products::', shopProducts)

                this.setState({
                    CAT_PRODUCTS: shopProducts
                })

                this.props.stores.storeUi.resetCartSection();

                this.checkAccessToken();

                //SbC should be further in front of process 
                this.setLanguage();
            }

            checkAccessToken() {
                if (this.props.stores.storeUi.app_auth.access_token === '') {
                    this.props.stores.storeUi.getAccessToken();
                }
            }

            setLanguage() {
                let lng = this.props.stores.storeUi.app_interface.lang;
                if (lng === '' && localStorage['i18nextLng']) {
                    lng = localStorage.getItem('i18nextLng');
                    this.props.stores.storeUi.setLocale(lng); 
                }
            }

            setActiveSlide = (idx) => {

            }

            setOptions = (swiper) => {
                this.swiper = swiper;
            }

            moveToSlide(idx) {
                if (this.swiper) {
                    this.swiper.slideTo(idx);
                }
            }

            moveToNext() {
                this.swiper.slideNext();
            }

            moveToPrev() {
                this.swiper.slidePrev();
            }

            getSubCatName(catId) {
                function findArrayElementByTitle(array, title) {
                    return array.find((element) => {
                    return element.id === title;
                    })
                }
                let subCat = findArrayElementByTitle( this.SHOP_CATS, catId)
                return subCat.name;
            }

            getSubCatLink(catName) {
                catName = catName.toLowerCase();
                return catName;
            }

            showShopPage = (props) => {
                let catIndex = this.SHOP_CATS.findIndex(x => x.name == props.match.params.number.toUpperCase());
                let parentCat =  'HOME';

                //SbC:: get name of main cat if sub cat
                if (this.SHOP_CATS[catIndex].level === 3) {
                    this.SHOP_CATS.map((cat, i) => {
                        if (cat.cats_sub) {
                            if (cat.cats_sub.findIndex(x => x.id == this.SHOP_CATS[catIndex].id) != -1) {
                                parentCat = this.SHOP_CATS[i].name;
                            };
                        }
                    })
                }
            
                if (this.SHOP_CATS[catIndex].name !== 'HOME') {
                    if (this.SHOP_CATS[catIndex].level === 2) {
                        return this.showCat(props);
                    } else {
                        return <SubCategory 
                            subcat = {props.match.params.number.toUpperCase()}
                            parent_cat = {parentCat}
                            products = {this.state.CAT_PRODUCTS}
                        />;
                    }
                } else {
                    return this.showCat(props);
                }
            }

            showNavCategories() {
                return <div>
                        <ListCategories 
                            categories = {this.state.CAT_SLIDES}
                            onNext = {this.props.onNext} 
                            segment = 'SHOP'
                        />
                    </div>
            }

            showCat = (props) => {

                return (                 
                    <Swiper
                        swiperOptions={{
                            scrollbarHide: false,
                            swipeHandler: '.home-swiper__content'
                        }}
                        {...this.state.options}
                        onInitSwiper={(swiper, event) => (
                            this.setOptions(swiper, props)
                        )}
                    >
                        {(new Array(this.state.CAT_SLIDES.length).fill(null).map((_, i) => (
                            
                            <Slide className="main-swiper__slide" key={i} onActive={swiper => this.setActiveSlide(i)}>
                                {this.CAT_SLIDES[i].name === 'HOME'
                                ?   <React.Fragment>
                                        <div className="home-swiper__content">
                                            <div className="container-navigation-cats">
                                                <div className={"heading-cat-container heading-cat-container--" + this.CAT_SLIDES[i].title}>
                                                    <h1 className="heading-cat">{this.CAT_SLIDES[i].title}</h1>
                                                </div>
                                                <ListCategories 
                                                    cats = {this.state.CAT_SLIDES}
                                                    slide = 'HOME'
                                                />
                                            </div>

                                            <h2>Featured products</h2>
                                            <ul>
                                                {this.state.CAT_PRODUCTS.map((prodItem, i) => (
                                                    <React.Fragment>
                                                        {prodItem.cats && 
                                                            <React.Fragment>
                                                                {prodItem.cats.map((cat, ii) => (
                                                                    <React.Fragment>
                                                                        {parseInt(cat.id) === this.CAT_HOME &&
                                                                            <li>
                                                                                <Link to={this.state.CAT_PRODUCTS[i].path}>{this.state.CAT_PRODUCTS[i].name}</Link>
                                                                            </li>
                                                                        }
                                                                    </React.Fragment>
                                                                ))}
                                                            </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </ul>

                                        </div> 
                                    </React.Fragment>
                                :   <React.Fragment>
                                        <div className="home-swiper__content">
                                            <div className={"heading-cat-container heading-cat-container--" + this.CAT_SLIDES[i].title}>
                                                <h1 className="heading-cat">{this.CAT_SLIDES[i].title} {this.CAT_SLIDES[i].id}</h1>
                                                {this.CAT_SLIDES[i].cats_sub && this.CAT_SLIDES[i].cats_sub.length > 0 &&
                                                    <React.Fragment>
                                                        <h2>Subcats::</h2>
                                                
                                                        <ul className="nav-subcats">
                                                        {this.CAT_SLIDES[i].cats_sub.map((subcat, iii) => (
                                                            <React.Fragment>
                                                                <li className="nav-subcats__item">
                                                                    {subcat.id} :: 
                                                                    <Link to={this.getSubCatLink(this.getSubCatName(subcat.id))}>{this.getSubCatName(subcat.id)}</Link>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                        </ul>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </Slide>
                        )))}
                    </Swiper>
                )
            }

            render() {
                let activeSlide = this.props.stores.storeUi.app_interface.active_cat_slide;
                this.moveToSlide(activeSlide);

                return (
                    <TemplateShop
                        segment = "shop"
                        id = "shop"
                    >
                         <div className="shop-swiper">
                            <div className="shop-swiper__nav-cats">
                                <span onClick={() => this.moveToPrev()}>PREV CAT</span>
                                <span onClick={() => this.moveToNext()}>NEXT CAT</span>
                            </div>

                            <Swiper 
                                swiperOptions={{
                                    scrollbarHide: false,
                                    swipeHandler: '.home-swiper__content'
                                }}
                                {...this.state.options}
                                onInitSwiper={(swiper, event) => (
                                    this.setOptions(swiper)
                                )}
                            >
                                <Slide className="shop-swiper__slide" onActive={swiper => this.setActiveSlide(0)}>
                                    <div className="shop-swiper__content">
                                        {this.showNavCategories()}    
                                    </div>                            
                                </Slide>

                                {this.state.CAT_SLIDES.map((category, i) => (
                                    <Slide className="shop-swiper__slide" key={i} onActive={swiper => this.setActiveSlide(i)}>
                                        <div>{category.id} {category.name}</div>
                                            <Category
                                                category = {category}
                                                slide = 'CATEGORY'
                                            />     
                                    </Slide>
                                ))}
                            </Swiper>


                            {/* <Switch>
                                <Route path='/shop/:number' render={({match}) => this.showShopPage({match})} />
                            </Switch> */}
                        </div>                        
                    </TemplateShop>
                );
            }
        }
    )
)

ShopCategories.propTypes = {
    CAT_SLIDES: PropTypes.array,
    SHOP_CATS: PropTypes.array
};
  
ShopCategories.defaultProps = {
    CAT_SLIDES: [{}],
    SHOP_CATS: [{}]
};

export default ShopCategories;