
import * as apifetch from '../fetch.js';

export async function createNewOrder() {
    const urlEndpoint = 'orders/new';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function updateOrder(orderId, data) {
    const urlEndpoint = 'orders/' + orderId + '/update';

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'PUT', postData);
}

export async function getOrderAddress(orderId) {
    const urlEndpoint = 'orders/' + orderId + '/address';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function submitOrder(cartId) {
    const urlEndpoint = 'orders/' + cartId + '/submit';

    const postData = {};

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function getOrderStatus(orderId) {
    const urlEndpoint = 'orders/' + orderId + '/status';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getOrderId(guid) {
    const urlEndpoint = 'orders/' + guid + '/id';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function sendOrderConfirmation(orderId, orderStatus) {
    const urlEndpoint = 'email/' + orderId + '/confirmation';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

