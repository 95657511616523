import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const ProductInsects = inject("stores") (
    observer (
        class ProductInsects extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.state = {

        }

        this.prod = {
            price: 28.00,
            subtitle: 'Xxxx',
            description_short: 'Xxx xx x xx x x x  x x x xx xx xxxx x  xx',
            img: '',
            artist: '',
            brand_logo: '',
            shop: '',
            shop_url: '',
        }
    }

    async componentDidMount() {

    }

    setActiveCatSlide(idx) {

    }

    render() {       
        return (
            <div>
                <h1>Wat?<br/>Insecten?</h1>
            </div>                    
        );
  }
}

    )
)

export default ProductInsects;
