import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import ShopProductSummary from '../products/shop-product-summary';

const ListShopProducts = inject("stores") (
    observer (
        class ListShopProducts extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.state = {
            affiliate: "",
            products: [],
            categories: [
                'organimal'
            ]
        }

        this.storeProducts = this.props.stores.storeProducts;
    }

    componentDidMount = async() => {
        const source = 'affiliates';
        let id;
        let affiliate = '';

        switch(this.props.section) {
            case "sleeping":
                id = 4;
                affiliate = 'ZooPlus';
                break;
            case "snacking":
                id = 1;
                affiliate = 'Organimal';
                break;
        }
    
        const products = await this.storeProducts.getShopProducts(source, id);

        console.log('Sbc aff id', id)
        console.log('Sbc aff products', products)

        this.setState({
            affiliate: affiliate,
            products: products
        })
    }

    componentDidUpdate = async(prevProps) => {
        const source = 'affiliates';
        let id;
        let affiliate = '';

        if (prevProps.section !== this.props.section) {
            switch(this.props.section) {
                case "sleeping":
                    id = 4;
                    affiliate = 'ZooPlus';
                    break;
                case "snacking":
                    id = 1;
                    affiliate = 'Organimal';
                    break;
            }
        
            const products = await this.storeProducts.getShopProducts(source, id);

            this.setState({
                affiliate: affiliate,
                products: products
            })
        }
    }

    setActiveCatSlide(idx) {
        //SbC:: 0 is extra shop categories overview
        this.props.stores.storeUi.setActiveCatSlide(idx + 1);
    }

    handleClick = (url) => {
        window.open(url);
    }

    render() {
        return (
            <div className="section section--list-products">
                {/* <h2 className="section__header">Producten via {this.state.affiliate}</h2> */}
                
                {this.state.products && this.state.products.map((product, idx) => (
                    <ShopProductSummary
                        section = {this.props.section}
                        affiliate = {this.state.affiliate}
                        product = {product}
                    />
                ))}
            </div>
        );
  }
}

    )
)

export default ListShopProducts;
