import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Link } from 'react-router-dom';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

const OverviewAccount = inject("stores") (
    observer (
        class OverviewAccount extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    email: "",
                    firstname: "",
                    lastname: "",
                    passwd: "",
                };
                if (this.props.stores.storeUi.app_interface.user_logged_in) {
                    this.getCustomerDetais();
                }
            }

            async getCustomerDetais() {
                let apiResponse = await account.getCustomerDetails(this.props.stores.storeUi.app_auth.access_token);
                let customerDetails = apiResponse;
                this.setState({
                    customer_id: customerDetails.customer_id,
                    email: customerDetails.email,
                    firstname: customerDetails.firstname,
                    lastname:  customerDetails.lastname
                })
                console.log('SbC account details::', customerDetails)
            }

            render() {
                
                return (
                    this.props.stores.storeUi.app_interface.user_logged_in
                    ?   <div className="wrapper-account">
                            <div className="container-account">
                                <div className="container-account__header">
                                    <h1>My account:: {this.props.stores.storeUser.user.user_id}</h1>
                                </div>

                                <div className="contain  er-account__content">
                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Email
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            {this.state.email}
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                First name
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            {this.state.firstname} 
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Last name
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            {this.state.lastname} 
                                        </div>
                                    </div>
                                </div>

                                <div className="container-account__header">
                                    <h1>Manage my account:: {this.props.stores.storeUser.user.user_id}</h1>
                                </div>

                                <div className="container-account__nav">
                                    <ul>
                                        <li>
                                            <Link to='/edit-account'>Change my details</Link>
                                        </li>
                                        <li>
                                            <Link to='/edit-addresses'>Manage addresses</Link>
                                        </li>
                                        <li>
                                            <Link to='/reset-password'>Change password</Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="container-account__header">
                                    <h1>Order history</h1>
                                </div>

                                <div className="container-account__footer">
                                    <div className="button-row">
                                        <span className="button button--account button--secundary --dev">
                                            <Link to='/shop/home'>Back to shop</Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    :   <div className="wrapper-account">
                            <div className="container-account">
                                <h1>NOT LOGGED IN</h1>
                                <Link to='/login'>Go to login</Link>
                            </div>
                        </div>
                );
            }
        }
    )
)
                    
export default OverviewAccount;

