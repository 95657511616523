import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";

import Branding from '../components/header/branding';
import TopBar from '../components/general/topbar';
import NavigationMain from '../components/general/navigation-main';
import Footer from '../components/footer/footer';

import BlockSocial from '../components/blocks/general/block-social';
import Filters from '../components/blocks/filters/filters';

const TemplateShop = inject("stores") (
    observer (
        class TemplateShop extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
            }

            componentDidMount() {

            }
   
            render() {

                return (
                    <React.Fragment>
                        <div className="topbar-container">
                            <div className="content-container">
                                <div className="content-container__content">
                                    <TopBar />
                                </div>
                            </div>
                        </div>

                        <Branding 
                            segment = {this.props.segment} 
                            id = {this.props.id} 
                            story = {this.props.story}
                            dogName = {this.storeUsers.dogName}
                        />

                        <NavigationMain 
                            useSuspense = {false}
                            segment = {this.props.segment} 
                            id = {this.props.id} 
                        />

                        <div 
                            className="wrapper--section section--filters"
                        >
                            <div 
                                className="wrapper--section section--header"
                            >
                                <h2>
                                    <span>Ik kan moeilijk kiezen hoor&nbsp;...<br/>help je me?</span>
                                </h2>
                            </div>

                            <Filters

                            />
                        </div>

                        {this.props.children}  
                        
                        {/* <div 
                            className="segment-wrapper"
                        >
                            <div className="page-section page-section--social">
                                <BlockSocial 
                                    item = {this.storeUi.social_item}
                                />
                            </div>
                        </div> */}

                        <Footer />
                     </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(TemplateShop));
