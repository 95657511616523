import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const ProductSummaryReview = inject("stores") (
    observer (
        class ProductSummaryReview extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeShop = this.props.stores.storeShop;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {

                }; 
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {

            }

            render() {
                return (
                    <React.Fragment>     
                        <div className="product-summary__column column--review">
                            REVIEW
                        </div>
                    </React.Fragment>
                );
            }
        }

    )
)

export default ProductSummaryReview;
