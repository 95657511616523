import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import * as shopUtils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

const Addaddress = inject("stores") (
    observer (
        class Addaddress extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    streetname: "",
                    housenr: "",
                    zipcode: "",
                    city: ""
                };
            }

            handleChange = e => {
                let change = {};
                change[e.target.name] = e.target.value;
                this.setState(change);
            }

            async handleSubmit(e) {
                e.preventDefault();

                const addressData = {
                    firstname: "Demo xx",
                    lastname: "User xx",
                    company: "Demo Company name",
                    postcode: this.state.zipcode,
                    city: this.state.city,
                    address_1: this.state.streetname,
                    address_2: this.state.housenr,
                    zone_id: "1256",
                    country_id: "81"
            
                    // "custom_field" => array(
                    //     "address"  => array(
                    //         "3" => "demo address 3"
                    //     )
                    // ),
                    // "default"  => 0
                }
     
                console.log('SbC address to add!::', addressData)
                let apiReturn = await account.addAddress(addressData, this.props.stores.storeUi.app_auth.access_token);
     
                if ('error' in apiReturn && apiReturn.error.length > 0 ) {
                   console.log('SbC address added error!::', apiReturn)
                } else {
                    console.log('SbC address added!::', apiReturn)
                }
            }

            render() {
                return (
                    <div className="wrapper-account">
                        <div className="container-account">
                            <div className="container-account__header">
                                <h1>Add address:: {this.props.stores.storeUser.user.user_id}</h1>
                            </div>

                            <div className="container-account__content">
                                <form>
                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Type (home or work etc)
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text"
                                                name="alias"
                                                value={this.state.alias} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Street
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text"
                                                name="streetname"
                                                value={this.state.streetname} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                House number
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text"
                                                name="housenr"
                                                value={this.state.housenr} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Zip code
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                        <input 
                                                type="text"
                                                name="zipcode"
                                                value={this.state.zipcode} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                City
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                        <input 
                                                type="text"
                                                name="city"
                                                value={this.state.city} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="container-account__footer">
                                <div className="button-row">
                                    <span className="button button--account button--secundary --dev">
                                        <Link to='/overview-account'>Cancel</Link>
                                    </span>
                                    <span className="button button--account button--primary --dev" onClick={(e)=>this.handleSubmit(e)}>Submit changes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
)
                    
export default Addaddress;

