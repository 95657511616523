export async function createCustomer(customer, auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocRegister.php';

    const postData = {
        firstname: customer['firstname'],
        lastname: customer['lastname'],
        email: customer['email'],
        telephone: customer['telephone'],
        customer_group_id: customer['customer_group_id'],
        password: customer['password'],
        password_confirmation: customer['password_confirmation'],
        agree: customer['agre']
    };

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if ('error' in responseJson) {
                apiReturn =  responseJson;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function loginCustomer(email, pw, auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocLogin.php';

    function objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    const queryString = objToQueryString({
        email: email,
        pw: pw
    });

    try {
        let apiStr = urlEndpoint + '?' + queryString;

        let response = await fetch(apiStr, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiCustomer;
            if ('error' in responseJson) {
                apiCustomer =  responseJson.error;
            } else {
                apiCustomer =  responseJson.success;
            }
            return apiCustomer;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function logoutCustomer(auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocLogout.php';

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiLogout;
            if ('error' in responseJson) {
                apiLogout =  responseJson;
            } else {
                apiLogout =  responseJson;
            }
            return apiLogout;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}


