import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as orders from '../communicator/shop/orders';

class StoreOrders {
  
    constructor(storeRoot) {
        this.storeRoot = storeRoot;
    }

    async submitOrder(cartId) {
        let result;
        try {
            const returnData = await orders.submitOrder(cartId);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    result = returnData.data;
                } 
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }  
    
    async getOrderStatus(orderId) {
        let orderStatus;
        //const orderId = this.getOrderId();

        try {
            const returnData = await orders.getOrderStatus(orderId);
            runInAction(() => {
                const result = returnData.data;
                orderStatus = result[0].orderStatus;

                return orderStatus;
            })           
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderStatus;
    }

    async getOrderId(guid) {
        let orderId;

        try {
            const returnData = await orders.getOrderId(guid);
            runInAction(() => {
                const result = returnData.data;
                orderId = result[0].orderId;

                return orderId;
            })           
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderId;
    }

    async sendOrderConfirmation(orderId, orderStatus) {
        try {
            const returnData = await orders.sendOrderConfirmation(orderId, orderStatus);
            runInAction(() => {
                const result = returnData.data;

                return result;
            })           
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }
    
}

export default StoreOrders;