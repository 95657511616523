import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import BlockVideo from '../videos/block-video';

const BlockReview = inject("stores") (
    observer (
        class BlockReview extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi; 
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                    review: {}
                };
            }

            componentDidMount = async() => {
                let idx = -1;
                let review;

                if (this.storeContent.listReviews.length === 0) {
                    const reviews = await this.storeContent.getReviews();
                }

                idx = this.storeContent.listReviews.findIndex((item) => item.ean === this.props.ean);

                if (idx > -1) {
                    review = this.storeContent.listReviews[idx];
                }

                this.setState({
                    idx: idx,
                    review: review
                })
            }

            componentDidUpdate(prevProps) {
                let idx = -1;
                let review;

                if (prevProps.ean !== this.props.ean) {
                    idx = this.storeContent.listReviews.findIndex((item) => item.ean === this.props.ean);

                    if (idx > -1) {
                        review = this.storeContent.listReviews[idx];
                    }

                    this.setState({
                        idx: idx,
                        review: review
                    })
                }
            }

            handleVideoPlaying = () => {
                this.setState({
                    video_playing: true
                })
            }

            render() {
                return (
                    this.state.review && this.state.review.fileName &&
                    <React.Fragment>
                        {this.storeUi.filtersShop.view === 'blocks' &&
                            <li>
                                <div 
                                    className="block--review"
                                >
                                    <BlockVideo
                                        video = {this.urlAPI + "public/videos/reviews/" + this.state.review.fileName}
                                        active_video_file = {this.urlAPI + "public/videos/reviews/" + this.state.review.fileName}
                                        active_video = {this.storeUi.active_video}
                                        video_playing = {this.state.video_playing}
                                        handleVideoPlaying = {this.handleVideoPlaying}
                                    />    
                                    <div   
                                        className="block--review__type"
                                    >
                                        <span>Review</span>
                                    </div>
                                    <div   
                                        className="block--review__overlay"
                                    >
                                        
                                        <h2>{this.state.review.quote}</h2>
                                        <span>{this.state.review.dogName}, {this.state.review.dogAge} jaar</span>
                                    </div>   
                                </div>
                            </li>
                        }
                        {this.storeUi.filtersShop.view === 'summaries' &&
                        <li>
                            <div 
                                className="block--review --summaries"
                            >
                                <div className="product-summary__columns">
                                    <div className="product-summary__column column--reviews">
                                        <BlockVideo
                                            video = {this.urlAPI + "public/videos/reviews/" + this.state.review.fileName}
                                            active_video_file = {this.urlAPI + "public/videos/reviews/" + this.state.review.fileName}
                                            active_video = {this.storeUi.active_video}
                                            video_playing = {this.state.video_playing}
                                            handleVideoPlaying = {this.handleVideoPlaying}
                                        />   
                                        <div   
                                            className="block--review__type"
                                        >
                                            <span>Review</span>
                                        </div>
                                        <div   
                                            className="block--review__overlay"
                                        >
                                            
                                            <h2>{this.state.review.quote}</h2>
                                            <span>{this.state.review.dogName}, {this.state.review.dogAge} jaar</span>
                                        </div> 
                                    </div>
                                </div>  
                            </div>
                        </li>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default BlockReview;
