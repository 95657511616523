import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import ListShopProducts from './list-shop-products';

const ListAffiliates = inject("stores") (
    observer (
        class ListAffiliates extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.state = {
            categories: [
                'organimal'
            ]
        }
    }

    componentDidMount = () => {
        console.log('SbC cats in list nav::', this.props.cats)
    }

    setActiveCatSlide(idx) {
        //SbC:: 0 is extra shop categories overview
        this.props.stores.storeUi.setActiveCatSlide(idx + 1);
    }

    render() {
        return (
            <div className="list--categories">
                <div className="container-navigation-cats">
                    <ListShopProducts 
                    
                    />
                </div>
            </div>
        );
  }
}

    )
)

export default ListAffiliates;
