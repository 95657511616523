import React, { createRef, Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import * as ui from '../../utilities/ui'; 

import TemplateShop from '../../templates/template-shop.js';
import ProductSummary from '../../components/products/product-summary';
import TeaserProductRange from '../../components/teasers/teaser-product-range.js';
import BlockReview from '../../components/blocks/reviews/block-review.js';
import FilterViews from '../../components/blocks/filters/filter-views.js';

const Products = inject("stores") (
    observer (
        class Products extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.storeUi = this.props.stores.storeUi;
        this.storeContent = this.props.stores.storeContent;
        this.storeProducts = this.props.stores.storeProducts;

        this.widthGridChange = 768;
        this.elementRef = createRef();

        this.state = {

        }
    }

    async componentDidMount() {
        if (this.storeUi.stickyFilters == false) {
            window.scrollTo(0, 0);
        }

        const products = await this.storeProducts.getProducts(this.storeUi.filtersShop.category, 'all');
        const reviews = await this.storeContent.getReviews();
    }

    componentDidUpdate = () => {
        const px = ui.convertRemToPixels(this.storeUi.settings_list['filterScroll']);
        const position = window.pageYOffset;

        if (position > 0) {
            //ui.smoothScrollTo(0, px, 250);
        }
    }

    render() {       
        return (
            <TemplateShop
                segment = "shop"
                id = "dogs"
                story = "jack"
            >
                <FilterViews

                />
                
                <div 
                    className={"wrapper-products wrapper-products--" + this.storeUi.filtersShop.view}
                    ref={(c) => { this.elementRef = c }}
                >
                    {this.storeProducts.listProducts && this.storeProducts.listProducts.length > 0
                    ?   <ul>
                            {this.storeProducts.listProducts.map((product, i) => (
                                <React.Fragment>
                                    <li>
                                        <ProductSummary
                                            ean = {product.ean}
                                            product = {product}
                                        />
                                    </li>
                                    {/* {this.storeUi.filtersShop.view !== 'summaries' &&  */}
                                        <BlockReview
                                            ean = {product.ean}
                                            reviews = {this.storeContent.listReviews}
                                        />        
                                    {/* }                        */}
                                </React.Fragment>
                            ))}
                            <li
                                className = "product--placeholder"
                            >
                               <TeaserProductRange
                               
                               />
                            </li>
                        </ul>
                    :   <div>
                            Er zijn geen producten bij deze combinatie gevonden.
                        </div>
                    }
                </div>    
            </TemplateShop>                       
        );
  }
}

    )
)

export default Products;
