import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import ProductSummaryHeader from './product-summary-header';
import ProductSummaryPreviews from './product-summary-previews';
import ProductSummaryStats from './product-summary-stats';
import ProductSummaryActions from './product-summary-actions';

const ProductSummary = inject("stores") (
    observer (
        class ProductSummary extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeShop = this.props.stores.storeShop;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    product: {},
                    price: {}
                }; 
            }

            componentDidMount = async() => {
                const sku = this.props.product.ean;
                const productData = await this.storeProducts.getProductData(sku);
                const productPrice = await this.storeProducts.getProductPrice(sku);

                let idx = -1;

                idx = this.storeContent.listReviews.findIndex((item) => item.ean === sku);

                this.setState({
                    idx: idx,
                    product: productData,
                    price: productPrice
                })

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.ean !== this.props.ean) {
                    const sku  = this.props.ean;
                    const productData = await this.storeProducts.getProductData(sku);
                    const productPrice = await this.storeProducts.getProductPrice(sku);

                    let idx = -1;
                    let review;
                    idx = this.storeContent.listReviews.findIndex((item) => item.ean === this.props.ean);

                    if (idx > -1) {
                        review = this.storeContent.listReviews[idx];
                    }

                    this.setState({
                        idx: idx,
                        product: productData,
                        price: productPrice
                    })
                }
            }

            handleClick = (url) => {
                this.storeUi.openDialogContent();
            }

            handleAddProduct = async() => {
                let cartId = this.storeAuth.cartId;
                if (cartId === 0) {
                    cartId = await this.storeCarts.createNewCart();
                }
                const productAdded = await this.storeCarts.addToCart('guest', cartId, this.props.product, this.state.price, 1)

                this.storeCarts.getCart(cartId);
                this.storeCarts.getCartAmount(cartId);
            }

            render() {
                return (
                    <React.Fragment>     
                        <div 
                            className={"wrapper--product-summary --" + this.storeUi.filtersShop.view}
                        >
                            <div className="product-summary__columns">
                                <ProductSummaryHeader
                                    ean = {this.props.product.ean}
                                    product = {this.props.product}
                                />

                                <div className="product-summary__column column--previews">
                                    <ProductSummaryPreviews
                                        ean = {this.props.product.ean}
                                        product = {this.props.product}
                                    />
                                </div>

                                <div className="product-summary__column column--stats">
                                    <ProductSummaryStats
                                        ean = {this.props.product.ean}
                                        product = {this.props.product}
                                    />
                                </div>

                                <ProductSummaryActions
                                    ean = {this.props.product.ean}
                                    product = {this.props.product}
                                />
                            </div>

                            <div className="product-summary__columns product-summary__columns--description">
                                <div className="product-summary__column column--description">
                                    <span
                                        className="column-description__text"
                                        dangerouslySetInnerHTML={{ __html: this.props.product.prodDescr}}
                                    />
                                </div>
                            </div>

                            <div className="product-summary__columns product-summary__columns--keydata">
                                <div className="product-summary__column column--keydata">
                                    <span><strong>Productnummer</strong></span>
                                    <span
                                        className = "ean"
                                    >
                                        {this.state.product.ean}
                                    </span>
                                </div>
                                <div 
                                    className={"product-summary__column column--analysis --" + this.props.product.typeFood}
                                >
                                    <div
                                        className = "column-keydata__analysis"
                                    >
                                        <span><strong>Analyse</strong></span>
                                        <span>ruw eiwit {this.state.product.anProtein}%</span>
                                        <span>ruw vet {this.state.product.anFat}%</span>
                                        <span>ruw as {this.state.product.anAshes}%</span>
                                        <span>ruw celstof {this.state.product.anCell}%</span>
                                        <span>vocht {this.state.product.anMoisture}%</span>
                                    </div>
                                </div>
                                {this.state.product.compound !== '' &&
                                    <div className="product-summary__column column--compound">
                                        <span><strong>Samenstelling</strong></span>
                                        <span
                                            className = "ean"
                                        >
                                            {this.state.product.compound}
                                        </span>
                                    </div>
                                }
                            </div>                   
                        </div>
                    </React.Fragment>
                ); 
            }
        }
    )
)

export default ProductSummary;
