import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const ProductSummaryActions = inject("stores") (
    observer (
        class ProductSummaryActions extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeShop = this.props.stores.storeShop;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    product: {},
                    price: {}
                }; 
            }

            componentDidMount = async() => {
                const sku = this.props.product.ean;
                const productData = await this.storeProducts.getProductData(sku);
                const productPrice = await this.storeProducts.getProductPrice(sku);

                this.setState({
                    product: productData,
                    price: productPrice
                })

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.ean !== this.props.ean) {
                    const sku  = this.props.ean;
                    const productData = await this.storeProducts.getProductData(sku);
                    const productPrice = await this.storeProducts.getProductPrice(sku);

                    this.setState({
                        product: productData,
                        price: productPrice
                    })
                }
            }

            handleClick = (url) => {
                this.storeUi.openDialogContent();
            }

            handleAddProduct = async() => {
                let cartId = this.storeAuth.cartId;
                if (cartId === 0) {
                    cartId = await this.storeCarts.createNewCart();
                }
                const productAdded = await this.storeCarts.addToCart('guest', cartId, this.props.product, this.state.price, 1)

                this.storeCarts.getCart(cartId);
                this.storeCarts.getCartAmount(cartId);
            }

            render() {
                return (
                    <React.Fragment>     
                        <div className="product-summary__column column--actions">
                            <div
                                className="column--actions__price"
                            >
                                {this.state.price && this.state.price.priceShop}
                            </div>

                            <div className="form-row form-row--buttons">
                                <div className="form-row--button button--add --active">
                                    <div 
                                        className="form-row__button button--primary"
                                        onClick = {() => this.handleAddProduct()}
                                    >
                                        <span className="button--add__plus" >
                                            &#x2b;
                                        </span>
                                    </div>
                                
                                    <div 
                                        className="form-row__button-text --link"
                                        onClick = {() => this.handleAddProduct()}
                                    >
                                        Voeg toe aan winkelwagen
                                    </div>
                                </div>
                            </div>

                            <div
                                className="column--actions__delivery"
                            >
                                Levertijd 1-3 werkdagen
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
        }

    )
)

export default ProductSummaryActions;
