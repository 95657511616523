import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";

const TemplateDialog = inject("stores") (
    observer (
        class TemplateDialog extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.storeUi.closeDialogContent()
                }
            }

            onClose = () => {
                this.storeUi.closeDialogContent()
            }
   
            render() {
                return (
                    <div className="overlay">
                        <div className="dialog wrapper-dialog">
                            <div 
                                className = "dialog__close --link"
                                onClick = {() => this.onClose()}
                            />
                            {this.props.children}  
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(TemplateDialog));
