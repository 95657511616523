import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

//import ListNavigation from '../navigation/list-navigation';

const NavigationMain = inject("stores") (
    observer (
        class NavigationMain extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeCarts = this.props.stores.storeCarts;
            }

            handleLogOut() {
                //this.props.stores.storeUi.setLoginState('logged_out');
            }

            onNext = (section) => {
                //this.storeUi.setNavMain(section);
                let url = "/" + section;
                this.props.history.push(url);
            }

            render() {
                return (
                    <div className="navigation--main">
                        <div className="navigation--main__bar" />
                        <ul className="navigation--main__links">
                            <li className="navigation--main__link navigation--main__link--home">
                                <span 
                                    className="--link" 
                                    onClick={(e) => this.onNext('home')}
                                >
                                        {i18n.t("topbar.home")}
                                </span>
                            </li>
                            <li className="navigation--main__link navigation--main__link--home">
                                <span 
                                    className="--link" 
                                    onClick={(e) => this.onNext('shoppingcart')}
                                >
                                        {i18n.t("topbar.shopping_cart")}
                                </span>
                                <span 
                                    className="navigation--main__cart-counter"
                                >
                                        {this.storeCarts.cartAmount}
                                </span>
                            </li>
                        </ul>
                    </div>
                );
            }
        }
    )
)

export default withTranslation()(withRouter(NavigationMain));
