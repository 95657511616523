export async function getCustomerDetails(auth) {
    const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlEndpoint = urlAPI + '/ocAccountDetails.php';

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if ('error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson.data;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function changeCustomerDetails(data, auth) {
    const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlEndpoint = urlAPI + '/ocEditAccount.php';
    
    let postData = {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        telephone: data.telephone
    };
    
    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if ('error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function getAddresses(auth) {
    const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlEndpoint = urlAPI + '/ocAccountAddresses.php';

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if ('error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function addAddress(data, auth) {
    const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlEndpoint = urlAPI + '/ocAddAddress.php';

    let postData = {
        address_data: data
    }

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if ('error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function editAddress(id, data, auth) {
    const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlEndpoint = urlAPI + '/ocEditAddress.php';

    let postData = {
        address_id: id,
        address_data: data
    }

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if ('error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson.data;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function deleteAddress(id, auth) {
    const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlEndpoint = urlAPI + '/ocDeleteAddress.php';

    let postData = {
        address_id: id
    }

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if ('error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson.data;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}


