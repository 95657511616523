import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const ProductSummaryHeader = inject("stores") (
    observer (
        class ProductSummaryHeader extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeShop = this.props.stores.storeShop;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    product: {},
                    price: {}
                }; 
            }

            componentDidMount = async() => {
                const sku = this.props.product.ean;
                const productData = await this.storeProducts.getProductData(sku);
                const productPrice = await this.storeProducts.getProductPrice(sku);

                this.setState({
                    product: productData,
                    price: productPrice
                })

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.ean !== this.props.ean) {
                    const sku  = this.props.ean;
                    const productData = await this.storeProducts.getProductData(sku);
                    const productPrice = await this.storeProducts.getProductPrice(sku);

                    this.setState({
                        product: productData,
                        price: productPrice
                    })
                }
            }

            handleClick = (url) => {
                this.storeUi.openDialogContent();
            }

            handleAddProduct = async() => {
                let cartId = this.storeAuth.cartId;
                if (cartId === 0) {
                    cartId = await this.storeCarts.createNewCart();
                }
                const productAdded = await this.storeCarts.addToCart('guest', cartId, this.props.product, this.state.price, 1)

                this.storeCarts.getCart(cartId);
                this.storeCarts.getCartAmount(cartId);
            }

            render() {
                return (
                    <React.Fragment>     
                        <div className="product-summary__column column--header">
                            {/* <div 
                                className="product-summary__productimage"
                                style= {{backgroundImage:`url(${this.urlAPI + "public/images/products/dogfood/" + this.state.product.ean + ".png"})` }}
                            /> */}
                    
                            <div>
                                <h3>
                                    {this.state.product && this.state.product.title1}
                                </h3>
                                <h3>
                                    {this.state.product && this.state.product.title2}
                                </h3>
                                <span
                                    className = "divider"
                                />
                                <h4>
                                    {this.state.product.subtype !== '' && this.state.product.subtype !== null
                                        ?   <span>{this.state.product.subtype}</span>
                                        :   <span>{this.state.product.type}</span>
                                    }
                                </h4>
                                
                            </div>
                        </div>    
                    </React.Fragment>
                );
            }
        }

    )
)

export default ProductSummaryHeader;
