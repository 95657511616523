import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FormPet from './form-pet';

const FormPets = inject("stores") (
    observer (
        class FormPets extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    pets: []
                }
            }

            componentDidMount = async() => {
                let userId;
                
                // const items = await this.storeOrders.getOrderItems(this.props.order.orderId);
                // items.map((item) => {
                //     this.addPetFromDb(item);
                // })

                const cartId = this.storeAuth.getCartId();

                if (cartId !== 0) {
                    const pets = await this.storeCarts.getCartPets(cartId);
                    console.log('SbC id for pet', pets)
                    if (pets) {
                        this.setState({
                            pets: pets
                        })
                    }
                }
            } 

            componentDidUpdate = async(prevProps) => {
            }

            addPetFromDb = (line) => {
                const updatedControls = {
                    ...this.state.formControls
                };

                let updatedFormElement = {
                    ...updatedControls['pets']
                };
                
                let pet = [{
                    pet: {
                        value: line.id,
                        placeholder: i18n.t("forms.placeholder.sku"),
                        validationRules: {
                            isRequired: true
                        },
                    },
                    petName: {
                        value: line.category,
                        placeholder: i18n.t("forms.placeholder.product"),
                        validationRules: {
                            isRequired: true
                        }
                    }
                }];

                let arr = [];
                  
                Object.keys(updatedFormElement).map((key, i) => {
                    arr.push(updatedFormElement[key]);
                })
                updatedFormElement = arr.concat(pet);
                updatedControls['pets'] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }

            addPet = async(e) => {            
                let pet = {
                    id: 0,
                    animalType: "",
                    name: ""
                }

                let pets = this.state.pets;    
                pets.push(pet);
                
                this.setState({
                    pets: pets
                })
            }

            getCartPets = async() => {
                const cartId = this.storeAuth.getCartId();
                
                if (cartId !== 0) {
                    const pets = await this.storeCarts.getCartPets(cartId);
                    console.log('SbC pets', pets)
                    this.setState({
                        pets: pets
                    })
                }
            }

            render() {
                return (
                    <React.Fragment>
                        {this.state.pets && this.state.pets.length >= 0 &&
                            <div className="section--form">
                                <div className="section--form__columns">
                                    <div className="section--form__column">
                                        <label className={"form-label"}>
                                            {i18n.t("forms.label.petName")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.pets && this.state.pets.length > 0 &&
                            <div className="section--form">
                                <div className="section--form__columns">
                                    <div className="section--form__column">
                                        {this.state.pets && this.state.pets.map((pet, i) => (
                                            <FormPet
                                                pet = {pet}
                                                getCartPets = {this.getCartPets}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="segment--content__columns">
                            <div className="segment--content__column">
                                <span
                                    className = "--link"
                                    onClick = {() => this.addPet()}
                                >
                                    + {i18n.t("button.add_dog")}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(FormPets);
