export async function addToCart(product_id, auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocAddToCart.php';

    let postData = {
        product_id: product_id
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn =  responseJson;

            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function getCart(auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocCartOverview.php';

    try {
        let response = await fetch(urlEndpoint, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn =  responseJson;

            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}