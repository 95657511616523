import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import classNames from 'classnames';

const LanguageSelector = inject("stores") (
    observer (
        class LanguageSelector extends Component {
            constructor(props) {
                super(props);
                this.props = props;
            }

            componentDidMount() {
                let lng = this.props.stores.storeUi.app_interface.lang;
                if (lng === '' && localStorage['i18nextLng']) {
                    lng = localStorage.getItem('i18nextLng');
                    this.props.stores.storeUi.setLocale(lng); 
                }
            }

            getActiveLang(lang) {
                const lng = this.props.stores.storeUi.app_interface.lang;
                let classNames = "lang-selector__button";
                if (lang === lng) { 
                    classNames = classNames + ' --active';
                }
                return classNames;
            }
     
            setLocale(lang) {
                this.props.stores.storeUi.setLocale(lang);
            }
        
            render() {
                i18n.changeLanguage(this.props.stores.storeUi.app_interface.lang);

                const lang = this.props.stores.storeUi.app_interface.lang;
        
                return (
                    <div className="lang-selector">
                        <span className={this.getActiveLang('nl')} onClick={() => this.setLocale('nl')}>nl</span>
                        <span className={this.getActiveLang('en')} onClick={() => this.setLocale('en')}>en</span>
                        <span className={this.getActiveLang('de')} onClick={() => this.setLocale('de')}>de</span>
                    </div>
                )
            }
        }
    )
);

export default LanguageSelector;