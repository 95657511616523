import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Video from '../../pages/video';

const BlockVideo = inject("stores") (
    observer (
        class BlockVideo extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    video: '',
                    video_playing: false
                }
            }

            componentDidMount = () => {
                const video = this.urlAPI + "public/videos/shop/" + this.props.filename;

                this.setState({
                    orientation: this.props.orientation,
                    video: video
                })
            }

            componentDidUpdate(prevProps) {
                if (prevProps.video_playing !== this.props.video_playing) {
                    this.setState({
                        video_playing: this.props.video_playing
                    })
                }

                if (prevProps.video !== this.props.video) {
                    this.setState({
                        orientation: this.props.orientation,
                        video: this.props.video
                    })
                }
            }

            componentWillUnmount = () => {
                this.setState({
                    video_playing: false
                })
            }

            handleCC = () => {

            }

            play() {
                this.player.play();
            }
            
            pause() {
                this.player.pause();
            }
            
            load() {
                this.player.load();
            }
            
            changeCurrentTime(seconds) {
                return () => {
                  const { player } = this.player.getState();
                  this.player.seek(player.currentTime + seconds);
                };
            }
            
            handleVideoState = (state) => {
                //console.log('SbC video state:', state)
                  this.setState({
                    player: state,
                    video_playing: state.hasStarted ? true : false
                })
            }

            handleVideoPlaying = (playing) => {
                //console.log('SbC video playing:', playing)
                this.setState({
                    active_video_playing: playing,
                    video_playing: playing
                })
            }

            render() {
                return (
                    <div className={"block--video" + " --" + this.state.orientation}>
                        <div className="block--video__player">
                            <Video
                                video = {this.state.video}
                                active_video = {this.storeUi.active_video}
                                active_video_location = {this.state.active_video_location}
                                active_video_playing = {this.state.active_video_playing}
                                current_state =  {this.state.player}
                                handleVideoState = {this.handleVideoState}
                                handleVideoPlaying = {this.handleVideoPlaying}
                                video_playing = {this.state.video_playing}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockVideo);
