import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import queryString from 'query-string';
import i18n from "i18next";

import TemplateShop from '../../templates/template-shop.js';

import ListCategories from './list-categories.js';
import Category from './category';
import SubCategory from './subcategory.js';

import * as shop from '../../communicator/shop/shop';
import ListAffiliates from '../../components/sections/list-affiliates';
import ListShopProducts from '../../components/sections/list-shop-products';
import ListBrandProducts from '../../components/sections/list-brand-products';
import BlockSnackpoints from '../../components/blocks/shop/block-snackpoints';

const ShopProducts = inject("stores") (
    observer (
        class ShopProducts extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeAffiliates = this.props.stores.storeAffiliates;

                this.APP_HOME = 15;
                this.CAT_HOME = 2;
                this.SHOP_CATS = props.SHOP_CATS;
                this.CAT_SLIDES = props.CAT_SLIDES;

                this.state = {
                    activeCat: 'HOME',
                    activeSlide: 0,
                    CAT_SLIDES: [],
                    CAT_PRODUCTS: [{}]
                }
            }

            async componentDidMount() {
                let apiCats;
                let affId = 0;

                // if (this.props.stores.storeUi.app_auth.access_token === '') {
                //     await this.props.stores.storeUi.getAccessToken();
                //     apiCats = await shop.getShopCategories(this.props.stores.storeUi.app_auth.access_token);
                //     console.log('SbC cat api::', apiCats)
                // } else {
                //     apiCats = await shop.getShopCategories(this.props.stores.storeUi.app_auth.access_token);
                // }

                // this.setState(state => {
                //     const CAT_SLIDES = state.CAT_SLIDES.concat(apiCats.data);
                //     return { 
                //         CAT_SLIDES 
                //     };
                // });

                const apiProducts = await shop.getShopProductsPerCategory();
                let shopProducts = this.state.CAT_PRODUCTS;
                shopProducts.push.apply(shopProducts, apiProducts);

                const section = this.props.match.params.section;

                const affiliates = await this.storeAffiliates.getShopAffiliates();
                switch(section) {
                    case "sleeping":
                        affId = 4;
                        break;
                    case "snacking":
                        affId = 1;
                        break;
                }

                this.setState({
                    affiliates: affiliates,
                    section: section,
                    CAT_PRODUCTS: shopProducts
                })

                this.props.stores.storeUi.resetCartSection();
                this.checkAccessToken();

                //SbC should be further in front of process 
                this.setLanguage();

                this.handleScroll();
            }

            componentDidUpdate = (prevProps) => {
                console.log('SbC props 1::', this.props)

                if (prevProps.match.params.section !== this.props.match.params.section) {
                    const section = this.props.match.params.section;
                
                    this.setState({
                        setcion: section,
                    })
                }
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            checkAccessToken() {
                if (this.props.stores.storeUi.app_auth.access_token === '') {
                    this.props.stores.storeUi.getAccessToken();
                }
            }

            setLanguage() {
                let lng = this.props.stores.storeUi.app_interface.lang;
                if (lng === '' && localStorage['i18nextLng']) {
                    lng = localStorage.getItem('i18nextLng');
                    this.props.stores.storeUi.setLocale(lng); 
                }
            }

            setActiveSlide = (idx) => {

            }

            setOptions = (swiper) => {
                this.swiper = swiper;
            }

            moveToSlide(idx) {
                if (this.swiper) {
                    this.swiper.slideTo(idx);
                }
            }

            moveToNext() {
                this.swiper.slideNext();
            }

            moveToPrev() {
                this.swiper.slidePrev();
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            getSubCatName(catId) {
                function findArrayElementByTitle(array, title) {
                    return array.find((element) => {
                    return element.id === title;
                    })
                }
                let subCat = findArrayElementByTitle( this.SHOP_CATS, catId)
                return subCat.name;
            }

            getSubCatLink(catName) {
                catName = catName.toLowerCase();
                return catName;
            }

            showNavCategories() {
                return <div>
                        <ListCategories 
                            categories = {this.state.CAT_SLIDES}
                            onNext = {this.props.onNext} 
                            segment = 'SHOP'
                        />
                    </div>
            }

            handleClick = (url, token) => {
                let affUrl;
                if (token !== null && token !== '') {
                    affUrl = url + "/" + token;
                } else {
                    affUrl = url;
                }
                window.open(affUrl);
            }

            render() {
                let activeSlide = this.props.stores.storeUi.app_interface.active_cat_slide;
                this.moveToSlide(activeSlide);

                return (
                    <TemplateShop
                        segment = "shop"
                        id = "dogs"
                        story = "jack"
                    >
                    
                        <div className="page-project">
                             <div className="segment-wrapper segment-wrapper--intro">
                                <div className="segment-wrapper__column segment-wrapper__column--intro">
                                    <h2>
                                        <span className="segment-wrapper__intro-header">
                                            Producten
                                        </span>
                                    </h2>
                                    <span className="segment-wrapper__intro-subheader">
                                        <span>
                                            {i18n.t("section."+ this.props.match.params.section)}
                                        </span>
                                    </span>
                                    <p>
                                        <span>
                                            Lekkere en gezonde snacks. Graan en glutenvrij. Daar word ik blij van hoor!.
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div className="page-content">
                                <div 
                                    className="segment-wrapper"
                                >
                                    <ListBrandProducts 
                                        section = {this.props.match.params.section}
                                    />
                                </div>

                                <div className="product__action-buttons action-button--cta">
                                    <button 
                                        className="btn btn--primary --link"
                                        onClick={() => this.gotoPage('/shoppingcart')}
                                    >
                                        Ga naar je winkelwagen
                                    </button>
                                </div>

                                <div>
                                    <BlockSnackpoints

                                    />
                                </div>
                            </div>
                        </div>                        
                    </TemplateShop>
                );
            }
        }
    )
)

ShopProducts.propTypes = {
    CAT_SLIDES: PropTypes.array,
    SHOP_CATS: PropTypes.array
};
  
ShopProducts.defaultProps = {
    CAT_SLIDES: [{}],
    SHOP_CATS: [{}]
};

export default ShopProducts;