import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as carts from '../communicator/shop/carts';

class StoreVAT {

    vat = 0;
    vatShipping = 0;
    
    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeCarts = this.storeRoot.storeCarts;
        this.storeShipping = this.storeRoot.storeShipping;
    }

    calcVAT = async() => {
        let total = 0;
        let totalLow = 0;
        let totalHigh = 0;
        let totalItem = 0;
        let percVat = 21;
        let vat = 0;

        const cartId = this.storeAuth.getCartId();
        const cart = await this.storeCarts.getCart(cartId);

        //SbC vat cart items
        cart.map(item => {
            totalItem = item.amount * item.price * (100 - item.discount)/100;
            total = total + totalItem / (1 + item.vat/100)
            if (parseInt(item.vat) === 9) {
                totalLow = totalLow + totalItem / (1 + item.vat/100);
            }
            if (parseInt(item.vat) === 21) {
                totalHigh = totalHigh + totalItem / (1 + item.vat/100);
            }
            vat = vat + (totalItem - totalItem / (1 + item.vat/100));
        });

        //SbC vat shipping
        //const vatShipping = this.storeShipping.shipping - this.storeShipping.shipping / (1 + percVat/100);
        const vatShippingLow = totalLow/total * this.storeShipping.shipping * (9/100);
        const vatShippingHigh = totalHigh/total * this.storeShipping.shipping * (21/100);
        this.vatShipping = vatShippingLow + vatShippingHigh;

        vat = vat + vatShippingLow + vatShippingHigh;
        this.vat = vat;
        
        this.saveVAT(cartId, vat);
        return vat;
    }

    saveVAT = async(cartId, vat) => {
        try {
            const returnData = await carts.saveVAT(cartId, vat);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {

                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
}

export default StoreVAT;