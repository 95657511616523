import React, { Component } from 'react';

class FooterInfo extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;
        
        this.state = {

        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="footer-row footer-row--column">
                <img 
                        src={this.urlAPI + "/public/images/branding/logo-black-bg-white.png"} 
                        className = "page-footer__branding"
                    />

                <span className="footer__profile-text">
                    IkShasha wil je graag helpen je hond blij en gezond te houden. Onze producten zijn daarbij van grote waarde.
                    <br/><br/>
                    Ook willen we graag het leven van honden die het wat minder hebben daardat ze in het asiel verblijven of op straat moeten leven, ietwat fijner maken.<br/>Later kun je op deze site meer informatie vinden over deze projecten en de honden die we daarmee helpen.
                </span>
            </div> 
        );
    }
}

export default FooterInfo;
