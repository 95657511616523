import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import Category from './category';

const ListCategories = inject("stores") (
    observer (
        class ListCategories extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.state = {
            categories: [
            ]
        }
    }

    componentDidMount = () => {
        console.log('SbC cats in list nav::', this.props.cats)
    }

    setActiveCatSlide(idx) {
        //SbC:: 0 is extra shop categories overview
        this.props.stores.storeUi.setActiveCatSlide(idx + 1);
    }

    render() {
        return (
            <div className="list--categories">
                <h2>Categories</h2>
                <div className="container-navigation-cats">
                    <ul className="navigation-cats navigation-cats--shoppage">
                        {this.props.categories && this.props.categories.length > 0
                        ?   this.props.categories.map((category, idx) => (
                                <li className="navigation-cats__cat" onClick={() => this.setActiveCatSlide(idx)}>
                                    <div className="navigation-cats__column">
                                        <Category
                                            category = {category}
                                        />   
                                    </div>
                                    <div className="navigation-cats__column">
                                        Description of category / image
                                    </div>
                                </li>                  
                            ))
                        :   <li>
                                <span>No categories</span>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        );
  }
}

    )
)

export default ListCategories;
