import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

import icEat from '../../assets/images/icons/ic-eat-inverted.svg';
import icPlay from '../../assets/images/icons/ic-play-inverted.svg';
import icSleep from '../../assets/images/icons/ic-sleep-inverted.svg';
import icGroom from '../../assets/images/icons/ic-groom-inverted.svg';

class CartFooter extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            SHOP_CATS: []
        };
    }

    async componentDidMount() {
        const apiCats = await utils.getShopCategories();
        let shopCats = this.state.SHOP_CATS;
        
        shopCats.push.apply(shopCats, apiCats);
        this.setState({
            SHOP_CATS: shopCats
        })
    }

    render() {
        const cat_icons = {
            'eat': icEat,
            'groom': icGroom,
            'play': icPlay,
            'sleep': icSleep
        }

        return (
            <div className="page-footer__content">
                <div className="footer-columns">
                    <div className="footer-column">
                        <h4>About the shopping process</h4>

                    </div>

                    <div className="footer-column">
                        <h4>About</h4>
                        <ul>
                            <li>Cereza</li>
                            <li>Shipping costs</li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="footer-column">
                        <h4>About</h4>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="footer-column">
                        <h4>Contact</h4>
                        <ul>
                            <li>
                                <div className="footer__address">
                                    Slotlaan 316
                                    Zeist
                                </div>
                            </li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>

                <div className="footer-columns">
                    <div className="footer-column">
                        <div className="page-footer__social-media">
                            <ul>
                                <li>Facebook</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-columns">
                    <div className="footer-column">
                        <div className="page-footer__colofon">
                            <ul>
                                <li>&copy;2019 - Cereza Design - Zeist</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CartFooter;
