import React, { Component } from 'react';

class FooterAddress extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;
    }

    componentDidMount() {
    }

    gotoUrl = (page) => {
        let url;

        url = "https://" + page;
        window.open(url, "_blank");
    }

    render() {
        return (
            <div className="footer-row footer-row--column">
                <span className="footer__address">
                    
                    <span className="address__founded">Created by Cereza Design</span>
                    <span className="address__street">Slotlaan 316</span>
                    <span className="address__city">3701 GX  Zeist</span>
                    <br/>
                    <span className="address__name">KvK: 70 664 536.</span>
                    <span className="address__name">Btw nr: NL001807905B96</span>
                </span>
            </div>
        );
    }
}

export default FooterAddress;
