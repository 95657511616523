import * as apifetch from './fetch.js';

export async function sendFormContact(form, data) {
    const urlEndpoint = 'email/contact';

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormNewsletter(form, data) {
    const urlEndpoint = 'email/newsletter';

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormLetmeknow(data) {
    const urlEndpoint = 'email/letmeknow';

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormAccount(form, data) {
    const urlEndpoint = 'accounts/new';

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}