import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

class FooterColofon extends Component {

    constructor(props) {
        super();
        this.props = props;
        
        this.state = {
        };
    }

    async componentDidMount() {
        const d = new Date();
        const year = d.getFullYear();
        this.setState({
            year: year
        })
    }

    render() {
        return (
            <div className="footer__colofon">
                <ul>
                    <li>&copy; 2020-{this.state.year}&nbsp;&nbsp;&nbsp;Cereza Design, Zeist</li>
                </ul>
            </div>
        );
    }
}

export default FooterColofon;
