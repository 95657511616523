import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import * as shopUtils from './communicator/shop/shop';
import * as appUtils from './communicator/app';

import AddAddress from './components/account/account-add-address';
import Colofon from './pages/colofon';
import CreateAccount from './components/account/account-create';
import DogCare from './pages/journal/dogcare';
import DogFriendly from './pages/journal/dogfriendly';
import EditAccount from './components/account/account-edit';
import EditAddresses from './components/account/account-addresses';
import HomeMain from './pages/home';
import Journal from './pages/journal/journal';
import Login from './components/account/account-login';
import OverviewAccount from './components/account/account-overview';
import CartPayment from './pages/shop/cart-payment';
import PaymentResult from './pages/order/payment-result';
import Pension from './pages/journal/pension';
import PrivacyStatement from './pages/general/privacy-statement';
import Product from './pages/product';
import Products from './pages/shop/products';
import Projects from './pages/projects/projects';
import ProjectShelter from './pages/projects/project-shelter';
import ProjectStreet from './pages/projects/project-street';
import ResetPassword from './components/account/account-reset-password';
import Review from './pages/shop/cart-review';
import SettingLanguage from './pages/setting-language';
import ShopCategory from './pages/shop/category';
import ShopCategories from './pages/shop/shop-categories';
import ShopProducts from './pages/shop/shop-products';
import ShopMain from './pages/shop';
import ShoppingCart from './pages/shop/shoppingcart';
import Spotlight from './pages/journal/spotlight';
import TermsConditions from './pages/general/terms-conditions';
import Training from './pages/journal/training';
import Walking from './pages/journal/walking';

import BlogInsects from './pages/blog/insects';

import RedirectPayment from './components/redirect-payment';
import PaymentError from './pages/shop/payment-error';
import PaymentReturn from './pages/shop/payment-return';

const Routes = inject("stores") (
    observer (
        class Routes extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storePayments = this.props.stores.storePayments;

                this.state = appUtils.getDefaultNav();

            }

            async componentDidMount() {

            }

            checkAccessToken() {
                if (this.props.stores.storeUi.app_auth.access_token === '') {
                    this.props.stores.storeUi.getAccessToken();
                }
            }

            showProductPage = (props) => {
                return <Product 
                    product = {props}
                />
            }

            render() {
                return (
                    <div className="">
                        <Switch>
                            <Route 
                                exact path='/' 
                                component={ShopMain}
                            />
                            <Route 
                                path='/home' 
                                component={(props) => <ShopMain {...props} />}
                            />
                            <Route 
                                path='/shop-products/:section?' 
                                component={(props) => <ShopProducts {...props} />}
                            />                           
                            <Route 
                                path='/create-account' 
                                component={CreateAccount}
                            />
                            <Route 
                                path='/dogcare' 
                                component={DogCare}/>
                            <Route 
                                path='/dogfriendly' 
                                component={DogFriendly}/>
                            <Route 
                                path='/edit-account' 
                                component={EditAccount}/>
                            <Route 
                                path='/edit-addresses' 
                                component={EditAddresses}/>
                            <Route 
                                path='/home' 
                                component={ShopMain}/>
                            <Route 
                                path='/journal' 
                                component={Journal}/>
                            <Route 
                                path='/login' 
                                component={Login}/>
                            <Route 
                                path='/overview-account' 
                                component={OverviewAccount}/>
                            <Route 
                                path='/payment' 
                                component={CartPayment}/>
                            <Route 
                                path='/payment-result' 
                                component={PaymentResult}/>
                            <Route 
                                path='/pension' 
                                component={Pension}/>
                            <Route 
                                path='/shop/products/:section?' 
                                component={Products}
                            />
                            <Route 
                                path='/product/:id' 
                                render={({match}) => this.showProductPage({match})} />
                            <Route 
                                path='/colofon' 
                                component={Colofon}/>
                            <Route 
                                path='/payment-error' 
                                component={PaymentError}
                            />
                            <Route 
                                path='/payment-return' 
                                component={PaymentReturn}
                            />
                            <Route 
                                path='/privacy-statement' 
                                component={PrivacyStatement}/>
                            <Route 
                                path='/projects' 
                                component={Projects}/>
                            <Route 
                                path='/project-street' 
                                component={ProjectStreet}/>
                            <Route 
                                path='/project-shelter' 
                                component={ProjectShelter}/>
                            <Route 
                                path='/reset-password' 
                                component={ResetPassword}/>
                            <Route 
                                path='/review' 
                                component={Review}
                            />
                            <Route 
                                path='/set-language' 
                                component={SettingLanguage}/>
                            <Route 
                                path='/shop' 
                                component={ShopMain}/>
                            <Route 
                                path='/shop-categories' 
                                component={ShopCategories}/>    
                            <Route 
                                path='/shoppingcart' 
                                component={ShoppingCart}/>
                            <Route 
                                path='/spotlight' 
                                component={Spotlight}/>
                            <Route 
                                path='/terms-conditions' 
                                component={TermsConditions}/>
                            <Route 
                                path='/training' 
                                component={Training}/>
                            <Route 
                                path='/walking' 
                                component={Walking}
                            />
                            <Route 
                                path='/blog/insects' 
                                component={BlogInsects}
                            />
                            <Route 
                                path="/payment-msp" 
                                render={(routeProps) => (<RedirectPayment {...routeProps} loc={this.storePayments.paymentUrl} />)}
                            />
                        </Switch>
                    </div>
                );
            }
        }
    )
)

export default withRouter(Routes);
