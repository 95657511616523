import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import FooterLegal from './footer-legal';
import FooterAddress from './footer-address';
import FooterColofon from './footer-colofon';

class FooterCart extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;
        
        this.state = {
        };
    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className="page-footer">
                <div className="page-footer__content">
                    <div className="page-footer__content--project">
                        <FooterAddress />
                        
                        <FooterLegal />
                        
                        <FooterColofon />
                    </div>
                    <div className="page-footer__content--project">
                        <FooterLegal />
                    </div>
                </div>
            </div>
        );
    }
}

export default FooterCart;
