import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

const EditAccount = inject("stores") (
    observer (
        class EditAccount extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    email: "",
                    firstname: "",
                    lastname: "",
                    telephone: ""
                };

                if (this.props.stores.storeUi.app_interface.user_logged_in) {
                    this.getCustomerDetais();
                }
            }

            async getCustomerDetais() {
                let apiResponse = await account.getCustomerDetails(this.props.stores.storeUi.app_auth.access_token);
                let customerDetails = apiResponse;
                this.setState({
                    customer_id: customerDetails.customer_id,
                    email: customerDetails.email,
                    firstname: customerDetails.firstname,
                    lastname:  customerDetails.lastname,
                    telephone:  customerDetails.telephone
                })
                console.log('SbC account details::', customerDetails)
            }

            handleChange = e => {
                let change = {};
                change[e.target.name] = e.target.value;
                this.setState(change);
            }

            async handleSubmit(e) {
                e.preventDefault();

                const customerData = {
                    email: this.state.email,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    telephone: '12345'
                }
     
                let apiReturn = await account.changeCustomerDetails(customerData, this.props.stores.storeUi.app_auth.access_token);

                console.log('SbC changed customer::', apiReturn)
                if ('error' in apiReturn) {
                   let customerId = 0;
                } else {
                    //SbC:: successfully changed
                    //SbC:: save to store
                }
            }

            render() {
                return (
                    <div className="wrapper-account">
                        <div className="container-account">
                            <div className="container-account__header">
                                <h1>Change account details:: {this.props.stores.storeUser.user.user_id}</h1>
                            </div>

                            <div className="container-account__content">
                                <form>
                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                First name
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text"
                                                name="firstname"
                                                value={this.state.firstname} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Last name
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text"
                                                name="lastname"
                                                value={this.state.lastname} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Email
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text"
                                                name="email"
                                                value={this.state.email} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Telephone
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text"
                                                name="telephone"
                                                value={this.state.telephone} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="container-account__footer">
                                <div className="button-row">
                                    <span className="button button--account button--secundary --dev">
                                        <Link to='/overview-account'>Cancel</Link>
                                    </span>
                                    <span className="button button--account button--primary --dev" onClick={(e)=>this.handleSubmit(e)}>Submit changes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
)
                    
export default EditAccount;

