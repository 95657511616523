import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import FriendSummary from '../blocks/friends/friend-summary';

const SectionPromoFriends = inject("stores") (
    observer (
        class SectionPromoFriends extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;

        this.storeUi = this.props.stores.storeUi;
        this.storeContent = this.props.stores.storeContent;

        this.state = {
            friends: []
        };      
    }

    componentDidMount = async() => {
        const listFriends = await this.storeContent.getFriends();
        console.log('SbC friends', this.storeContent.listFriends)
    }

    handleClick = (url) => {
        window.open(url);
    }

    onNext = (section) => {
        //this.storeUi.setNavMain(section);
        let url = "/" + section + '/products';
        this.props.history.push(url);
    }

    render() {
        return (
            <React.Fragment>
                <div 
                    className = "section section--promo promo--friends"
                >
                
                    <div 
                        className="promo--friends__header"
                    >
                        <h2>
                            <span>Heb je nog even tijd?</span>
                        </h2>
                        <h3>
                            Kijk dan ook eens hier naar
                        </h3>
                    </div>

                    <div className="promo--friends__columns">
                        <ul>
                            {this.storeContent.listFriends.map((friend) => (
                                <li
                                    className='promo--friends__column'
                                >
                                    <FriendSummary
                                        friend = {friend}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
  }
}

    )
)

export default withRouter(SectionPromoFriends);
