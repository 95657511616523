import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import * as shopUtils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

const EditAddresses = inject("stores") (
    observer (
        class EditAddresses extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    addresses: []
                };

                if (this.props.stores.storeUi.app_interface.user_logged_in) {
                    this.getCustomerAddresses();
                }
            }

            async getCustomerAddresses() {
                let apiReturn = await account.getAddresses(this.props.stores.storeUi.app_auth.access_token);

                if ('error' in apiReturn && apiReturn.error.length > 0 ) {

                } else {
                    this.setState(state => {
                        state.addresses = state.addresses.concat(apiReturn.data.addresses);
                        return state;
                    }, console.log('SbC:: customer addresses::', this.state));
                }
            }

            handleChange = (e, i) => {
                let change = {};
                let identifier = e.target.name;
                change[identifier] = e.target.value;

                this.setState(state => {
                    const addresses = state.addresses.map((address, j) => {
                      if (j === i) {
                        address[identifier] = change[identifier];
                      } 
                      return address
                    });
                    return {
                      addresses
                    };
                });
            }

            async handleDelete(e, id) {
                console.log('SbC address to delete::', id)
                let apiReturn = await account.deleteAddress(id, this.props.stores.storeUi.app_auth.access_token);

                if ('error' in apiReturn && apiReturn.error.length > 0 ) {
                   let customerId = 0;
                } else {
                    console.log('SbC address deleted!::', id, apiReturn)
                    this.removeAddressFromView(id);
                }
            }

            async handleSubmit(e, id) {
                e.preventDefault();
                let address = this.findInArray(this.state.addresses, 'address_id', id);
                console.log('SbC address to change!::', address[0])
                let apiReturn = await account.editAddress(id, address[0], this.props.stores.storeUi.app_auth.access_token);
     
                if ('error' in apiReturn && apiReturn.error.length > 0 ) {
                   let customerId = 0;
                } else {
                    console.log('SbC address changed!::', id, apiReturn)
                }
            }

            removeAddressFromView(id) {
                let addresses = this.removeFromArray(this.state.addresses, 'address_id', id);
                console.log('SbC new arr::', addresses)
                this.setState(state => {
                    state.addresses = addresses;
                    return state;
                });
            }

            findInArray(array, key, value) {
                return array.filter(el => el[key] === value);
            }

            removeFromArray(array, key, value) {
                return array.filter(el => el[key] !== value);
            }

            render() {
                return (
                    this.props.stores.storeUi.app_interface.user_logged_in
                    ?   <div className="wrapper-account">
                            <div className="container-account">
                                <div className="container-account__header">
                                    <h1>Manage addresses:: {this.props.stores.storeUser.user.user_id}</h1>
                                    <p>Change or delete an address, or add a new one</p>
                                    <Link to='/add-address'>Add address</Link>
                                </div>

                                {this.state.addresses.map((address, i) =>
                                    <React.Fragment>
                                    <div className="container-account__content">
                                        <h2>{this.state.addresses[i].alias}</h2>
                                        <form>
                                            <div className="form-row --dev">
                                                <div className="form-col form-col--label">
                                                    <label>
                                                        Street
                                                    </label>
                                                </div>
                                                <div className="form-col form-col--input">
                                                <input 
                                                        type="text"
                                                        name="address_1"
                                                        value={this.state.addresses[i].address_1} 
                                                        onChange={(e)=>this.handleChange(e,i)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row --dev">
                                                <div className="form-col form-col--label">
                                                    <label>
                                                        House number
                                                    </label>
                                                </div>
                                                <div className="form-col form-col--input">
                                                <input 
                                                        type="text"
                                                        name="address_2"
                                                        value={this.state.addresses[i].address_2} 
                                                        onChange={(e)=>this.handleChange(e,i)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row --dev">
                                                <div className="form-col form-col--label">
                                                    <label>
                                                        Zip code
                                                    </label>
                                                </div>
                                                <div className="form-col form-col--input">
                                                <input 
                                                        type="text"
                                                        name="postcode"
                                                        value={this.state.addresses[i].postcode} 
                                                        onChange={(e)=>this.handleChange(e,i)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row --dev">
                                                <div className="form-col form-col--label">
                                                    <label>
                                                        City
                                                    </label>
                                                </div>
                                                <div className="form-col form-col--input">
                                                <input 
                                                        type="text"
                                                        name="city"
                                                        value={this.state.addresses[i].city} 
                                                        onChange={(e)=>this.handleChange(e,i)}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="container-account__footer">
                                        <div className="button-row">
                                            <span className="button button--account button--primary --dev" onClick={(e)=>this.handleSubmit(e, this.state.addresses[i].address_id)}>Submit changes</span>
                                            <span className="button button--login button--secundary --dev" onClick={(e)=>this.handleDelete(e, this.state.addresses[i].address_id)}>Delete address</span>
                                        </div>
                                    </div>
                                    </React.Fragment>
                                )}

                                <div className="container-account__footer">
                                    <div className="button-row">
                                        <span className="button button--account button--secundary --dev">
                                            <Link to='/overview-account'>Cancel</Link>
                                        </span>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    :   <div className="wrapper-account">
                            <div className="container-account">
                                <h1>NOT LOGGED IN</h1>
                                <Link to='/login'>Go to login</Link>
                            </div>
                        </div>
                );
            }
        }
    )
)
                    
export default EditAddresses;

