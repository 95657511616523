import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import ProductSummary from '../../components/products/product-summary';

const SectionPromoProducts = inject("stores") (
    observer (
        class SectionPromoProducts extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;

        this.storeUi = this.props.stores.storeUi;
        this.storeProducts = this.props.stores.storeProducts;

        this.state = {
            productImages: [{
                    title: 'Zalmhuid',
                    file: '7091042550618.png'
                },{
                    title: 'Lampens',
                    file: '7091042366622.png',
                },{
                    title: 'Kipfilet',
                    file: '7091045814779.png'
                }                
            ]
        };      
    }

    componentDidMount = async() => {
        this.storeUi.setFilterShop('view', 'blocks');
        this.storeUi.setFilterShop('category', 'snacks');
        this.storeUi.setFilterShop('type', 'all');

        const products = await this.storeProducts.getProducts(this.storeUi.filtersShop.category, 'all');
        const section = 'snacks';
        
        this.setState({
            section: section
        })
    }

    handleClick = (url) => {
        window.open(url);
    }

    onNext = (section) => {
        //this.storeUi.setNavMain(section);
        let url = "/" + section + '/products';
        this.props.history.push(url);
    }

    render() {
        return (
            <React.Fragment>
                <div 
                    className="section section--promo promo--products"
                >
                
                    <div 
                        className="wrapper--section section--header"
                    >
                        <h2>
                            <span>Ja die ... en die ...<br/>en natuurlijk die ook ...</span>
                        </h2>
                    </div>

                    <div className="wrapper-promo">
                        <div className="wrapper-products wrapper-products--blocks">
                            {this.storeProducts.listProducts && this.storeProducts.listProducts.length > 0 &&
                                <ul>
                                    {this.storeProducts.listProducts.map((product, i) => (
                                        i < 3 &&
                                        <li>
                                            <ProductSummary
                                                ean = {product.ean}
                                                product = {product}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>    
                    </div>
                                    
                    <div className="form-row form-row--buttons --single --focus">
                        <div className="form-row--button --active">
                            <div 
                                className="form-row__button button--primary --link"
                                onClick={() => this.onNext('shop')}
                            >
                                <span className="button--arrow --right" />
                            </div>
                            <div 
                                className="form-row__button-text --link"
                                onClick={() => this.onNext('shop')}
                            >
                                Bekijk alles wat ik lekker vind
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
  }
}

    )
)

export default withRouter(SectionPromoProducts);
