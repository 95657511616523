import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const TeaserProductRange = inject("stores") (
    observer (
        class TeaserProductRange extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.storeUi = this.props.stores.storeUi;
        this.storeProducts = this.props.stores.storeProducts;

        this.state = {

        }
    }

    componentDidMount() {
        
    }

    render() {       
        return (
            <div
                className="teaser teaser--product-range"
            >
                <div
                    className="teaser--product-range__content"
                >
                    <h1>Iets niet gevonden?</h1>
                    <h2>We zijn momenteel hard bezig om ons aanbod te vergroten.<br/>
                    Houd deze pagina in de gaten voor nieuwe producten.</h2>
                </div>
            </div>                      
        );
  }
}

    )
)

export default TeaserProductRange;
