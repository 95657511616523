import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default class TermsConditions extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    render() {
        return (
            <div className="page-content">
                TERMS & CONDITIONS
            </div>
        );
    }
}
