import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import i18n from "i18next";

import * as utils from '../../communicator/shop/shop';
import * as login from '../../communicator/shop/login';

const CreateAccountForm = inject("stores") (
    observer (
        class CreateAccountForm extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    firstname: "",
                    lastname: "",
                    email: "",
                    telephone: '',
                    customer_group_id: "",
                    password: "",
                    password_confirmation: "",
                    agree: 0
                };
            }

            handleChange = e => {
                let change = {};
                change[e.target.name] = e.target.value;
                this.setState(change);
            }

            async handleSubmit(e) {
                e.preventDefault();

                let customer = {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: this.state.email,
                    telephone: this.state.telephone,
                    customer_group_id: 2, // ADOGSLIFE
                    password: this.state.password, 
                    password_confirmation: this.state.password_confirmation,
                    agree: 1
                }
     
                let apiCustomerDetails = await login.createCustomer(customer, this.props.stores.storeUi.app_auth.access_token);

                console.log('SbC created customer::', apiCustomerDetails)
                if ('error' in apiCustomerDetails) {
                    apiCustomerDetails.id = 0;
                } else {
                    //SbC:: successfully created
                    //SbC:: save to store
                    console.log('Customer::', apiCustomerDetails)
                }

                this.props.followUpLoginSubmit();
            }

            render() {
                i18n.changeLanguage(this.props.stores.storeUi.app_interface.lang);

                return (
                    <React.Fragment>
                        <div className="container-account__content">
                            <form>
                                <div className="form-row">
                                    <div className="form-col form-col--label">
                                        <label>
                                            {i18n.t("form_label.firstname")}
                                        </label>
                                    </div>
                                    <div className="form-col form-col--input">
                                        <input 
                                            type="text"
                                            name="firstname"
                                            value={this.state.firstname} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-col form-col--label">
                                        <label>
                                            {i18n.t("form_label.lastname")}
                                        </label>
                                    </div>
                                    <div className="form-col form-col--input">
                                        <input 
                                            type="text"
                                            name="lastname"
                                            value={this.state.lastname} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-col form-col--label">
                                        <label>
                                            {i18n.t("form_label.email")} 
                                        </label>
                                    </div>
                                    <div className="form-col form-col--input">
                                        <input 
                                            type="text"
                                            name="email"
                                            value={this.state.email} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-col form-col--label">
                                        <label>
                                            {i18n.t("form_label.telephone")}
                                        </label>
                                    </div>
                                    <div className="form-col form-col--input">
                                        <input 
                                            type="text"
                                            name="telephone"
                                            value={this.state.telephone} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <h2>{i18n.t("header_h2.password")}</h2>

                                <div className="form-row">
                                    <div className="form-col form-col--label">
                                        <label>
                                            {i18n.t("form_label.password")}
                                        </label>
                                    </div>
                                    <div className="form-col form-col--input">
                                        <input 
                                            type="text" 
                                            name="password" 
                                            value={this.state.password} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-col form-col--label">
                                        <label>
                                            {i18n.t("form_label.password_confirm")}
                                        </label>
                                    </div>
                                    <div className="form-col form-col--input">
                                        <input 
                                            type="text" 
                                            name="password_confirmation" 
                                            value={this.state.password_confirmation} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="container-account__footer">
                            <div className="button-row">
                                <span className="button button--account button--secundary">
                                    <Link to='/shop/home'>{i18n.t("button.cancel")}</Link>
                                </span>
                                <span className="button button--account button--primary" onClick={(e)=>this.handleSubmit(e)}>{i18n.t("button.create_account")}</span>
                            </div>
                        </div>
                    </React.Fragment> 
                );
            }
        }
    )
)
                    
export default CreateAccountForm;

