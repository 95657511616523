import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockVisual = inject("stores") (
    observer (
        class BlockVisual extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
    
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    filename: ''
                }
            }

            componentDidMount = () => {
                this.setState({
                    filename: this.props.filename
                })
            }

            componentDidUpdate(prevProps) {
                if (prevProps.filename !== this.props.filename) {
                    this.setState({
                        filename: this.props.filename
                    })
                }
            }

            render() {
                const bg_img = this.urlAPI + "public/images/" + this.props.segment + "/pagetop/" + this.state.filename;

                return (
                    <div 
                        className="top-visual__image"
                        style= {{backgroundImage:`url(${bg_img})` }}
                    />
                )
            }
        }
    )
)

export default withTranslation()(BlockVisual);
