import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import BrandProductSummary from '../products/brand-product-summary';

const ListBrandProducts = inject("stores") (
    observer (
        class ListBrandProducts extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.state = {
            affiliate: "",
            products: [],
            categories: [
                'organimal'
            ]
        }

        this.storeProducts = this.props.stores.storeProducts;
    }

    componentDidMount = async() => {
        const products = await this.storeProducts.getBrandProducts(this.props.section);

        console.log('SbC products', products)

        this.setState({
            products: products
        })
    }

    componentDidUpdate = async(prevProps) => {
        if (prevProps.section !== this.props.section) {     
            const products = await this.storeProducts.getBrandProducts(this.props.section);

            this.setState({
                products: products
            })
        }
    }

    setActiveCatSlide(idx) {
        //SbC:: 0 is extra shop categories overview
        this.props.stores.storeUi.setActiveCatSlide(idx + 1);
    }

    handleClick = (url) => {
        window.open(url);
    }

    render() {
        return (
            <div className="section section--list-products">
                {/* <h2 className="section__header">Producten via {this.state.affiliate}</h2> */}
                {this.state.products && this.state.products.map((product, idx) => (
                    <BrandProductSummary
                        section = {this.props.section}
                        product = {product}
                    />
                ))}
            </div>
        );
  }
}

    )
)

export default ListBrandProducts;
