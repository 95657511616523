import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default class PrivacyStatement extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    getMainCatLink(catName) {
        let catLink = '/shop/' + catName;
        return catLink;
    }

    render() {
        return (
            <div className="page-contnet">
                PRIVACY STATEMENT
            </div>
        );
    }
}
