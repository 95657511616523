import React from 'react';
import i18n from "i18next";

const ButtonDialog = (props) => {

    let btnClass = "dialog-footer__button";

    if (props.btnClass && props.btnClass !== '') {
        btnClass = btnClass + ' ' + btnClass + props.btnClass;
    }
  
    return (
        <div className="form-action__button">
            <div 
                className="form-action__button-text"
                onClick = {props.onAction}
            >
                {props.caption}
            </div>
            <div 
                className="form-action__button button--secundary"
                onClick = {props.onAction}
            >
                <span className={"button--arrow " + props.btnClass} />
            </div>
        </div>
    );
}

export default ButtonDialog;