import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as settings from '../communicator/settings';
import * as carts from '../communicator/shop/carts';

class StoreShipping {

    shippingLetter = '4.95';
    shippingPackage = '6.95';
    shipping = 0;
    orderWeight = 0;
    
    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeCarts = this.storeRoot.storeCarts;
        this.storeProducts = this.storeRoot.storeProducts;
    }

    getShipping = async() => {
        //SbC shipping is ECXL vat;
        const cartId = this.storeAuth.getCartId();
        const cart = await this.storeCarts.getCart(cartId);
        let shipping = 0;
        let weight = 0;
        
        //SbC check free shipping
        const freeShipping = this.checkFreeShipping(cart);

        // SbC get shipping country
        const country = this.getShippingCountry();

        // SbC calculate cart weight
        const cartWeight = await this.getCartWeight(cart);

        // SbC define packaging
        const packageSize = await this.getPackageSize(cart, cartWeight);

        // SbC calculate package weight
        const packageWeight = this.getPackageWeight(packageSize);

        const orderWeight = packageWeight + cartWeight;
        this.orderWeight = orderWeight;

        if (freeShipping) {
            shipping = 0;
        } else {
            shipping = await this.calcShipping(country, packageSize, orderWeight);
        }

        this.shipping = shipping;

        return shipping;
    }

    checkFreeShipping(cart) {
        let freeShipping = false;
        let total = 0;

        cart.map(item => {
            total = total + item.amount * item.price;
        })

        if (total >= 50) {
            freeShipping = true;
        }

        return freeShipping
    }

    getPackageSize = async(cart, weight) => {
        let packageSize = 1;
        let container;

        if (this.storeProducts.listProducts.length === 0) {
            const products = await this.storeProducts.getProducts();
        }

        if (weight > 2000) {
            packageSize = 2;
        }
             
        let idx;
        let score = 0;

        cart.map(item => {
            idx = this.storeProducts.listProducts.findIndex(product => product.sku === item.sku);
            // container = this.storeProducts.listProducts[idx].container;
            // if (container === 'BOTTLE') {
            //     packageSize = 2;
            // }

            // idx = this.storeGeneral.listPackaging.findIndex(pack => pack.sku === container);
            // score = score + (parseFloat(this.storeGeneral.listPackaging[idx].scorePackaging) * item.amount);
        })

        if (score > 1) {
            packageSize = 2;
        }

        return packageSize;
    }

    getPackageWeight(packageSize) {
        let weight = 80;

        // if (packageSize === 1) {
        //     weight = this.storeGeneral.listSettings['defaultWeightLetter'];
        // }
        // if (packageSize === 2) {
        //     weight = this.storeGeneral.listSettings['defaultWeightPackage'];
        // }

        return parseInt(weight);
    }

    getCartWeight = async(cart) => {
        let weight = 0;
        let container;
        let weightContainer = 10;
        let weightItem = 0;
        let idx;

        if (this.storeProducts.listProducts.length === 0) {
            const products = await this.storeProducts.getProducts();
        }
      
        cart.map(item => {
            idx = this.storeProducts.listProducts.findIndex(product => product.sku === item.sku);
           
            //container = this.storeProducts.listProducts[idx].container;     
            //weightItem = parseInt(this.storeProducts.listProducts[idx].contents);

            //idx = this.storeGeneral.listPackaging.findIndex(pack => pack.sku === container);
            //weightContainer = parseInt(this.storeGeneral.listPackaging[idx].weight);

            weight = weight + (weightItem + weightContainer) * item.amount;
        })

        return parseInt(weight);
    }

    getShippingCountry() {
        let country = 'NL';

        return country;
    }

    async getShippingForCountry(country) {
        let shipping;
        try {
            const returnData = await carts.getShipping(country);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    shipping = returnData.data;
                } 
                return shipping;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return shipping;
    }   
    
    getPackaging() {

    }

    saveShipping = async(cartId, shipping) => {
        try {
            const returnData = await carts.saveShipping(cartId, shipping);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {

                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    calcShipping = async(country, packageSize, orderWeight) => {
        let shipping = 0;

        try {
            const returnData = await settings.getShipperCosts(orderWeight);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    if (parseInt(packageSize) === 1) {
                        shipping = returnData.data[0]['letterPostNL'];
                    } 
                    if (parseInt(packageSize) === 2) {
                        shipping = returnData.data[0]['packagePostNL'];
                    } 
                } 
                return shipping;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return shipping;
    }
}

export default StoreShipping;