
import * as apifetch from '../fetch.js';

export async function getUser(userId) {
    const urlEndpoint = 'accounts/' + userId;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getAddress(userId) {
    const urlEndpoint = 'accounts/' + userId + '/address'; 

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function saveUser(type, userId, data) {
    const urlEndpoint = 'accounts/' + userId + '/profile';

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'PUT', postData);
}

export async function saveAddress(type, userId, data) {
    const urlEndpoint = 'accounts/' + userId + '/address'; 

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'PUT', postData);
}

export async function getPets(userId) {
    const urlEndpoint = 'accounts/' + userId + '/pets'; 

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function savePet(type, userId, data) {
    const urlEndpoint = 'accounts/' + userId + '/pet';

    const postData = data;
    console.log('SbC pet', postData)

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}
