import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

// import * as utils from '../../communicator/shop/shop';
// import * as account from '../../communicator/shop/account';
// import * as cart from '../../communicator/shop/carts';

import CartItems from './cart-items';

const CartOverview = inject("stores") (
    observer (
        class CartOverview extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.storeAuth = this.props.stores.storeAuth;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    cart: []
                }
            }

            componentDidMount = async() => {
                this.getCart();
            }

            getCart = async() => {
                const id = this.storeAuth.getCartId();
                const cart = await this.storeCarts.getCart(id);
                
                this.setState({
                    cart: cart
                })
            }
 
            gotoCartSection = (section) => {
                if (section === 'shop') {
                    this.props.history.goBack();
                } else {
                    this.storeUi.setCartSection(section);
                }
            }

            render() {
                return (
                    <React.Fragment>
                        <div className="container-cart__content">
                            <div className="container-cart__products">
                                {(this.storeCarts.cart !== null && this.storeCarts.cart.length !== 0)
                                    ?   <CartItems 
                                            cart = {this.state.cart}
                                            editable = {true}
                                        />
                                    :   <span
                                            className="container-cart__empty"
                                        >
                                            Je winkelwagen is nog leeg
                                        </span>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
        }
    )
)
                    
export default withTranslation()(withRouter(CartOverview));