import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Typewriter from 'typewriter-effect';

const BlockTypewriter = inject("stores") (
    observer (
        class BlockTypewriter extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeGeneral = this.props.stores.storeGeneral;

                this.typingText1 = 'shasha';
                this.typingText4 = this.props.dogname;

                this.state = {
                   
                };
            }

            componentDidMount = async() => {
            }

            componentDidUpdate = async(prevProps) => {
            }

            getDogName = () => {
                const max = this.storeGeneral.dogNames.length;
                const rand = Math.floor((Math.random() * max));
                const dogName =this.storeGeneral.dogNames[rand];

                return dogName;
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper-typewriter">
                            <Typewriter
                                options={{
                                    autoStart: true,
                                    loop: true,
                                    cursor: '_',
                                    cursorClassName: "Typewriter__cursor header-container__logo-txt--cursor"
                                }}
                                onInit={(typewriter) => {
                                    typewriter
                                        .changeDelay(200)
                                        .pauseFor(500)
                                        .typeString(this.typingText1)
                                        .pauseFor(300)
                                        .deleteAll()
                                        //.deleteChars(4)
                                        .typeString(this.getDogName())
                                        .pauseFor(2500)
                                        .deleteAll()
                                        .typeString(this.getDogName())
                                        .pauseFor(2500)
                                        .deleteAll()
                                        .typeString(this.typingText4)
                                        .pauseFor(2500)
                                        .deleteAll()
                                        .start();
                                }}
                            />
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default BlockTypewriter;