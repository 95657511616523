export function handleScroll(pos) {
    if (pos === 'top') {
        window.scrollTo(0, 0);
    } else {
        window.scrollTo(0, pos);
    }
}

export function checkMatchingValues(val1, val2) {
    let matching = (val1 === val2 && val1 !== '' && val2 !== '') ? true : false;
    return matching;
}

export function contains(a, obj) {
    for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
}

export function smoothScrollTo(endX, endY, duration) {
    let startX = window.scrollX || window.pageXOffset,
    startY = window.scrollY || window.pageYOffset,
    distanceX = endX - startX,
    distanceY = endY - startY,
    startTime = new Date().getTime();

    // Easing function
    let easeInOutQuart = function(time, from, distance, duration) {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    let timer = window.setInterval(function() {
        let time = new Date().getTime() - startTime,
        newX = easeInOutQuart(time, startX, distanceX, duration),
        newY = easeInOutQuart(time, startY, distanceY, duration);
        if (time >= duration) {
            window.clearInterval(timer);
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

export function convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}