import { makeObservable, observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import {
    isBrowser,
    isMobile
} from "react-device-detect";

import * as authUtils from '../communicator/shop/auth';
import * as login from '../communicator/shop/login';

class StoreUi {
    
    app_interface = { 
        lang: '',
        lang_id: 0,
        height_footer: 0,
        isDialogContentOpen: false
    }  

    filtersShop = {
        category: 'snacks',
        type: 'all',
        view: 'blocks'
    }

    settings_list = {
        "maxLengthProfile": 250,
        'filterScroll': isMobile ? 17.5 : 12.5
    }

    isDialogContentOpen = false
    stickyFilters = false
    filtersTop = 0
    filtersHeight = 0

    constructor() {
        makeObservable(this, {
            filtersShop: observable,
            filtersTop: observable,
            filtersHeight: observable,
            isDialogContentOpen: observable,
            stickyFilters: observable
        })
    }

    openDialogContent = () => {
        this.app_interface.isDialogContentOpen = true;
        this.isDialogContentOpen = true;
    }

    closeDialogContent = () => {
        this.app_interface.isDialogContentOpen = false;
        this.isDialogContentOpen = false;
    }

    setFilterShop(filter, value) {
        this.filtersShop[filter] = value;
    }

    setLocale = (lang) => {

    }

    setActiveVideo(idx) {
        this.active_video = idx;
    }

    setStickyFilters(value) {
        this.stickyFilters = value;
    }

    setPosTopFilters(value) {
        this.filtersTop = value;
    }

    setHeightFilters(value) {
        this.filtersHeight = value;
    }
}

export default StoreUi;