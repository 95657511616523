import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import i18n from "i18next";

import * as utils from '../../communicator/shop/shop';
import * as login from '../../communicator/shop/login';

import CreateAccountForm from '../account/account-create-form';

const CreateAccount = inject("stores") (
    observer (
        class CreateAccount extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    user_created: false
                };
            }

            followUpLoginSubmit() {
                this.setState({
                    user_created: true
                })
            }

            render() {
                i18n.changeLanguage(this.props.stores.storeUi.app_interface.lang);

                return (
                    <div className="wrapper-login">
                        {!this.state.user_created &&
                            <div className="container-account">
                                <div className="container-account__header">
                                    <h1>{i18n.t("header.create_account")}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: i18n.t("header.intro.create_account") }}></p>
                                    <span className="button button--login button--tertiary">
                                        <Link to='/login'>{i18n.t("button.login_to_account")}</Link>
                                    </span>
                                </div>

                                <CreateAccountForm 
                                    followUpLoginSubmit = {this.followUpLoginSubmit} 
                                />
                            </div>
                        }

                        {!this.state.user_created &&
                            <div className="container-login">
                                <div className="container-login__header">
                                    <h1>{i18n.t("header.user_created")}</h1>
                                </div>
                                <div className="container-login__content">
                                    <Link to='/shop/home'>Return</Link>
                                </div>
                            </div>
                        }

                    </div>
                );
            }
        }
    )
)
                    
export default CreateAccount;

