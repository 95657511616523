import { makeObservable, observable, computed, action, decorate } from "mobx";
import { runInAction } from "mobx";

import * as content from '../communicator/content';

class StoreContent {

    listReviews = []
    listFriends = []
    
    constructor(storeRoot) {
        makeObservable(this, {
            listReviews: observable,
            listFriends: observable
        })
    }
    
    getReviews = async() => {
        try {
            const returnData = await content.getReviews();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.listReviews = returnData.data;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    getFriends = async() => {
        try {
            const returnData = await content.getFriends();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.listFriends = returnData.data;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
}

export default StoreContent;