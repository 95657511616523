import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const SectionPromoCompany = inject("stores") (
    observer (
        class SectionPromoCompany extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;

        this.storeUi = this.props.stores.storeUi;
        this.storeShop = this.props.stores.storeShop;
        this.storeProducts = this.props.stores.storeProducts;

        this.state = {
            productImages: [
                'affiliates/krachtkruid/logo-krachtkruid.png',
                'affiliates/krachtkruid/herbs-6.jpg',
                'affiliates/krachtkruid/kk-2.jpg',
                'affiliates/krachtkruid/process-4.jpg'
            ],
        }; 
    }

    componentDidMount = async() => {
        const sku = this.props.sku;
        const product = await this.storeProducts.getShopProduct(sku);
        console.log('SbC product::', this.props.sku, product)
    }

    handleClick = (url) => {
        window.open(url);
    }

    render() {
        return (
            <React.Fragment>
                <div 
                    className="section--promo-company"
                >
                    <div 
                        className="section--promo-company__header"
                    >
                        <h2>
                            <span>Maar niet alleen snackies natuurlijk.</span>
                        </h2>
                    </div>

                    <div 
                        className="section__columns"
                    >
                        <div 
                            className="section__column"
                        >
                            <div 
                                className="section__column-content section__column-content--intro"
                            >
                                Af en toe kan ik ook wel wat extra ondersteuning gebruiken.
                            </div>
                            <span className="section__header-divider" />
                            <div 
                                className="section__column-content"
                            >
                                <p className="product-summary__p">Krachtkruid maakt producten op natuurlijke basis om mij weerbaar en vitaal te houden.</p>                              
                                <p className="product-summary__p">Neem nou bijvoorbeeld potenzalf.<br/>
                                    Krachtkruid potenzalf beschermt mijn zolen tijdens de winter. Het herstelt de ruwe huid en heelt kleine wondjes. Gemaakt met was van hun eigen schone bijen en met kruiden van eigen akker.</p>
                                <p className="product-summary__p">Daarom ben ik op deze dagen zo blij met Krachtkruid.</p>
                            </div>
                            <span className="section__header-divider" />
                            <div 
                                className="section__column-content"
                            >
                                <span>Je kunt deze potenzalf (en andere producten) bestellen via www.krachtkruid.nl</span>
                            </div>
                        </div>
                    
                        <div 
                            className="section__column"
                        >
                            <div 
                                className="section__column-content"
                            >
                                
                                <div className="section__product-previews">
                                    {this.state.productImages && this.state.productImages.map((productImage, i) => (    
                                        <div 
                                            className="section__product-preview"
                                            style= {{backgroundImage:`url(${this.urlAPI + "public/images/" + productImage})` }}
                                        >
                                        </div>  
                                    ))}
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div 
                        className="section__columns"
                    >
                        <div 
                            className="section__column section__column--single"
                        >
                            <div className="form-row form-row--buttons --single --focus">
                                <div className="form-row--button">
                                    <div 
                                        className="button button--external-link"
                                        onClick={() => this.handleClick('https://www.krachtkruid.nl')}
                                    >
                                        <div 
                                            className="box"
                                        >
                                            <div 
                                                className="box"
                                            >
                                                <div 
                                                    className="arrow-head"
                                                />
                                            </div>
                                        </div>
                                        <div class="button__text --link">Bezoek Krachtkruid</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        );
  }
}

    )
)

export default withRouter(SectionPromoCompany);
