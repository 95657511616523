import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ListProductsCategory from './list-products-category';
import ListProductsRecommended from './list-products-recommended';

class Category extends Component {

    constructor(props) {
        super(props);

        this.state = {
            category: this.props
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.category !== prevState.category) {
            return {
                category: nextProps.category
            };
        }
        else return null;
    }

    render() {
        return (
            <div className="heading-cat-container">
                <h1 className="heading-cat">{this.props.category.category_id} {this.props.category.name}</h1>

                Category
            </div>
        );
  }
}

export default Category;
