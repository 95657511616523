import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const ProductSummaryPreviews = inject("stores") (
    observer (
        class ProductSummaryPreviews extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeShop = this.props.stores.storeShop;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    product: {},
                    price: {}
                }; 
            }

            componentDidMount = async() => {
                let bgImg;
                const sku = this.props.product.ean;
                const productData = await this.storeProducts.getProductData(sku);
                const productPrice = await this.storeProducts.getProductPrice(sku);

                if (productData.displayImg1 !== '') {
                    bgImg = productData.displayImg1;
                } else {
                    bgImg = productData.ean + ".png";
                }

                this.setState({
                    bgImg: bgImg,
                    product: productData,
                    price: productPrice
                })

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.ean !== this.props.ean) {
                    let bgImg;
                    const sku  = this.props.ean;
                    const productData = await this.storeProducts.getProductData(sku);
                    const productPrice = await this.storeProducts.getProductPrice(sku);

                    if (productData.displayImg1 !== '') {
                        bgImg = productData.displayImg1;
                    } else {
                        bgImg = productData.ean + ".png";
                    }

                    this.setState({
                        bgImg: bgImg,
                        product: productData,
                        price: productPrice
                    })
                }
            }

            handleClick = (url) => {
                this.storeUi.openDialogContent();
            }

            handleAddProduct = async() => {
                let cartId = this.storeAuth.cartId;
                if (cartId === 0) {
                    cartId = await this.storeCarts.createNewCart();
                }
                const productAdded = await this.storeCarts.addToCart('guest', cartId, this.props.product, this.state.price, 1)

                this.storeCarts.getCart(cartId);
                this.storeCarts.getCartAmount(cartId);
            }

            render() {
                return (
                    <React.Fragment>     
                        <div 
                            className="section__product-preview"
                            style= {{backgroundImage:`url(${this.urlAPI + "public/images/products/dogfood/" + this.state.bgImg})` }}
                        />
                     </React.Fragment>
                );
            }
        }

    )
)

export default ProductSummaryPreviews;
