import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Swiper, Slide } from '../../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';

const ResetPassword = inject("stores") (
    observer (
        class ResetPassword extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    passwd: "",
                    new_passwd: "",
                    new_passwd2: "",
                };

                this.getCustomerDetais();
            }

            async getCustomerDetais() {
                let customerDetails = await account.getCustomerDetails(this.props.stores.storeUser.user.user_email, this.props.stores.storeUser.user.user_pw);
                this.setState({
                    email: customerDetails.email,
                    firstname: customerDetails.firstname,
                    lastname:  customerDetails.lastname,
                })
            }

            handleChange = e => {
                let change = {};
                change[e.target.name] = e.target.value;
                this.setState(change);
            }

            async handleSubmit(e) {
                e.preventDefault();
     
                let changeResponse = await account.changeCustomerDetails(this.state.email, this.state.firstname, this.state.lastname, this.props.stores.storeUser.user.user_pw);

                console.log('SbC changed customer::', changeResponse)
                if ('error' in changeResponse) {
                   let customerId = 0;
                } else {

                }
            }

            render() {
                return (
                    <div className="wrapper-login">
                        <div className="container-login">
                            <div className="container-login__header">
                                <h1>Change password:: {this.props.stores.storeUser.user.user_id}</h1>
                            </div>

                            <div className="container-login__content">
                                <form>
                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Old password
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text" 
                                                name="passwd" 
                                                value={this.state.password} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                New password
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text" 
                                                name="passwd" 
                                                value={this.state.new_password} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row --dev">
                                        <div className="form-col form-col--label">
                                            <label>
                                                Confirm new password
                                            </label>
                                        </div>
                                        <div className="form-col form-col--input">
                                            <input 
                                                type="text" 
                                                name="passwd" 
                                                value={this.state.new_password2} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="container-login__footer">
                                <div className="button-row">
                                    <span className="button button--login button--secundary --dev">
                                        <Link to='/overview-account'>Cancel</Link>
                                    </span>
                                    <span className="button button--login button--primary --dev" onClick={(e)=>this.handleSubmit(e)}>Submit changes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
)
                    
export default ResetPassword;

