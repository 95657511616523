import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const FilterViews = inject("stores") (
    observer (
        class FilterViews extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    views: [
                        'blocks',
                        'summaries'
                    ]
                }
                // this.state = {
                //     views: [
                //         'summaries'
                //     ]
                // }
            }

            componentDidMount = () => {
                const px = this.storeUi.filtersTop;
                console.log('SbC margin', px)
            }

            handleFilter = (filter, value) => {
                this.storeUi.setFilterShop(filter, value);
            }

            render() {
                let className = this.storeUi.filtersShop.view

                return (
                    <div 
                        className={"container-filter container-filter--views"}
                        style={{marginTop: this.storeUi.stickyFilters ? this.storeUi.filtersHeight : 0}}
                    >
                        <ul>
                            <li
                                className = {"--link" + (className === 'blocks' ? ' --active' : '')}
                                onClick = {() => this.handleFilter('view', 'blocks')}
                            >
                                <img src={this.urlAPI + "public/images/shop/icons/ic-view-blocks.png"} />
                            </li>
                            <li
                                className = {"--link" + (className === 'summaries' ? ' --active' : '')}
                                onClick = {() => this.handleFilter('view', 'summaries')}
                            >
                                <img src={this.urlAPI + "public/images/shop/icons/ic-view-summaries.png"} />
                            </li>
                        </ul>
                    </div>
                );
            }
        }
    )
)

export default FilterViews;
