import { makeObservable, observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as users from '../communicator/accounts/users';

class StoreUser {
    user = {
        user_id: 0,
        user_email: '',
        user_pw: ''
    }
    dogName = 'shasha'

    async getUser(userId) {
        let user = {};
        try {
            const returnData = await users.getUser(userId);
            runInAction(() => {
                user = returnData.data[0];
                return user;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return user;
    }

    async getAddress(userId) {
        let address = {};
        try {
            const returnData = await users.getAddress(userId);
            runInAction(() => {
                address = returnData.data[0];
                return address;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return address;
    }

    async saveUser(type, userId, data) {
        const user = {
            type: type,
            firstname: data.firstname.value,
            middlename: data.middlename.value,
            lastname: data.lastname.value,
            email: data.email.value
        };
        try {
            const returnData = await users.saveUser(type, userId, user);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    async saveAddress(userId, data) {
        const user = {
            street: data.street.value,
            housenr: data.housenr.value,
            addition: data.addition.value,
            zipcode: data.zipcode.value,
            city: data.city.value,
            country: 'NL',
        };
        try {
            const returnData = await users.saveAddress(userId, user);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    setUserId(state, id) {
        if (state === 'logged_in') {
            this.user.user_id = id;
        } else {
            this.user.user_id = 0;
        }
    }

    setUserPw(state, pw) {
        if (state === 'logged_in') {
            this.user.user_pw = pw;
        } else {
            this.user.user_pw = '';
        }
    }

    setUserEmail(state, email) {
        if (state === 'logged_in') {
            this.user.user_email = email;
        } else {
            this.user.user_email = '';
        }
    }

    setDogName(dogName) {
        this.dogName = dogName;
    }

    async getPets(userId) {
        let pets = [];
        try {
            const returnData = await users.getPets(userId);
            
            runInAction(() => {
                pets = returnData.data;
                return pets;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return pets;
    }

    async savePet(type, userId, petId, data) {
        const pets = {
            userId: userId,
            petId: petId,
            type: type,
            pet: data.pet.value,
            petName: data.petName.value
        };
        try {
            const returnData = await users.savePet(type, userId, pets);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }
}

// decorate(StoreUser, {
//     dogName: observable,
//     user: observable,
//     setUserId: action,
//     setUserPw: action
// })

export default StoreUser;