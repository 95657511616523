import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from '../modules/react-dynamic-swiper/lib';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import * as shop from '../communicator/shop/shop';

import TopBar from '../components/general/topbar';

import NavBreadcrumb from '../components/navigation/nav-breadcrumb';
import ProductConfiguration from '../components/products/product-configuration';

const Product = inject("stores") (
    observer (
        class Product extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            product: {}
        }
    }

    async componentDidMount() {
        let apiProduct;
        let product_id = this.props.product.match.params.id;

        if (this.props.stores.storeUi.app_auth.access_token === '') {
            await this.props.stores.storeUi.getAccessToken();
            apiProduct = await shop.getShopProductDetails(product_id, this.props.stores.storeUi.app_auth.access_token);
            console.log('SbC products api::', apiProduct)
        } else {
            apiProduct = await shop.getShopProductDetails(product_id, this.props.stores.storeUi.app_auth.access_token);
            console.log('SbC products api::', apiProduct)
        }

        this.setState({
            product: apiProduct.data
        });

        console.log('SbC api product::', apiProduct)
    }

    render() {
        return (
            <div className="page-product">
                <div className="topbar-container">
                    <div className="content-container">
                        <div className="content-container__content">
                            <TopBar />
                        </div>
                    </div>
                </div>

                <div className="page-product__product-header">
                    <div className="content-container">
                        <div className="content-container__content">
                            <NavBreadcrumb />
                            <div className="product__product-title">
                                <h1>{this.state.product.name}</h1>
                            </div>
                            <div className="product__product-intro">xxx fd f df sdf ds d fd fd</div>
                        </div>
                    </div>
                </div>

                <div className="page-product__product-content">
                    <div className="content-container">
                        <div className="content-container__content">
                            <div className="page-product__product-slider">
                               
                            </div>
                            
                            <div className="page-product__product-columns">
                                <div className="page-product__product-description">
                                    <div className="product__product-descr-top">xxx</div>
                                    <div className="product__product-quote">"kkkk</div>
                                    <div className="product__product-descr-bottom">ss sss</div>
                                </div>

                                <div className="page-product__product-configuration">
                                    <ProductConfiguration 
                                        product_id = {this.state.product.id}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        );
    }
}

)
)

export default Product;
