import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockUnderConstruction = inject("stores") (
    observer (
        class BlockUnderConstruction extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
    
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                   
                }
            }

            componentDidMount = () => {
               
            }

            componentDidUpdate(prevProps) {
               
            }

            render() {
                return (
                    <div 
                        className="section--under-construction"
                    >
                        <div className="content__columns">
                            <div className="content__column">
                                <h1>DEZE SHOP IS NOG IN ONTWIKKEL- EN TESTFASE</h1>
                                <h2>Er kunnen nog geen bestellingen geplaatst worden</h2>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockUnderConstruction);
