import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import NavTopBar from '../header/nav-topbar';

const TopBar = inject("stores") (
    observer (
        class TopBar extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }

            // handleLogOut() {
            //     this.props.stores.storeUi.setLoginState('logged_out');
            // }

            render() {
                return (
                    <div className="topbar">
                        <NavTopBar 
                            useSuspense = {false}
                        />
                    </div>
                );
            }
        }
    )
)

export default TopBar;
