import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

class FooterLegal extends Component {

    constructor(props) {
        super();
        this.props = props;
        
        this.state = {
        };
    }

    componentDidMount() {
    }

    gotoPage = (page) => {
        let url;
        //this.props.history.push(url);

        if (page === 'ps') {
            url = "https://www.ikshasha.nl/api/legal/ps/php";
        }
        if (page === 'ta') {
            url = "https://www.ikshasha.nl/api/legal/ta/php";
        }
        window.open(url, "_blank")
    }

    render() {
        return (
            <div className="footer-row footer-row--column">
                <ul>
                    <li 
                        className="--link"
                        onClick={() => this.gotoPage('ps')}>Privacy Statement
                    </li>
                    <li 
                        className="--link"
                        onClick={() => this.gotoPage('ta')}>Algemene Voorwaarden
                    </li>
                </ul>
            </div>
        );
    }
}

export default withRouter(FooterLegal);
