import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';
import TemplateCart from '../../templates/template-cart';
import PaymentReturnOrderData from '../../components/shop/payment-return-orderdata';

const PaymentReturn = inject("stores") (
    observer (
        class PaymentReturn extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    orderId: 0
                }
            }

            componentDidMount = async() => {
                const guid = this.getUrlParams(this.props.location.search);
                const orderId = await this.storeOrders.getOrderId(guid);

                const cart = this.storeAuth.removeCartId();
                let orderStatus = '';

                //SbC send confirmation email if not done in php yet
                if (parseInt(orderId) !== 0) {
                    // let orderStatus = await this.storeOrders.getOrderStatus(orderId);

                    // if (orderStatus !== 'bankTransfer') {
                    orderStatus = 'paymentCompleted';
                    //     const status = await this.storeOrders.setOrderStatus(orderId, orderStatus);
                    // } 
                    this.setState({
                        orderId: orderId
                    })

                    const fb = await this.storeOrders.sendOrderConfirmation(orderId, orderStatus);
                }
            }

            componentDidUpdate = () => {

            }

            getUrlParams = (searchString) => {
                const params = queryString.parse(searchString)
                let orderId;

                if (params.id) {
                    orderId = params.id;
                }

                return orderId;
            }

            backToStore = () => {
                const url = "/home";
                this.props.history.push(url);
            }

            render() {
                return (
                    <TemplateCart
                    
                    >
                        <div className="page-content page-content--review">
                            <div className="section--form">
                                <div 
                                    className="wrapper--section section--header"
                                >
                                    <h2>
                                        <span>Bedankt voor je bestelling!</span>
                                    </h2>
                                </div>
                                <div>
                                    <PaymentReturnOrderData
                                        orderId = {this.state.orderId}
                                    />
                                    <p>We gaan voor je aan de slag om de bestelling zo snel mogelijk bij je thuis te bezorgen. </p>
                                    <p>Als beginnende webshop hopen op je begrip als het nog niet helemaal vlekkeloos verloopt.<br/>
                                    Je kunt echter altijd contact met ons opnemen en dan zullen we een eventueel probleem zo snel en zo goed mogelijk oplossen zodat jij en je hond tevreden zijn en blijven!</p>
                                </div>

                                <div 
                                    className="section__columns"
                                >
                                    <div 
                                        className="section__column section__column--single"
                                    >
                                        <div className="form-row form-row--buttons --single --focus"></div>
                                            <div 
                                                className="form-row__button --active button--forward"
                                                onClick={() => this.backToStore()}
                                            >
                                                <div 
                                                    className="button button--primary"
                                                    
                                                >
                                                    <span className="button--arrow --right" />
                                                </div>
                                                <div 
                                                    className="button-text --link"
                                                >
                                                    Verder met winkelen
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </TemplateCart>
                );
            }

        }
    )
)
                    
export default PaymentReturn;
