import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";

const BrandProductSummary = inject("stores") (
    observer (
        class BrandProductSummary extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;
        
        this.storeAuth = this.props.stores.storeAuth;
        this.storeUi = this.props.stores.storeUi;
        this.storeCarts = this.props.stores.storeCarts;
        this.storeOrders = this.props.stores.storeOrders;

        this.state = {

        }
    }

    componentDidMount = () => {

    }

    handleClick = (url) => {

    }

    handleAddProduct = async() => {
        const sku = this.props.sku;

        if (this.storeAuth.isLoggedin) {
            const productAdded = await this.storeCarts.addToCart('account', this.storeAuth.cartId, this.props.product, 1)
            //this.storeCarts.getCart('account', this.storeAuth.user.userId);
        } else {
            const productAdded = await this.storeCarts.addToCart('guest', this.storeAuth.cartId, this.props.product, 1)
            //this.storeCarts.getCart('user', this.storeAuth.cartId);
            //this.storeCarts.getCartAmount(this.storeAuth.user.userId);
        }
    }


    render() {
        return (
            <React.Fragment>     
                <div 
                    className="wrapper--product-summary" 
                >
                    <div className="product-summary__columns">
                        <div className="product-summary__column product-summary__column--keydata">
                            <span className="product-summary__title">{this.props.product.displayValue}</span>
                            <span className="product-summary__subtitle">{this.props.product.subTitle}</span>
                        </div>
                        <div className="product-summary__column product-summary__column--image">
                            <div 
                                className="product-summary__container-image"
                            >
                                <img src={this.urlAPI + "public/images/shop/products/" + this.props.product.ean + ".png"} />
                            </div>
                        </div>
                        <div
                            className="product-summary__column"
                        >
                            <span className="product-summary__description">
                                <span>{this.props.product.description}</span>
                            </span>
                        </div>
                    </div>
                    <div className="product-summary__columns">
                        <div className="product-summary__column product-summary__column--specs">
                            <span 
                                className="product-summary__spec product-summary__spec--icon"
                                style= {{backgroundImage:`url(${this.urlAPI + "public/images/shop/icons/ic-type-" + this.props.product.typeFood + ".png"})` }}
                            >
                                <span>
                                    {i18n.t("type_food." + this.props.product.typeFood)}
                                </span>
                            </span>
                            <span className="product-summary__spec product-summary__spec--contents">
                                <span>
                                    <span>
                                        {this.props.product.contents}
                                    </span>
                                    <span
                                        className="spec--unit"
                                    >
                                        {this.props.product.unit}
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="product-summary__column product-summary__column--shopdata">
                            <span className="product-summary__price">{this.props.product.pricePurchase}</span>
                        </div>

                        <div className="product__action-buttons">
                            <button
                                className = "btn btn--primary --link"
                                onClick = {() => this.handleAddProduct()}
                            >
                                Voeg toe aan bestelling
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
  }
}

    )
)

export default BrandProductSummary;
