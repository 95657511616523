import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
} from 'react-share';

import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
} from 'react-share';

const BlockSocial = inject("stores") (
    observer (
        class BlockSocial extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    item: '',
                    direct_url: '',
                    urlFacebook: "https://www.facebook.com/Ik-Shasha-109505681642209"
                }
            }

            componentDidUpdate(prevProps) {
                if (prevProps.item !== this.props.item) {
                    this.setState({
                        item: this.props.item
                    })
                }
            }

            handleSocialTooltip = (social) => {
                this.props.onMouseEnter(social);
            }

            render() {
                return (
                    <div 
                        className="section-wrapper section-wrapper--social --secundary"
                    >
                        <div 
                            className="section__columns"
                        >
                            <div 
                                className="section__column section__column--single"
                            >
                                <div className="section__header">
                                    <h2>
                                        <span>Help ons andere honden blij te maken</span>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div 
                            className="section__columns"
                        >
                            <div 
                                className="section__column section__column--single"
                            >      
                                <span className="section__text">We zouden het fijn vinden als je dit deelt op</span>
                    
                                <div className="container--social__icons">
                                    <div className="container--social__icon"> 
                                        <FacebookShareButton 
                                            url = {this.state.urlFacebook}
                                        >
                                            <FacebookIcon 
                                                size = {'2rem'} 
                                                round = {true}
                                                bgStyle = {
                                                    {fill: '#AAA'}
                                                }
                                            />
                                        </FacebookShareButton>
                                    </div>
                                    
                                    {/* <div className="container--social__icon"> 
                                        <LinkedinShareButton 
                                            url = {this.state.direct_url}
                                        >
                                            <LinkedinIcon size={'1.75rem'} round={true}/>
                                        </LinkedinShareButton>
                                    </div> */}
                                    
                                    {/* <div className="container--social__icon"> 
                                        <TwitterShareButton 
                                            url = {this.state.direct_url}
                                        >
                                            <TwitterIcon 
                                                size = {'2rem'} 
                                                round = {true}
                                                bgStyle = {
                                                    {fill: '#AAA'}
                                                }
                                            />
                                        </TwitterShareButton>
                                    </div> */}
                                    
                                    {/* <div className="container--social__icon"> 
                                        <WhatsappShareButton 
                                            url = {this.state.direct_url}
                                        >
                                            <WhatsappIcon size={'1.75rem'} round={true}/>
                                        </WhatsappShareButton>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
)

export default BlockSocial;
