import React from 'react';

const RadioMethod = props => {

    let formControl = "form-control";
    let direction = "--" + props.direction;
    let urlAPI = process.env.REACT_APP_URL_API;

    return (
        <div className={"form-group form-group--radio " + direction}>
            {props.options.map(option => (
                <div className="form-group__option" key={option.value}>
                    <input type="radio"
                        name = {props.name}
                        value = {option.id}
                        onChange = {props.onChange}
                        className = {formControl}
                        defaultChecked = {props.defaultValue === option.id ? 'defaultChecked' : null}
                    />
                    <img src={urlAPI + "public/images/payments/methods/"+ option.id + ".png"} />
                    <span>{option.displayValue}</span> 
                </div>
            ))}
        </div>
    );
}

export default RadioMethod;