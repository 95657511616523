import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import BlockReview from '../../components/blocks/reviews/block-review.js';
import ProductSummaryReview from './product-summary-review';

const ProductSummaryStats = inject("stores") (
    observer (
        class ProductSummaryStats extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeShop = this.props.stores.storeShop;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                    product: {},
                    price: {}
                }; 
            }

            componentDidMount = async() => {
                const sku = this.props.product.ean;
                const productData = await this.storeProducts.getProductData(sku);
                const productPrice = await this.storeProducts.getProductPrice(sku);

                this.setState({
                    product: productData,
                    price: productPrice
                })

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.ean !== this.props.ean) {
                    const sku  = this.props.ean;
                    const productData = await this.storeProducts.getProductData(sku);
                    const productPrice = await this.storeProducts.getProductPrice(sku);

                    this.setState({
                        product: productData,
                        price: productPrice
                    })
                }
            }

            render() {
                return (
                    <React.Fragment>     
                        <div 
                            className="product-summary__product-stats"
                        >
                            <div className={"product-summary__product-stat --" + this.props.product.typeFood}>
                                <div
                                    className = "column-keydata__icon"
                                >
                                    <img src={this.urlAPI + "public/images/shop/icons/ic-" + this.props.product.typeFood + ".png"} />
                                </div>
                            </div>
                            <div className="product-summary__product-stat">
                                <div
                                    className = "column-keydata__contents"
                                >
                                    <span
                                        className = "column-keydata__contents--amount"
                                    >
                                        {this.state.product.contents}
                                    </span>
                                    <span
                                        className = "column-keydata__contents--unit"
                                    >
                                        {this.state.product.unit}
                                    </span>
                                </div>
                            </div>
                        </div>  
                    </React.Fragment>
                );
            }
        }

    )
)

export default ProductSummaryStats;
