import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const ShopProductSummary = inject("stores") (
    observer (
        class ShopProductSummary extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;
        this.storeUi = this.props.stores.storeUi;
        this.storeShop = this.props.stores.storeShop;
        this.storeAffiliates = this.props.stores.storeAffiliates;

        this.state = {
            categories: [
                'organimal'
            ]
        }
    }

    componentDidMount = () => {

    }

    handleClick = (url) => {
        this.storeUi.openDialogContent();
    }

    render() {
        return (
            <React.Fragment>     
                <div 
                    className="wrapper--product-summary --link" 
                    onClick={() => this.handleClick(this.props.product.url)}
                >
                    <div className="product-summary__columns">
                        <div className="product-summary__column product-summary__column--keydata">
                            <span className="product-summary__title">{this.props.product.name}</span>
                            <span className="product-summary__subtitle">{this.props.product.keyIntro}</span>
                            <span className="product-summary__affiliate">via: {this.props.affiliate}</span>
                        </div>
                        <div className="product-summary__column product-summary__column--image">
                            <div 
                                className="product-summary__container-image"
                            >
                                <img src={this.urlAPI + "public/images/shop/products/" + this.props.affiliate.toLowerCase() + "/" + this.props.section.toLowerCase() + "/" + this.props.product.img} />

                                <div className="form-row form-row--buttons --inline">
                                    <div className="form-row__button button--primary">
                                        <span className="button--arrow --right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-summary__column product-summary__column--shopdata">
                            <span className="product-summary__description">
                                <span>{this.props.product.description}</span>
                            </span>
                            <span className="product-summary__pricesub">vanaf:</span>
                            <span className="product-summary__price">{this.props.product.price}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
  }
}

    )
)

export default ShopProductSummary;
