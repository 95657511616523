import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import * as utils from '../../communicator/shop/shop';
import * as account from '../../communicator/shop/account';
import TemplateCart from '../../templates/template-cart';

const PaymentReturnOrderData = inject("stores") (
    observer (
        class PaymentReturnOrderData extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.state = {
                    orderId: 0
                }
            }

            componentDidMount = async() => {
                this.setState({
                    orderId: orderId
                })
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.orderId !== this.props.orderId) {
                    this.setState({
                        orderId: this.props.orderId
                    })
                }
            }

            render() {
                return (
                    <div>
                        <p>Je ordernummer is: {this.state.orderId}</p>
                    </div>   
                );
            }

        }
    )
)
                    
export default PaymentReturnOrderData;
