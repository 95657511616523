import { makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as payments from '../communicator/shop/payments';

class StorePayments {
    
    listMethods = []
    listIssuers = []
    paymentUrl = '';
    issuerCode = '0721'

    constructor() {
        makeObservable(this, {
            issuerCode: observable
        })
    }

    async getPaymentMethods() {
        let listMethods;

        try {
            const returnData = await payments.getPaymentMethods();
            runInAction(() => {
                this.listMethods = returnData.data;
                listMethods = returnData.data;
                console.log('SbC methods:', this.listMethods)
                return listMethods;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }

        return listMethods;
    }

    async getPaymentIssuers() {
        let listIssuers;

        try {
            const returnData = await payments.getPaymentIssuers();
            runInAction(() => {
                this.listIssuers = returnData.data;
                listIssuers = returnData.data;
                console.log('SbC issuers:', this.listIssuers)
                return listIssuers;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }

        return listIssuers;
    }

    setIssuerCode(code) {
        this.issuerCode = code;
    }

    async getPaymentUrl(orderId, issuerCode) {
        let url;

        const data = {
            method: 'IDEAL',
            issuer: issuerCode
        }

        console.log('SbC data', orderId, data)

        try {
            const returnData = await payments.getPaymentUrl(orderId, data);
            runInAction(() => {
                this.paymentUrl = returnData.data;
                url = returnData.data;
                return url;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }

        return url;
    }

    

}

export default StorePayments;