import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Video from '../../../elements/video';

const BlockVideo = inject("stores") (
    observer (
        class BlockVideo extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    active_video_location: 'list',
                    active_video_playing: false
                }
            }

            componentDidMount = () => {
                this.setState({
                    video_playing: this.props.video_playing
                })
            }

            componentDidUpdate(prevProps) {
                if (prevProps.video_playing !== this.props.video_playing) {
                    this.setState({
                        video_playing: this.props.video_playing
                    })
                }            
            }

            handleCC = () => {

            }

            play() {
                this.player.play();
            }
            
            pause() {
                this.player.pause();
            }
            
            load() {
                this.player.load();
            }
            
            changeCurrentTime(seconds) {
                return () => {
                  const { player } = this.player.getState();
                  this.player.seek(player.currentTime + seconds);
                };
            }
            
            handleVideoState = (state) => {
                this.setState({
                    player: state,
                    video_playing: state.hasStarted ? true : false
                })
            }

            handleVideoPlaying = (playing) => {
                this.setState({
                    active_video_playing: playing,
                    video_playing: playing
                })

                this.props.handleVideoPlaying();
            }

            handleVideoCC = (cc) => {               
                this.setState({
                    active_video_cc: cc
                })
                this.storeUi.setActiveVideoCC(cc);
            }

            render() {
                return (
                    <div className={"block--video" + " --" + this.props.orientation}>
                        <div className="content-segment__content">
                            <div className="block--video__player">
                                <Video
                                    location = 'list'
                                    idx = {this.props.idx}
                                    video = {this.props.video}
                                    active_video = {this.storeUi.active_video}
                                    active_video_location = {this.state.active_video_location}
                                    active_video_playing = {this.state.active_video_playing}
                                    active_video_cc = {this.state.active_video_cc}
                                    current_state =  {this.state.player}
                                    handleVideoState = {this.handleVideoState}
                                    handleVideoPlaying = {this.handleVideoPlaying}
                                    handleVideoCC = {this.handleVideoCC}
                                    video_playing = {this.state.video_playing}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockVideo);
