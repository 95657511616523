import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import validate from '../../utilities/validation-rules';

import RowText from '../form-rows/row-text';

import Checkbox from '../../elements/form-input-checkbox';
import Select from '../../elements/form-input-select';

const FormPet = inject("stores") (
    observer (
        class FormPet extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    formIsValid: false,
                    formControls: { 
                        petName: {
                            type: "text",
                            value: this.props.pet.name,
                            validationRules: {
                                
                            },
                            valid: true
                        }
                    }
                }
            }

            componentDidMount = async() => {
                let userId;
            } 

            componentDidUpdate = async(prevProps) => {
                if (prevProps.pet.id !== this.props.pet.id) {
                    this.initRow ('petName', this.props.pet.name)
                }
            }

            addPetFromDb = (line) => {
                const updatedControls = {
                    ...this.state.formControls
                };

                let updatedFormElement = {
                    ...updatedControls['pets']
                };
                
                let pet = [{
                    petName: {
                        value: line.category,
                        placeholder: i18n.t("forms.placeholder.petname"),
                        validationRules: {
                            
                        }
                    }
                }];

                let arr = [];
                  
                Object.keys(updatedFormElement).map((key, i) => {
                    arr.push(updatedFormElement[key]);
                })
                updatedFormElement = arr.concat(pet);
                updatedControls['pets'] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }          

            initForm = async() => {
                let name = '';
                let value = '';

                name = 'petName';
                value =  this.state.pet.name;
                await this.initRow(name, value);
            }

            initRow = async(name, value) => {
                const valid = true;
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                })
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: 
                    formIsValid ? '--active' : '--disabled'
                }, () => this.savePet());
            }

            savePet = () => {
                const data = this.state.formControls;
                const petId = this.props.pet.id;
                const cartId = this.storeAuth.getCartId();
                
                this.storeCarts.saveCartPet(cartId, petId, data);

                this.props.getCartPets();
            }

            handleDelete = async() => {
                const petId = this.props.pet.id;
                const pet = await this.storeCarts.deleteCartPet(petId);

                this.props.getCartPets();
            }

            render() {
                return (
                    <form className="form form-row--pet">
                        {Object.keys(this.state.formControls).map((key, i) => (
                            <RowText 
                                type = {this.state.formControls[key].type}
                                name = {key}
                                value = {this.state.formControls[key].value}
                                validationRules = {this.state.formControls[key].validationRules}
                                validationMsg = {this.state.formControls[key].validationMsg}
                                onValidate = {this.validateRow}
                                disable = {this.state.formControls[key].disable}
                            />
                        ))}
                        <span 
                            className="icon icon--delete --link"
                            onClick = {() => this.handleDelete()}
                        />
                    </form>
                )
            }
        }
    )
)

export default withTranslation()(FormPet);
