import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { Redirect } from 'react-router';

import RadioMethod from '../../elements/form-input-radio-method';
import RadioIssuer from '../../elements/form-input-radio-issuer';

const CartPayment = inject("stores") (
    observer (
        class CartPayment extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                // this.storeUser = this.props.stores.storeUser;
                // this.storeOrder = this.props.stores.storeOrder;
                this.storePayments = this.props.stores.storePayments;

                this.state = {
                    btn_submit: "active",
                    redirect: false,
                    redirect_complete: false,
                    methods: [],
                    issuers: [],

                    formControls: { 
                        payment_method: {
                            value: this.props.payment_method_id,
                            placeholder: 'Select your payment method',
                            valid: false,
                            touched: false,
                            options: this.storePayments.listMethods
                        },
                        payment_issuer: {
                            value: '',
                            placeholder: 'Select your bank',
                            valid: false,
                            touched: false,
                            options: this.storePayments.listIssuers
                        }
                    },
                    error: false
                 
                }


            }

            async componentDidMount() {
                let methods;
                let issuers;

                if (this.storePayments.listMethods.length === 0) {
                    methods = await this.storePayments.getPaymentMethods();
                }
                if (this.storePayments.listIssuers.length === 0) {
                    issuers = await this.storePayments.getPaymentIssuers();
                }
                this.setState({
                    methods: methods,
                    issuers: issuers
                })

                console.log('SbC methods', this.storePayments.listMethods)
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            sendConfirmationOrder = () => {

            }

            setCartView = (view) => {
                this.storeOrder.setCartView(view);
                this.handleScroll();
            }

            startPayment = async (payment_id, issuer_code) => {
                const url = await this.storePayments.getPaymentUrl('100001');
                console.log('SbC link', url)
                this.setState({
                    redirect: true
                })
            }

            completePayment = () => {
                
            }

            gotoCartSection = (section) => {
                this.storeUi.setCartSection(section);
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/payment"} 
                    />;
                }

                return (
                    <React.Fragment>
                        <div className="container-cart__content">
                            <div className="form-wrapper">
                                <div className="form-fieldset">
                                    <form className="form --full-width">
                                        <div className="form-row form-row--input">
                                            <div className="container-cart__header">
                                                <h1 className="">
                                                    {i18n.t("cart.header.payment_method")}
                                                </h1>
                                            </div> 
                                            <RadioMethod 
                                                name = "payment_method" 
                                                placeholder = {this.state.formControls.payment_method.placeholder}
                                                value = {this.state.formControls.payment_method.value}
                                                options = {this.storePayments.listMethods}
                                                direction = {"vertical"}
                                                onChange = {this.handleChange}
                                                defaultValue = {this.props.payment_method_id}
                                            />
                                        </div>

                                        {/* {(this.state.formControls.payment_method.value === 'IDEAL') && */}
                                        <React.Fragment>
                                            <div className="form-row form-row--input">
                                                <div className="container-cart__header">
                                                    <h1 className="">
                                                        {i18n.t("cart.header.payment_issuer")}
                                                    </h1>
                                                </div> 
                                                <RadioIssuer 
                                                    name = "payment_issuer" 
                                                    placeholder = {this.state.formControls.payment_issuer.placeholder}
                                                    //value = {this.state.formControls.payment_issuer.value}
                                                    //options = {this.state.formControls.payment_issuer.options}
                                                    options = {this.storePayments.listIssuers}
                                                    direction = {"horizontal"}
                                                    onChange = {this.handleChange}
                                                    //defaultValue = {this.state.formControls.payment_issuer.defaultValue}
                                                    //selected = {this.storeOrder.order_data.payment.payment_issuer.code}
                                                />
                                            </div>
                                        </React.Fragment>
                                        {/* } */}

                                        {this.state.error &&
                                            <div className="form-row form-row--error">
                                                <p>Er is een fout opgetreden. Controleer je invoergegevens.</p>
                                            </div>
                                        }
                                    </form>
                                </div>
                            
                            </div>
                        </div>

                        <div className="container-cart__content">
                            <div 
                                className="form-row form-row--buttons --dialog"
                                
                            >
                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.gotoCartSection('review')}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.goback_review")}
                                    </div>
                                    <div className="form-row__button button--secundary">
                                        <span className="button--arrow --left"></span>
                                    </div>
                                </div>

                                <div 
                                    className="form-action__button --link"
                                    onClick={() => this.startPayment()}
                                >
                                    <div className="sform-action form-action__button-text">
                                        {i18n.t("button.goto_msp")}
                                    </div>
                                    <div className="form-row__button button--primary">
                                        <span className="button--arrow --right"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(CartPayment);
