import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import i18n from "i18next";
import CacheBuster from 'react-cache-buster';
import GA4React from "ga-4-react";
import './assets/css/ikshasha.css';
import packageJson from '../package.json';
import {
    isBrowser,
    isMobile
} from "react-device-detect";

import Routes from './routes';
import DialogAffiliate from './components/dialogs/dialog-affiliate';

const ga4react = new GA4React("G-FM6EJK9CG2");

const App = inject("stores") (
    observer (
        class App extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.isProduction = process.env.REACT_APP_ENV === 'prod';

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeShop = this.props.stores.storeShop;
                this.storeAffiliates = this.props.stores.storeAffiliates;

                //ga4react.initialize().then().catch()
            }

            gotoUrl = () => {
                const url = this.storeShop.affProductUrl;
                window.open(url);
            }

            render() {
                return (
                    <React.Fragment>
                        <CacheBuster
                            currentVersion={packageJson.version}
                            isEnabled={this.isProduction} //If false, the library is disabled.
                            isVerboseMode={false} //If true, the library writes verbose logs to console.
                            // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
                            metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
                        >
                        <div className={"app-content " + (isMobile ? "app-content--mobile" : "app-content--desktop")}>
                            <Routes />
                        </div>

                        {this.storeUi.app_interface.isDialogContentOpen &&
                            <DialogAffiliate
                                show = {this.storeUi.app_interface.isDialogContentOpen}
                                onClose = {() => this.storeUi.closeDialogContent()}
                                title = {i18n.t("popups.added.title")}
                                subtitle = "Het aantal kun je aanpassen in je winkelmandje."
                                buttons = {
                                    [{
                                        text: "Annuleer",
                                        onClick: () => this.storeUi.closeDialogContent(),
                                        btnClass: "--left"
                                    },{
                                        text: "Ga naar Krachtkruid.nl",
                                        onClick: () => this.gotoUrl(),
                                        btnClass: "--right"
                                    }]
                                }
                            >
                            </DialogAffiliate>
                        }
                        </CacheBuster>
                    </React.Fragment>
                );
            }
        }
    )
)

export default App;
