import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Template from '../../templates/template.js';

export default class ProjectShelter extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    render() {
        return (
            <Template
            segment = "shelter"
            id = "dogs"
            story = "shelter"
            >
                <div className="page-project">
                    <span><Link to='/'>Back</Link></span>
                       
                    <div className="page-content">
                        <div className="segment-wrapper">
                            <div className="project__quote">
                                It can be hard, but is doesn't have to be. Support your best friend and help another
                            </div>
                        </div>

                        <div className="segment-wrapper">
                            <div className="segment-wrapper__column segment-wrapper__column--intro">
                                <h2>
                                    <span>
                                        Project Shelter
                                    </span>
                                </h2>
                                <p>
                                    <span>Tries to improve the lives of stray dogs around the world. 
        First initiative is to provide kennels to stray dogs in South Africa. By working with local carpenters, it helps them in generating sufficient income too.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
       
                </div>
            </Template>
        );
    }
}
