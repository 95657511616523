import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default class Colofon extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    render() {
        return (
            <div className="page-product">
                <span><Link to='/shop/home'>Back</Link></span>
                <h2>COLOFON</h2>
                <span>Produced by Seduced by Cereza</span>
            </div>
        );
    }
}
