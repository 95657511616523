import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import validate from '../../utilities/validation-rules';

import RowText from '../form-rows/row-text';

import Checkbox from '../../elements/form-input-checkbox';
import Select from '../../elements/form-input-select';

const FormProfile = inject("stores") (
    observer (
        class FormProfile extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    error_email: false,
                    error_email_empty: false,
                    formIsValid: false,
                    formControls: { 
                        invoiceFirstname: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceMiddlename: {
                            type: "text",
                            value: '',
                            validationRules: {
                            },
                            valid: true
                        },
                        invoiceLastname: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceEmail: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            valid: false
                        }
                    }
                }
            }

            componentDidMount = async() => {
                const cartId = this.storeAuth.getCartId();
                const cartUser = await this.storeCarts.getCartUser(cartId);

                if (cartUser) {
                    this.initForm(cartUser[0]);
                }
            } 

            componentDidUpdate = async(prevProps) => {

            }

            initForm = async(user) => {
                let name = '';
                let value = '';

                name = 'invoiceFirstname';
                value =  user[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }

                name = 'invoiceMiddlename';
                value =  user[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }

                name = 'invoiceLastname';
                value =  user[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }

                name = 'invoiceEmail';
                value =  user[name];
                if (value !== null) {
                    await this.initRow(name, value);
                }
            }

            initRow = async(name, value) => {
                const valid = true;
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);
                this.setState({
                    formControls: updatedControls
                })
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);
                this.props.handleBtnProfile(formIsValid);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }, () => this.saveCartUser());
            }

            saveCartUser = () => {
                const data = this.state.formControls;
                const cartId = this.storeAuth.getCartId();
                this.storeCarts.saveCartUser(cartId, data);
            }

            render() {
                return (
                    <form className="form">
                        {Object.keys(this.state.formControls).map((key, i) => (
                            <React.Fragment>
                                {(key !== "country_select" && 
                                  key !== "has_subscription") &&                               
                                    <React.Fragment>
                                        {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                            <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                validationMsg = {this.state.formControls[key].validationMsg}
                                                onValidate = {this.validateRow}
                                                disable = {this.state.formControls[key].disable}
                                            />
                                        }
                                    </React.Fragment>
                                } 
                            </React.Fragment>
                        ))}
                    </form>
                )
            }
        }
    )
)

export default withTranslation()(FormProfile);
