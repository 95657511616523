import { observable, computed, action, decorate } from "mobx";
import { runInAction } from "mobx";

import * as settings from '../communicator/settings';

class StoreGeneral {

    dogNames = [
        'Baloo',
        'Lizzy',
        'Saartje',
        'Nero',
        'Mike',
        'Baco'
    ]
    
    constructor() {
        this.getSettings();
    }
    
    getSettings = async() => {
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    const settings = returnData.data;
                    settings.map(setting => {
                        this.listSettings[setting.settingName] = setting.settingValue;
                    })
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
}

export default StoreGeneral;