import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const BlockSnackpoints = inject("stores") (
    observer (
        class BlockSnackpoints extends Component {

            constructor(props) {
                super();
                this.props = props;

                this.state = {
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeProducts = this.props.stores.storeProducts;
            }

            componentDidMount = async() => {
            }

            render() {
                return (
                    <React.Fragment>
                        <div 
                            className="section--snackpoints"
                        >
                            <h1>* Wat zijn snackpunten?</h1>
                            <p>Bij iedere aankoop verzamel je (gedeeltelijke) snackpunt. ! snackpunt staat voor 1 snackpack dat uitgedeeld wordt aan een van de projecten die we ondersteunen</p>
                            <p>Momenteel worden de snackpacks uitgedeeld aan asielhonden in Nederland.</p>
                        </div>
                        
                    </React.Fragment>
                );
            }
        }

    )
)

export default withRouter(BlockSnackpoints);
