import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default class Projects extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    render() {
        return (
            <div className="page-product">
                <span><Link to='/'>Back</Link></span>
                <h2>PROJECTS I AM WORKING ON</h2>
                <span><Link to='/project-shelter'>Project Shelter</Link></span>
                <span><Link to='/project-street'>Project Street</Link></span>
                <span>Produced by Seduced by Cereza</span>
            </div>
        );
    }
}
