import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

const CartReviewAddress = inject("stores") (
    observer (
        class CartReviewAddress extends Component {

            constructor(props) {
                super();
                this.props = props;
                this.state = {
                    address: [{
                        name: 'street',
                        value: 'xx'
                    },{
                        name: 'housenr',
                        value: '316'
                    },{
                        name: 'zipcode',
                        value: '3701 FX'
                    },{
                        name: 'city',
                        value: 'Zeist'
                    },{
                        name: 'country',
                        value: 'NL'
                    }]
                };

                this.storeOrders = this.props.stores.storeOrders;

            }

            componentDidMount = () => {
                //if (this.props.stores.storeUi.app_interface.checkout_flow === 'use_account') {
                    //this.getOrderAddress();

                    //SbC if length = 0 -> show input forms

                    //SbC if length == 1 show address

                    //SBC if length > 1 show select address
                //}
            }

            getOrderAddress = async() => {
                // const orderId = 1001;
                // const address = await this.storeOrders.getOrderAddress(orderId);

                // this.setState({
                //     address: address
                // }, () => console.log('Sbc address', address))
            }

            gotoCartSection = (section) => {
                if (this.props.stores.storeUi.app_interface.checkout_flow === 'guest') {
                    //SbC:: create guest user
                }
                this.props.stores.storeUi.setCartSection(section);
            }

            selectAddress = (id) => {
                this.setState({
                    payment_address_id: id,
                    shipping_address_id: id 
                })
            }

            render() {
                return (
                    <React.Fragment>
                        <form>
                            <div className="container-cart__content">
                                <div className="container-cart__address container-cart__address--payment">
                                    {/* {this.props.type == 'billing' &&
                                        <h3>Billing address</h3>
                                    }
                                    {this.props.type == 'shipping' &&
                                        <h3>Shipping address</h3>
                                    }
    */}
                                    <dl>
                                        {this.state.address.map((address) => (
                                            <React.Fragment>
                                                <dt>
                                                    {address.name}
                                                </dt>
                                                <dd>
                                                    {address.value}
                                                </dd>
                                            </React.Fragment>
                                        ))}
                                    </dl>
                                </div>
                                      

                                <div className="container-cart__address container-cart__address--shipment">
                                    <div className="cart-row cart-row--address --dev">
                                        <label>
                                            First name
                                        </label>
                                        
                                    </div>

                                    <div className="form-row --dev">
                                        <label>
                                            Street
                                        </label>
                                       
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                );
            }
        }
    )
)
                    
export default CartReviewAddress;

