import * as apifetch from '../fetch.js';

export async function addToCartOC(product_id, auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocAddToCart.php';

    let postData = {
        product_id: product_id
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn =  responseJson;

            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function getCartOC(auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocCartOverview.php';

    try {
        let response = await fetch(urlEndpoint, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn =  responseJson;

            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function getCartProducts(userId) {
    const urlEndpoint = 'carts/' + userId;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function updateCartProduct(userId, sku, amount) {
    const urlEndpoint = 'carts/' + userId;

    const postData = {
        sku: sku,
        amount: amount
    }

    return apifetch.ikFetch(urlEndpoint, 'PUT', postData);
}

export async function createNewCart() {
    const urlEndpoint = 'carts/new';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getCart(id) {
    const urlEndpoint = 'carts/' + id;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function addToCart(id, product) {
    const urlEndpoint = 'carts/' + id + '/add';

    const postData = product;

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function updateCart(id, product) {
    const urlEndpoint = 'carts/' + id + '/update';

    const postData = product;

    return apifetch.ikFetch(urlEndpoint, 'PUT', postData);
}

export async function deleteFromCart(id, product) {
    const urlEndpoint = 'carts/' + id + '/delete';

    const postData = product;

    return apifetch.ikFetch(urlEndpoint, 'DELETE', postData);
}

export async function getShipping(country) {
    const urlEndpoint = 'shipping/' + country;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function saveShipping(cartId, shipping) {
    const urlEndpoint = 'carts/' + cartId + '/shipping';

    const postData = {
        shipping: shipping
    };

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function saveVAT(cartId, vat) {
    const urlEndpoint = 'carts/' + cartId + '/vat';

    const postData = {
        vat: vat
    };

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function saveTotals(cartId, subtotal, total) {
    const urlEndpoint = 'carts/' + cartId + '/totals';

    const postData = {
        subtotal: subtotal,
        total: total
    };

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function saveCartAddress(cartId, data) {
    const urlEndpoint = 'carts/' + cartId + '/addresses'; 

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'PUT', postData);
}

export async function saveCartUser(cartId, data) {
    const urlEndpoint = 'carts/' + cartId + '/user'; 

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'PUT', postData);
}

export async function getCartAddresses(cartId) {
    const urlEndpoint = 'carts/' + cartId + '/addresses'; 

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getCartUser(cartId) {
    const urlEndpoint = 'carts/' + cartId + '/user'; 

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getCartPets(cartId) {
    const urlEndpoint = 'carts/' + cartId + '/pets'; 

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function saveCartPet(cartId, data) {
    const urlEndpoint = 'carts/' + cartId + '/pet';

    const postData = data;
    console.log('SbC cart pet', postData)

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}

export async function deleteCartPet(petId) {
    const urlEndpoint = 'carts/' + petId + '/pet';

    const postData = {};

    return apifetch.ikFetch(urlEndpoint, 'DELETE', postData);
}
