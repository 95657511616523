import * as apifetch from '../fetch.js';

export async function getPaymentMethods() {
    const urlEndpoint = 'payment/methods';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getPaymentIssuers() {
    const urlEndpoint = 'payment/issuers';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getPaymentUrl(orderId, data) {
    const urlEndpoint = 'payment/' + orderId + '/paymentlink';

    const postData = data;

    return apifetch.ikFetch(urlEndpoint, 'POST', postData);
}