import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import i18n from "i18next";

import TemplateCart from '../../templates/template-cart';

import FormProfile from '../../components/forms/form-profile'
import FormAddress from '../../components/forms/form-address'
import CartItems from '../../components/cart/cart-items';
import FormPets from '../../components/forms/form-pets';

const CartReview = inject("stores") (
    observer (
        class CartReview extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeContent = this.props.stores.storeContent;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    btnProfile: false,
                    btnAddress: false
                }
            }

            componentDidMount = async () => {
                window.scroll(0,0);
                this.getCart();

                // let idx = 43;
                // const contentOrdering = await this.storeContent.getContentBlock('pageblocks', idx);
                // idx = 41;
                // const contentShipping = await this.storeContent.getContentBlock('pageblocks', idx);
                
                // this.setState({
                //     contentOrdering: contentOrdering,
                //     contentShipping: contentShipping
                // })
            }

            componentDidUpdate = async (prevProps) => {
            }

            getCart = async() => {
                const id = this.storeAuth.getCartId();
                const cart = await this.storeCarts.getCart(id);
               
                this.setState({
                    cartId: id,
                    cart: cart
                })
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            handleBtnAddress = (value) => {
                this.setState({
                    btnAddress: value
                })
            }

            handleBtnProfile = (value) => {
                this.setState({
                    btnProfile: value
                })
            }

            submitOrder = () => {
                // let url;
                // const result = this.storeOrders.submitOrder(this.state.cartId);

                // if (result === 'success') {
                //     url = '/payment-result';
                //     this.props.navigate(url);
                // }
               const url = '/payment';
               this.props.history.push(url);
            }

            render() {
                return (
                    <TemplateCart
                        title = {"Uw gegevens & betaling"}
                    >
                        <div className="page-content page-content--cart">
                            <div className="section--form">
                                <div 
                                    className="wrapper--section section--header"
                                >
                                    <h2>
                                        <span>Vul je gegevens in</span>
                                    </h2>
                                </div>
                            </div>

                            <div className="section--form">
                                <div className="section--form__columns">
                                    <div className="section--form__column">
                                        <div className="content--form">
                                            <FormProfile
                                                handleBtnProfile = {this.handleBtnProfile}
                                            />
                                        </div>
                                    </div>
                                    <div className="section--form__column">
                                        <div className="content--form">
                                            <FormAddress
                                                handleBtnAddress = {this.handleBtnAddress}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(!this.state.btnProfile || !this.state.btnAddress) &&
                                 <div className="section--form">
                                    <div className="form-row form-row--advice">
                                        <p>Nog niet alle velden zijn correct ingevuld.</p>
                                    </div>
                                </div>
                            }
                                
                            <div className="section--form">
                                <div 
                                    className="wrapper--section section--header"
                                >
                                    <h2>
                                        <span>Jouw honden</span>
                                    </h2>
                                </div>
                                
                                <FormPets

                                />
                            </div>
   
                            <div className="section--form">
                                {this.state.contentOrdering && this.state.contentOrdering.title && (
                                    <div 
                                        className="content__header"
                                        dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.title.rendered}}
                                    />
                                    )
                                }
                                {this.state.contentOrdering && this.state.contentOrdering.content &&
                                    <div 
                                        className="content__text" 
                                        dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.content.rendered}}
                                    />
                                }
                          
                                {this.state.feedback &&
                                    <div 
                                        className="form-row form-row--feedback"
                                        dangerouslySetInnerHTML={{ __html: i18n.t("forms.feedback.success")}} 
                                    />
                                }
                            </div>

                            <div className="section--form">
                                <div 
                                    className="wrapper--section section--header"
                                >
                                    <h2>
                                        <span>Controleer je bestelling</span>
                                    </h2>
                                </div>

                                <div className="container-cart__content">
                                    <CartItems 
                                        cart = {this.state.cart}
                                        editable = {false}
                                    />
                                </div>
                            </div>

                            <div className="section--form">
                                {this.state.contentOrdering && this.state.contentOrdering.title && (
                                    <div 
                                        className="content__header"
                                        dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.title.rendered}}
                                    />
                                    )
                                }
                                {this.state.contentOrdering && this.state.contentOrdering.content &&
                                    <div 
                                        className="content__text" 
                                        dangerouslySetInnerHTML={{ __html: this.state.contentOrdering.content.rendered}}
                                    />
                                }

                                <div className="form-row form-row--buttons --block">        
                                    <div 
                                        className="form-row__button --active button--back"
                                        onClick={() => this.gotoPage('/shoppingcart')}
                                    >
                                        <div 
                                            className="button button--secundary"
                                        >
                                            <span className="button--arrow --left" />
                                        </div>
                                        <div 
                                            className="button-text --link"
                                        >
                                            Wijzig je bestelling
                                        </div>
                                    </div>

                                    {(this.state.btnProfile && this.state.btnAddress)
                                    ?   <div 
                                            className="form-row__button --active button--forward"
                                            onClick={() => this.submitOrder()}
                                        >
                                            <div 
                                                className="button button--primary"
                                                
                                            >
                                                <span className="button--arrow --right" />
                                            </div>
                                            <div 
                                                className="button-text --link"
                                            >
                                                Verstuur je bestelling
                                            </div>
                                        </div>
                                    :   <div 
                                            className="form-row__button --disabled button--forward"
                                        >
                                            <div 
                                                className="button button--primary"
                                                
                                            >
                                                <span className="button--arrow --right" />
                                            </div>
                                            <div 
                                                className="button-text"
                                            >
                                                Verstuur je bestelling
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="section--form">
                                <div className="form-row form-row--buttons --block">        
                                    <div 
                                        className="form-row__button --active button--back"
                                        onClick={() => this.gotoPage('/shop/products')}
                                    >
                                        <div 
                                            className="button button--secundary"
                                        >
                                            <span className="button--arrow --left" />
                                        </div>
                                        <div 
                                            className="button-text --link"
                                        >
                                            Terug naar producten
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TemplateCart>
                )
            }
        }
    )
)

export default CartReview;

