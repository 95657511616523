import React from 'react';
import i18n from "i18next";

function checkPaste(e, arr) {
    if (arr && arr.indexOf("paste") > -1) {
        e.preventDefault();
        return false;
    } else {
        return true;
    }
    
}

const TextInput = (props) => {
    let formClass = "form-field--text";

    if (props.className && props.className !== '') {
        formClass = formClass + ' ' + props.className;
    }

    if (props.touched && !props.valid && props.value !== '') {
        formClass = formClass + ' --error';
    }

    return (
        <div className="form-group">
            <input 
                {...props}
                type = {props.secret ? "password" : "text" }
                className = {formClass} 
                onPaste = {(e) => {
                    checkPaste(e, props.disable)
                 }} 
            />
            {props.is_secret && 
                <span className={"form-group__secret-switch " + (props.secret ? '' : '--active')} onClick={() => props.showSecret()}>
                    {props.secret ? 'Toon' : 'Verberg'}
                </span>
            }
            {props.touched && !props.valid && props.value !== '' && 
                <React.Fragment>
                    {props.msg
                    ?   <span className="form-field--error">{props.msg}</span>
                    :   <span className="form-field--error">{i18n.t("form_errors.general")}</span>
                    }
                </React.Fragment>
            }
        </div>
    );
}

export default TextInput;