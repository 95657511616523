import { makeObservable, observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as products from '../communicator/shop/products';

class StoreProducts {
    listProducts = []

    constructor() {
        makeObservable(this, {
            listProducts: observable
        })
    }

    async getShopProduct(sku) {
        let shopProduct = [];
        try {
            const returnData = await products.getShopProduct(sku);
            runInAction(() => {
                shopProduct = returnData.data;
                return shopProduct;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return shopProduct;
    }

    async getProductFilters() {
        let filters = [];
        try {
            const returnData = await products.getProductFilters();
            runInAction(() => {
                filters = returnData.data;
                return filters;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return filters;
    }

    // async getShopProducts(source, id) {
    //     let shopProducts = [];
    //     try {
    //         const returnData = await products.getShopProducts(source, id);
    //         runInAction(() => {
    //             shopProducts = returnData.data;
    //             return shopProducts;
    //         })
    //     } catch (error) {
    //         runInAction(() => {
    //             this.state = "error"
    //         })
    //     } finally {

    //     }
    //     return shopProducts;
    // }

    async getProducts(section, type) {
        let listProducts = [];
        try {
            const returnData = await products.getProducts(section, type);
            runInAction(() => {
                this.listProducts = returnData.data;
                return listProducts;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return listProducts;
    }

    async getProductData(sku) {
        let productData = [];
    
        try {
            const returnData = await products.getProductData(sku);
            runInAction(() => {
                productData = returnData.data[0];
                return productData;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return productData;
    }

    async getProductPrice(sku) {
        let productPrice = 0;
    
        try {
            const returnData = await products.getProductPrice(sku);
            runInAction(() => {
                productPrice = returnData.data[0];
                return productPrice;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return productPrice;
    }

    async getBrandProducts(brand) {
        let shopProducts = [];
        try {
            const returnData = await products.getBrandProducts(brand);
            runInAction(() => {
                shopProducts = returnData.data;
                return shopProducts;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return shopProducts;
    }

    async getSoonInShop() {
        let shopProducts = [];
        try {
            const returnData = await products.getSoonInShop();
            runInAction(() => {
                shopProducts = returnData.data;
                console.log('SbC soon', returnData.data)
                return shopProducts;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return shopProducts;
    }
}

export default StoreProducts;