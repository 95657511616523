import * as apifetch from './fetch.js';

export async function getReviews() {
    const urlEndpoint = 'content/reviews';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getFriends() {
    const urlEndpoint = 'content/friends';

    return apifetch.ikFetch(urlEndpoint, 'GET');
}
