import * as apifetch from '../fetch.js';

export async function getShopCategories(auth) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocCategories.php';

    try {
        let response = await fetch(urlEndpoint, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn =  responseJson;

            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function getShopProductsPerCategory(category_id, auth) {
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocProductsPerCategory.php';

    let postData = {
        category_id: category_id
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            }),
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn =  responseJson;

            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function getShopProductDetails(product_id, auth) {
    const urlAPI = 'https://www.cereza.nl/api/shop/oc';
    const urlEndpoint = urlAPI + '/ocProductDetails.php';

    let postData = {
        product_id: product_id
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                'Authorization': 'Bearer ' + auth,
                'Sku-Store': 'ADOGSLIFE'
            })
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiProductDetails =  responseJson;

            return apiProductDetails;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message);
    }
}

export async function getProductsRecommended(auth, product_group) {
    const urlEndpoint = '/content/getProductsRecommended.php';

    const postData = {
        product_group: product_group
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

