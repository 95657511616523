import React, { Component } from 'react';

class FooterOnline extends Component {

    constructor(props) {
        super();
        this.props = props;
        
        this.state = {

        };
    }

    componentDidMount() {
    }

    gotoUrl = (page) => {
        let url;

        url = "https://" + page;
        window.open(url, "_blank");
    }

    render() {
        return (
            <div className="footer-row footer-row--column">
                    <span className="footer__online">
                        <span className="online--email">info@cereza.nl</span>
                        <span 
                            className="online--url --link"
                            onClick={() => this.gotoUrl('www.cereza.nl')}>www.cereza.nl
                        </span>
                        <span 
                            className="onlin--social social--facebook --link"
                            onClick={() => this.gotoUrl('www.facebook.com/profile.php?id=100077757520874')}>Facebook
                        </span>
                        <span className="online--tel">06-24557657</span>
                    </span>
               
            </div>
        );
    }
}

export default FooterOnline;
