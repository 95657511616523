import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";

import TopBar from '../components/general/topbar';
import Footer from '../components/footer/footer';

const TemplateCart = inject("stores") (
    observer (
        class TemplateCart extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
            }

            componentDidMount() {

            }
   
            render() {

                return (
                    <React.Fragment>
                        <div className="topbar-container">
                            <div className="content-container">
                                <div className="content-container__content">
                                    <TopBar />
                                </div>
                            </div>
                        </div>

                        {/* <Branding 
                            segment = {this.props.segment} 
                            id = {this.props.id} 
                            story = {this.props.story}
                            dogName = {this.storeUser.dogName}
                        /> */}

                        {this.props.children}  

                        <Footer />
                     </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(TemplateCart));
