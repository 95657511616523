import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateDialog from '../../templates/template-dialog';
import ButtonDialog from '../../elements/button-dialog';

const DialogAffiliate = inject("stores") (
    observer (
        class DialogAffiliate extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeAffiliates = this.props.stores.storeAffiliates;
            }

            render() {
                return (
                    <TemplateDialog>
                        <div className="wrapper-content wrapper-content--affiliate">
                            <h2>Je gaat nu naar de site van {this.storeAffiliates.affiliate}</h2>
                            <p>Hier kun je dit product bestellen.</p>
                            <p>Voor iedere bestelling die je via onze site bij {this.storeAffiliates.affiliate} doet, ontvangen wij een vergoeding.<br/>
                            Daarmee zijn wij in staat om weer andere honden blij te maken.
                            </p>

                            {this.props.buttons &&
                                <div className="dialog-footer">
                                    <div className="form-row form-row--buttons --dialog">
                                        {this.props.buttons.map((button, i) => (
                                            <ButtonDialog
                                                key = {i}
                                                caption = {button.text}
                                                onAction = {button.onClick}
                                                btnClass = {button.btnClass}
                                            />
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </TemplateDialog>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(DialogAffiliate));
