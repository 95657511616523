import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as utilities from '../../utilities/formats';
import validate from '../../utilities/validation-rules';

import TextInput from '../../elements/form-input-text';

const CartItem = inject("stores") (
    observer (
        class CartItem extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;
                this.storeCarts = this.props.stores.storeCarts;

                this.state = {
                    percVAT: 21,
                    formControls: { 
                        amount: {
                            value: this.props.product.amount,
                            valid: false,
                            validationRules: {
                                isInteger: true
                            }
                        }
                    }
                }
            }

            componentWillUnmount = () => {
                //this.storeUi.clearFormFeedback();
            }

            componentDidMount = async() => {
            
            }

            componentDidMUpdate = async(prevProps) => {
                if (prevProps.product !== this.props.product) {

                }
            }

            calcCartRowTotal = (item) => {
                let total = 0;

                total = parseFloat(item.price) * parseInt(this.state.formControls.amount.value);

                total = utilities.formatPrice(total);
                return total;
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }); 
            }

            handleChange = async(name, value) => {   
                const accountId = 1;
                if (parseInt(value) === 0 || parseInt(value) === '') {
                    this.setState({
                        oldAmount: this.state.formControls.amount.value
                    });
                    //this.storeUi.openDialogDelete(this.props.product);
                } else {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls[name]
                    };
                    updatedFormElement.value = value;
                    updatedFormElement.touched = true;
                    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
            
                    updatedControls[name] = updatedFormElement;
        
                    //if (updatedFormElement.valid) {
                        this.setState({
                            formControls: {
                                ...this.state.formControls,
                                [name]: {
                                    ...updatedControls[name],
                                    value
                                }
                            }
                        }, async() => {
                            this.calcCartRowTotal(this.props.product);
                            
                            const id = this.storeAuth.getCartId();
                            await this.storeCarts.addToCart('guest', id, this.props.product, '', this.state.formControls.amount.value)
                            this.props.handleChangeAmount();
                        });
                    //}
                } 
            }

            handleDelete = async(product) => {
                const cartId = this.storeAuth.cartId;
                const productDeleted = await this.storeCarts.deleteFromCart(cartId, product);
                const cart = await this.storeCarts.getCart(cartId);
                this.props.handleDelete();
            }

            sendFormProductItem = (e) => {
                e.preventDefault();

                if (this.state.formIsValid) {
                    this.storeForms.sendFormProductItem(this.state.formControls);
                    this.afterSubmit('disable');
                }
            }

            afterSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        formIsSubmitted: true,
                        btn_class: '--disabled',
                        feedback: true
                    }); 
                }
            } 

            render() {
                const product  = this.props.product

                return (
                    <div className='cart-products__columns cart-products__columns--productline'>
                        <div className='cart-products__column'>
                            {product.displayTitle}
                            <strong>{product.displayValue}</strong>
                        </div>
                        <div className='cart-products__column cart-products__column--amount'>
                            {this.props.editable === true
                                ?   <TextInput 
                                        name = {'amount'} 
                                        placeholder = {this.state.formControls['amount'].placeholder}
                                        value = {this.state.formControls.amount.value}
                                        valid = {true}
                                        onChange = {(e) => this.handleChange(e.target.name, e.target.value)}
                                        onBlur = {this.handleBlur}
                                        className = {"--int-small"}
                                    />
                                :   this.props.product.amount
                            }
                            {this.props.editable === true &&
                            <React.Fragment>
                                <span 
                                    className="icon icon--delete --link"
                                    onClick = {() => this.handleDelete(product)}
                                ></span>
                               </React.Fragment>
                            }
                        </div>
                        <div className='cart-products__column cart-products__column--amount'>
                            {utilities.formatPrice(product.price)}
                        </div>
                        <div className='cart-products__column cart-products__column--amount-total'>
                            <span>{this.calcCartRowTotal(product)}</span>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(CartItem);
