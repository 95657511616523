import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import FooterVersion from './footer-version';
import FooterLegal from './footer-legal';
import FooterInfo from './footer-info';
import FooterColofon from './footer-colofon';
import FooterAddress from './footer-address';
import FooterOnline from './footer-online';

class Footer extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;
        
        this.state = {
        };
    }

    gotoPage = (url) => {
        this.props.history.push(url);
    }

    render() {
        return (
            <div className="page-footer">
                <div className="page-footer__content">
                    <div className="page-footer__content--project">
                       

                        <div className="page-footer__columns">
                            <FooterInfo />
                            
                            <FooterAddress />

                            <FooterOnline />
                        
                            <FooterLegal />
                        </div>
                    </div>
                    <div className="page-footer__content--colofon">
                        <FooterColofon />
                        <FooterVersion />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Footer);
