import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as utilities from '../../utilities/formats';

import CartItem from './cart-item';

const CartItems = inject("stores") (
    observer (
        class CartItems extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
                this.storeForms = this.props.stores.storeForms;
                this.storeCarts = this.props.stores.storeCarts;
                this.storeShipping = this.props.stores.storeShipping;
                this.storeVAT = this.props.stores.storeVAT;

                this.state = {
                    shipping: 0,
                    subtotal: 0,
                    vat: 0,
                    total: 0
                }
            }

            componentDidMount = async() => {
                let cart = await this.initCart();
            }

            componentWillUnmount = () => {
                //this.storeUi.clearFormFeedback();
            }

            initCart = async() => {
                const cart = await this.getCart();

                let subtotal = 0;
                let shippingExcl = 0;
                let shippingIncl = 0;
                let vat = 0;
                let total = 0;

                if (cart !== null && cart.length > 0) {
                    subtotal = this.calcCartSubTotal();
                    shippingExcl = await this.calcShipping();
                    vat = await this.calcVAT();
                    total = this.calcCartTotal();

                    shippingIncl = parseFloat(shippingExcl) + parseFloat(this.storeVAT.vatShipping);
                    shippingIncl = utilities.formatPrice(shippingIncl);
                
                    this.setState({
                        subtotal: subtotal,
                        total: total,
                        shipping: shippingIncl,
                        vat: vat
                    })
                } else {
                    this.storeCarts.setCartAmount(0);
                }

            }

            getCart = async() => {
                const id = this.storeAuth.getCartId();
                const cart = await this.storeCarts.getCart(id);

                return cart;
            }

            calcCartRowTotal = (item) => {
                let total = 0;

                total = parseInt(item.price) * parseInt(item.amount);
                total = utilities.formatPrice(total);

                return total;
            }

            calcCartSubTotal = () => {
                let total = 0;
                let totalRow = 0;

                this.storeCarts.cart && this.storeCarts.cart.map((item) => {
                    totalRow = parseFloat(item.price) * parseInt(item.amount);
                    total = total + totalRow;
                });

                total = utilities.formatPrice(total);

                return total;
            }

            calcCartTotal = () => {
                let total = 0;
                let shipping = 0;
                let vatShipping = 0;

                const subtotal = parseFloat(this.calcCartSubTotal());
                total = total + subtotal;
                const id = this.storeAuth.getCartId();
                shipping = parseFloat(this.storeShipping.shipping);
                vatShipping = parseFloat(this.storeVAT.vatShipping);
                shipping = shipping + vatShipping;

                this.storeShipping.saveShipping(id, shipping);

                total = total + shipping;

                this.storeCarts.saveTotals(id, subtotal, total);

                total = utilities.formatPrice(total);
                return total;
            }

            calcVAT = async() => {
                let vat = 0;
                vat = await this.storeVAT.calcVAT();

                vat = utilities.formatPrice(vat);
                return vat;
            }

            calcShipping = async() => {
                let shipping = 0;
                shipping = await this.storeShipping.getShipping(this.storeAuth.getCartId());

                shipping = utilities.formatPrice(shipping);
                return shipping;
            }

            reCalcCart = async() => {
                const cart = await this.initCart();

                this.storeCarts.getCartAmount(this.storeAuth.cartId);
            }

            // validateRow = (name, value, valid) => {        
            //     const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

            //     this.setState({
            //         formControls: updatedControls
            //     }, () => this.validateForm()); 
            // }

            // validateForm = () => {
            //     const formIsValid = this.storeForms.validateForm(this.state.formControls);

            //     this.setState({
            //         formIsValid: formIsValid,
            //         feedback: false,
            //         btn_class: formIsValid ? '--active' : '--disabled'
            //     });
            // }

            handleDelete = async(product) => {

            }

            sendFormOrderItems = (e) => {
                e.preventDefault();

                if (this.state.formIsValid) {
                    this.storeForms.sendFormOrderItems(this.state.formControls);
                    this.afterSubmit('disable');
                }
            }

            afterSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        formIsSubmitted: true,
                        btn_class: '--disabled',
                        feedback: true
                    }); 
                }
            } 

            render() {
                return (
                    <div className='cart-products'>
                        <div className='cart-products__columns'>
                            <div className='cart-products__column'>
                                <span className='cart-products__column-header'>
                                    Product
                                </span>
                            </div>
                            <div className='cart-products__column'>
                                <span className='cart-products__column-header cart-products__column-header--amount'>
                                    Aantal
                                </span>
                            </div>
                            <div className='cart-products__column'>
                                <span className='cart-products__column-header cart-products__column-header--amount'>
                                    Prijs p/s
                                </span>
                            </div>
                            <div className='cart-products__column'>
                                <span className='cart-products__column-header cart-products__column-header--amount'>
                                    Totaal
                                </span>
                            </div>
                        </div>

                        {this.storeCarts.cart && this.storeCarts.cart.map((product) => (
                            <CartItem
                                product = {product}
                                editable = {this.props.editable}
                                handleChangeAmount = {() => this.reCalcCart()}
                                handleDelete = {() => this.reCalcCart()}
                            />
                        ))}

                        <div className='cart-products__columns cart-products__columns--totals'>
                            <div className='cart-products__column cart-products__column--label'>
                                <span>Subtotaal</span>
                            </div>
                            <div className='cart-products__column cart-products__column--amount-total'>
                                <span>{this.state.subtotal}</span>
                            </div>
                        </div>

                        <div className='cart-products__columns cart-products__columns--totals'>
                            <div className='cart-products__column cart-products__column--label'>
                                <span>Verzendkosten</span>
                            </div>
                            <div className='cart-products__column cart-products__column--amount-total'>
                                <span>{this.state.shipping}</span>
                            </div>
                        </div>

                        <div className='cart-products__columns cart-products__columns--totals --grandtotal'>
                            <div className='cart-products__column cart-products__column--label'>
                                <span><strong>Totaal (incl Btw)</strong></span>
                            </div>
                            <div className='cart-products__column cart-products__column--amount-total'>
                                <span><strong>{this.state.total}</strong></span>
                            </div>
                        </div>

                        <div className='cart-products__columns cart-products__columns--totals cart-products__columns--totals-vat'>
                            <div className='cart-products__column cart-products__column--label'>
                                <span>Btw</span>
                            </div>
                            <div className='cart-products__column cart-products__column--amount-total'>
                                <span>{this.state.vat}</span>
                            </div>
                        </div>

                        <div className='cart-products__columns'>
                            <div className='cart-products__column'>
                                &nbsp;
                            </div>
                        </div>

                        <div className='cart-products__feedback'>
                            Verzendkosten gebaseerd op basis van gewicht en verzending binnen Nederland.<br/>
                            {/* (gewicht: {this.storeShipping.orderWeight} gr) */}
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(CartItems);
