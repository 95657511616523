import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const Product = inject("stores") (
    observer (
        class Product extends Component {

    constructor(props) {
        super();
        this.props = props;

        this.state = {

        }

        this.prod = {
            price: 28.00,
            subtitle: 'Xxxx',
            description_short: 'Xxx xx x xx x x x  x x x xx xx xxxx x  xx',
            img: '',
            artist: '',
            brand_logo: '',
            shop: '',
            shop_url: '',
        }
    }

    componentDidMount() {

    }

    setActiveCatSlide(idx) {

    }

    render() {
        const product  = this.props.product;
        
        return (
            <li className="list-products-per-category__item list-product">
                <div className="list-products-per-category__row">
                    <div className="list-products-per-category__column">
                        <div className="list-product__title">
                            <Link to={'/product/' + product.id}>{product.name}</Link>
                        </div>
                        <div className="list-product__price">
                            {this.prod.price}
                        </div>
                    </div>
                    <div className="list-products-per-category__column">
                        <div className="list-product__img-container">
                            <div className="list-product__img">
                                image
                            </div>
                        </div>
                    </div>
                    <div className="list-products-per-category__column">
                        <div className="list-product__subtitle">{this.prod.subtitle}</div>
                        <div className="list-product__description--short">{this.prod.description_short}</div>
                        <div className="list-product__actions">
                            <button className="btn btn--secundary">Recommend</button>
                            <button className="btn btn--primary">Buy</button>
                        </div>
                    </div>
                </div>
                <div className="list-products-per-category__divider"></div>
            </li>                           
        );
  }
}

    )
)

export default Product;
