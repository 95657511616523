import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const FriendSummary = inject("stores") (
    observer (
        class FriendSummary extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.urlAPI = process.env.REACT_APP_URL_API;

        this.state = {

        }; 
    }

    componentDidMount = async() => {

    }

    componentDidUpdate = async(prevProps) => {

    }

    handleClick = (url) => {
        window.open(url);
    }

    render() {
        const bg_img = this.urlAPI + "public/images/friends/" + this.props.friend.backgroundImg;

        return (
            <React.Fragment>     
                <div 
                    className={"promo--friends__block promo--friends__block--" + this.props.friend.bemClass}
                    style= {{backgroundImage:`url(${bg_img})` }}
                >
                    <h3>
                        {this.props.friend.title}
                    </h3>
                    <span
                        className = "divider"
                    />
                    <p>
                        {this.props.friend.description}
                    </p>
                    {this.props.friend.logo &&
                        <img 
                            src = {this.urlAPI + "/public/images/friends/" + this.props.friend.logo} 
                            className = "logo-friend"
                        />
                    }
                </div>

                <div 
                    className="button button--external-link"
                    onClick={() => this.handleClick(this.props.friend.link)}
                >
                    <div 
                        className="box"
                    >
                        <div 
                            className="box"
                        >
                            <div 
                                className="arrow-head"
                            />
                        </div>
                    </div>
                    <div class="button__text --link">Bezoek {this.props.friend.title}</div>
                </div>
            </React.Fragment>
        );
  }
}

    )
)

export default FriendSummary;
