import { observable, computed, action, decorate } from "mobx";

import StoreAuth from './store-auth';
import StoreCarts from './store-carts';
import StoreContent from './store-content';
import StoreForms from './store-forms';
import StoreGeneral from './store-general';
import StoreOrders from './store-orders'
import StorePayments from './store-payments';
import StoreProducts from './store-products';
import StoreShipping from './store-shipping';
import StoreShop from './store-shop';
import StoreUi from './store-ui';
import StoreUsers from './store-users';
import StoreVisuals from './store-visuals';
import StoreVAT from './store-vat';

class StoreRoot {

    constructor() {  
        this.storeAuth = new StoreAuth(this);  
        this.storeCarts = new StoreCarts(this);  
        this.storeContent = new StoreContent(this);  
        this.storeForms = new StoreForms(this);
        this.storeGeneral = new StoreGeneral(this);
        this.storeOrders = new StoreOrders(this);
        this.storePayments = new StorePayments(this);
        this.storeProducts = new StoreProducts(this);
        this.storeUi = new StoreUi(this);
        this.storeUsers = new StoreUsers(this);
        this.storeShipping = new StoreShipping(this);
        this.storeShop = new StoreShop(this);
        this.storeVisuals = new StoreVisuals(this);
        this.storeVAT = new StoreVAT(this);
    }
    
}

export default StoreRoot;