import { makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as carts from '../communicator/shop/carts';

class StoreCarts {

    cart = [];
    cartId = 0;
    cartAmount = 0;
    
    constructor(storeRoot) {
        this.storeAuth = storeRoot.storeAuth;
        
        makeObservable(this, {
            cart: observable,
            cartAmount: observable
        })
    }

    async createNewCart() {
        let cartId;
        try {
            const returnData = await carts.createNewCart();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.cartId = returnData.data.cartId;
                    cartId = returnData.data.cartId;
                } 
                return cartId;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cartId;
    }

    async getCart(id) {
        let cart;
        try {
            const returnData = await carts.getCart(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.cart = returnData.data;
                    cart = returnData.data;
                } 
                return cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cart;
    }

    async getCartAddresses(id) {
        let cartAddresses;
        try {
            const returnData = await carts.getCartAddresses(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    console.log('SbC return', returnData)
                    cartAddresses = returnData.data;
                } 
                return cartAddresses;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cartAddresses;
    }

    async getCartUser(id) {
        let cartUser;
        try {
            const returnData = await carts.getCartUser(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    console.log('SbC return', returnData)
                    cartUser = returnData.data;
                } 
                return cartUser;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cartUser;
    }

    async getCartAmount(id) {
        let cart;
        let total = 0;
        try {
            const returnData = await carts.getCart(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    cart = returnData.data;
                    cart.map((item) => {
                        total = total + parseInt(item.amount);
                    });
                    this.cartAmount = total;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    setCartAmount(amount) {
        this.cartAmount = amount;
    }

    addToCart = async(type, id, product, price, amount) => {
        let sku;
        if (product.sku) {
            sku = product.sku;
        }
        if (product.ean) {
            sku = product.ean;
        }

        let productLine = {
            id: id,
            guestId: this.storeAuth.user.guestId,
            accountId: this.storeAuth.user.accountId,
            type: this.storeAuth.userType,
            amount: amount,
            sku: sku,
            displayTitle: product.displayTitle,
            displayValue: product.displayValue,
            price: price.priceShop,
            vat: price.vat,
            discount: 0
        }

        if (this.cart !== null && this.cart.length > 0) {
            const idx = this.cart.findIndex(item => item.sku === sku);
            if (idx === -1) {
                // SbC add to cart
                let cart = await this.addCartItem(id, productLine);
            } else {
                // SbC replace amount in cart
                if (amount === 1) {
                    productLine.amount = parseInt(this.cart[idx].amount) + 1;
                } else {
                    productLine.amount = amount;
                }
                let cart = await this.updateCartItem(id, productLine);
            }
        } else {
            let cart = await this.addCartItem(id, productLine);
        }
    }

    async addCartItem(id, product) {
        try {
            const returnData = await carts.addToCart(id, product);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.storeAuth.setCartId(returnData.data[0]);
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async updateCartItem(id, product) {
        try {
            const returnData = await carts.updateCart(id, product);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    //cart = returnData.data;
                } 
                // cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        //return cart;
    }

    async deleteFromCart(cartId, product) {
        const productLine = {
            sku: product.sku
        }
        try {
            const returnData = await carts.deleteFromCart(cartId, productLine);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                } 
                //return cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        //return cart;
    }

    saveTotals = async(cartId, subtotal, total) => {
        try {
            const returnData = await carts.saveTotals(cartId, subtotal, total);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {

                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async saveCartAddresses(cartId, formData) {
        const data = {
            invoiceStreet: formData.invoiceStreet.value,
            invoiceHousenr: formData.invoiceHousenr.value,
            invoiceAddition: formData.invoiceAddition.value,
            invoiceZipcode: formData.invoiceZipcode.value,
            invoiceCity: formData.invoiceCity.value,
            invoiceCountry: 'NL',
        };
        try {
            const returnData = await carts.saveCartAddress(cartId, data);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    async saveCartUser(cartId, formData) {
        const data = {
            invoiceFirstname: formData.invoiceFirstname.value,
            invoiceMiddlename: formData.invoiceMiddlename.value,
            invoiceLastname: formData.invoiceLastname.value,
            invoiceEmail: formData.invoiceEmail.value
        };
        try {
            const returnData = await carts.saveCartUser(cartId, data);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    async getCartPets(cartId) {
        let pets = [];
        try {
            const returnData = await carts.getCartPets(cartId);
            
            runInAction(() => {
                pets = returnData.data;
                console.log('SbC return pets', returnData)
                return pets;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return pets;
    }

    async saveCartPet(cartId, petId, data) {
        const pets = {
            petId: petId,
            petName: data.petName.value
        };
        try {
            const returnData = await carts.saveCartPet(cartId, pets);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    async deleteCartPet(petId) {
        let pets = [];
        try {
            const returnData = await carts.deleteCartPet(petId);
            
            runInAction(() => {
                pets = returnData.data;
                return pets;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return pets;
    }
    
}

export default StoreCarts;