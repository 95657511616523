import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as shop from '../communicator/shop/shop';
import * as ui from '../utilities/ui'; 

import Template from '../templates/template.js';

const HomeMain = inject("stores") (
    observer (
        class HomeMain extends Component {
            
            constructor(props) {
                super();
                this.props = props;
                this.storeUi = this.props.stores.storeUi;

                this.APP_HOME = 15;
                this.state = {
                    activeCat: 'HOME',
                    activeSlide: 0,
                    CAT_PRODUCTS: [{}]
                }
            }

            async componentDidMount() {
                window.scrollTo(0, 0);
                ui.smoothScrollTo(0, 0, 250);
                this.storeUi.setSocialItem('site');
                // const apiProducts = await shop.getShopProductsPerCategory();
                // let shopProducts = this.state.CAT_PRODUCTS;
                // shopProducts.push.apply(shopProducts, apiProducts);

                // this.setState({
                //     CAT_PRODUCTS: shopProducts
                // })
            }

            componentDidUpdate = () => {
                ui.smoothScrollTo(0, 0, 250);
            }

            render() {
                return (
                    <Template
                        segment = "home"
                        id = "home"
                    >
                        <div className="homepage">
                            <h2>This is APP HOME page!</h2>
                            
                            {/* <h2>Featured shop products</h2>
                            <ul>
                                {this.state.CAT_PRODUCTS.map((prodItem, i) => (
                                    <React.Fragment>
                                        {prodItem.cats && 
                                            <React.Fragment>
                                                {prodItem.cats.map((cat, ii) => (
                                                    <React.Fragment>
                                                        {parseInt(cat.id) === this.APP_HOME &&
                                                            <li>
                                                                {this.state.CAT_PRODUCTS[i].name}
                                                            </li>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                ))}
                            </ul> */}
                        </div>
                    </Template>
                );
            }
        }
    )
)

export default withRouter(HomeMain);
