import * as apifetch from './fetch.js';

export async function getVisuals(section) {
    const urlEndpoint = 'visuals/' + section;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}

export async function getQuote(id) {
    const urlEndpoint = 'visuals/quote/' + id;

    return apifetch.ikFetch(urlEndpoint, 'GET');
}
