import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import * as cart from '../../communicator/shop/utilsCart';

const ProductConfiguration = inject("stores") (
    observer (
        class ProductConfiguration extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    async handleSubmit(e) {
        e.preventDefault();
        const product_id = this.props.product_id;
        console.log('SbC adding to cart::', product_id)

        let cartDetails = await cart.addToCart(product_id, this.props.stores.storeUi.app_auth.access_token);
    }

    render() {
        return (
            <React.Fragment>
                <div className="product-configuration__header">Configure your order</div>
                <div className="product-configuration__configuration-block">
                    <div className="configuration-block__header">Color</div>
                    <div className="configuration-block__options">
                        <div className="configuration-block__option">White</div>
                        <div className="configuration-block__option">Black</div>
                    </div>
                </div>

                <div className="product-configuration__configuration-block">
                    <div className="configuration-block__header">Size</div>
                    <div className="configuration-block__options">
                        <div className="configuration-block__option">S</div>
                        <div className="configuration-block__option">M</div>
                    </div>
                </div>

                <div className="product-configuration__configuration-block">
                    <div className="configuration-block__header">Additional components</div>
                    <div className="configuration-block__options">
                        <div className="configuration-block__option">Tap connection</div>
                        <div className="configuration-block__option">Gardena hose connection</div>
                    </div>
                </div>

                <div className="product-configuration__action-block">
                    <div className="action-block__price"></div>
                    <div className="action-block__add-to-cart">
                        <div className="action-block__button" onClick={(e)=>this.handleSubmit(e)}>
                            Add to cart
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
    )
)
export default ProductConfiguration;
 