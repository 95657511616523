import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";

import Branding from '../components/header/branding';
import TopBar from '../components/general/topbar';
import NavigationMain from '../components/general/navigation-main';
import Footer from '../components/footer/footer';

import BlockSocial from '../components/blocks/general/block-social';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeUsers = this.props.stores.storeUsers;
            }

            componentDidMount() {
                
            }
   
            render() {

                return (
                    <div>
                        <div className="topbar-container">
                            <div className="content-container">
                                <div className="content-container__content">
                                    <TopBar />
                                </div>
                            </div>
                        </div>

                        <Branding 
                            leader = {this.props.leader} 
                            segment = {this.props.segment} 
                            id = {this.props.id} 
                            story = {this.props.story}
                            dogName = {this.storeUsers.dogName}
                        />

                        <NavigationMain 
                            useSuspense = {false}
                            segment = {this.props.segment} 
                            id = {this.props.id} 
                        />

                        {this.props.children}  

                        {/* <div className="segment-wrapper segment-wrapper--social">
                            <BlockSocial 
                                item = {this.storeUi.social_item}
                            />
                        </div> */}

                        <Footer />
               
                     </div>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(Template));
