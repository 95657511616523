import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

class NavBreadcrumb extends Component {

    constructor() {
        super();
    }

    render() {
        return (
            <div className="nav-breadcrumb">
                <ul className="list-breadcrumb">
                    <li className="list-breadcrumb__item"><span><Link to='/shop/home'>Home</Link></span></li>
                    <li className="list-breadcrumb__item"><Link to='/shop/groom'>Groom</Link></li>
                    <li className="list-breadcrumb__item --active">Dog wash</li>
                </ul>
            </div>
        );
    }
}

export default NavBreadcrumb;
 